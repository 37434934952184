import Axios from 'axios';
import cookie from 'react-cookies';

import { setError, clearError } from "./error/action";
import {store} from "./store";
import history from '@iso/lib/helpers/history';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const { PLAN, PLAN_BILLING,USER_PROFILE_DOCUMENT } = APP_PRIVATE_ROUTE;
        
const addOrUpdateCSSChatBox = cssText => ((styleElement => styleElement ? styleElement : (styleElement = document.createElement('style'), styleElement.id = 'chatBox-css', document.head.appendChild(styleElement)))(document.getElementById('chatBox-css')), document.getElementById('chatBox-css').innerHTML = cssText);

export default function axiosApi(url, method, data, dispatch, dispatchType, staticPayload = '', RateLimitCookie ={} ) {
  const state = store.getState();
  dispatch(clearError());
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" })
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData ? userData.authToken : null;
  let billingtoken = userData ? userData.billingToken : null;
  let userId = userData ? userData._id : null;
  return Axios({
    method,
    url,
    data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      id: userId,
      authtoken: authToken,
      billingtoken: billingtoken,
      ...(Object.values(RateLimitCookie).some(value => value) && RateLimitCookie), 
    },
  }).then((result) => {
    if(result.data && result.data.commonData){
      if("isChatBox" in result.data.commonData){
        addOrUpdateCSSChatBox(`.crisp-client { display: ${result.data.commonData.isChatBox ? "block" : "none"} }`);
      }

      if(state.authentication){
        if(
          "report" in result.data.commonData.plan && 
          "report" in state.authentication.userData.data.plan && 
          result.data.commonData.plan.report !== state.authentication.userData.data.plan.report
        ){
          store.dispatch({
            type:"UPDATE_COMMON_USER_DATA",
            payload:{
              type: 'plan',
              data: {"role":result.data.commonData.role,"roleData":result.data.commonData.roleData}
            }
          })
        }
        
        if(result.data.commonData.setNewPassword){
          store.dispatch({
            type:"UPDATE_COMMON_USER_DATA",
            payload:{
              type: 'changeAuth',
              data: {"setNewPassword":result.data.commonData.setNewPassword}
            }
          })
        }
        if(result.data.commonData.setNewPasswordCountDown){
          store.dispatch({
            type:"UPDATE_COMMON_USER_DATA",
            payload:{
              type: 'changeAuth',
              data: {"setNewPasswordCountDown":result.data.commonData.setNewPasswordCountDown}
            }
          })
        }

        if( userData?.isDisableCredit !== result.data?.commonData?.isDisableCredit){
          store.dispatch({
            type:"UPDATE_COMMON_USER_DATA",
            payload:{
              type: 'changeAuth',
              data: {"isDisableCredit":result.data.commonData.isDisableCredit}
            }
          })
        }
      }
    }
    if(url.includes("billing/plan/user")){
      let 
        userData = JSON.parse(localStorage.getItem('userData')),
        resultObj = result.data.data,
        availableCredits = resultObj.availableCredits && resultObj.availableCredits.availableCredits ? resultObj.availableCredits.availableCredits : 0,
        usedCredits = resultObj.availableCredits && resultObj.availableCredits.usedCredits ? resultObj.availableCredits.usedCredits : 0;
      
      userData.amountDue = resultObj.user && resultObj.user.amountDue ? resultObj.user.amountDue : 0;
      if(availableCredits && usedCredits){
          userData.totalCredits = Number(availableCredits) - Number(usedCredits);
      }else{
        userData.totalCredits = Number(availableCredits);
      }
      if( resultObj?.user?.currency && userData?.currency !== resultObj?.user?.currency ){
          store.dispatch({
            type:"UPDATE_COMMON_USER_DATA",
            payload:{
              type: 'changeAuth',
              data: {"currency":resultObj.user.currency}
            }
          })
      }
      // userData.availableCredits = resultObj.availableCredits
      // userData.numberUserMinute = resultObj.numberUserMinute
      localStorage.setItem("userData", JSON.stringify(userData));

      dispatch({
        type: `PUSHER_DATA`,
        payload:{"data":{"amountDue":userData.amountDue,"totalCredits":userData.totalCredits},type:"plan"},
      })
    }
    return dispatch({
      type: `${dispatchType}`,
      payload: result.data,
      staticPayload: staticPayload
    });
  }).catch((err) => {
    if (err.response && err.response.data && err.response.data.logout) {
      return ClearUserData(err.response);
    }
    
    if (err.response && errorResponseStatus.indexOf(err.response.status) >= 0) {
      return ClearUserData(err.response);
     }
    if (err.response.status === 426 && err.response.data && err.response.data.amountDue){
      let search = window.location.search;
      const params = new URLSearchParams(search);
      const redirectUrl = params.get('r');
      if(!redirectUrl){
        history.push(PLAN_BILLING);
       
      }
      return dispatch({
        type: dispatchType,
        payload: {
          type:"error",
          error:  { error : err.response && err.response.data && err.response.data.error ? err.response.data.error : "Something went wrong" }
        },
        staticPayload: staticPayload
      });
    }
    
    if (err.response.status === 428){
      history.push({  pathname: USER_PROFILE_DOCUMENT, state: {status:428}});
      return;
      // dispatch({
      //   type: dispatchType,
      //   payload: {
      //     type:"error",
      //     error:  { error : err.response && err.response.data && err.response.data.error ? err.response.data.error : "Something went wrong" },
      //     success:false,
      //     status:428
      //   },
      //   staticPayload: staticPayload
      // });
    }

     return dispatch({
      type: dispatchType,
      payload: {
        type:"error",
        error:  err.response && 
                err.response.data && 
                err.response.data.error? err.response.data.error : "Something went wrong",
                 message:  err.response && 
                  err.response.data && 
                  err.response.data.error && 
                  err.response.data.error.error ? err.response.data.error.error : err && err.response && err.response.data && err.response.data.error && err.response.data.error.message && err.response.data.error.type == "payment" ? err.response.data.error.message.split("Error message")[0] : "Something went wrong",
      },
      staticPayload: staticPayload
    });
  });
}
export const ClearUserData=(response)=> {
  let data  = response.data
  let msg = data && data.error && data.error.error? data.error.error: data.error
  store.dispatch({
    type:'SET_CUSTOM_MESSAGE',
    payload:{message:msg,type:'error'}
  })
  setTimeout(() => {
    localStorage.clear();
      allCookieClear()
      store.dispatch({
        type:"AUTHENTICATION",
        postdata:{}
      })
      store.dispatch({
        type:"CLEAR_CALL_PLANNER"
      })
      history.push("/login")    
  }, msg ? 5000 : 0);
}

export const allCookieClear =()=>{
  cookie.remove('webAppUserRoleEnabled', { path: '/', domain: "callhippo.com" });
  cookie.remove('userRoleEnabled', { path: '/', domain: "callhippo.com" });
  cookie.remove('authToken', { path: '/', domain: "callhippo.com" });
  cookie.remove('chReports', { path: '/', domain: "callhippo.com" });
  cookie.remove('billingToken', { path: '/', domain: "callhippo.com" });
  cookie.remove('user_id', { path: '/', domain: "callhippo.com" });
  cookie.remove('creditDisplayMessage', { path: '/', domain: "callhippo.com" });
  cookie.remove('creditDisplayMessage5', { path: '/', domain: "callhippo.com" });
  cookie.remove('creditDisplayMessage1', { path: '/', domain: "callhippo.com" });
  cookie.remove('amountDueDisplayMessage', { path: '/', domain: "callhippo.com" });
  cookie.remove('version2Enabled', { path: '/', domain: "callhippo.com" });
  cookie.remove('firstTimeLogin', { path: '/', domain: "callhippo.com" });
  cookie.remove('whatsappToken', { path: '/', domain: "callhippo.com" });
  
  if(!cookie.load('dialerAppUserRoleEnabled')){
    cookie.remove('userRoleEnabled', { path: '/', domain: "callhippo.com" });
  }
  window.location.replace(process.env.REACT_APP_REDIRECT_URL+new Date().getTime());
  
  if(!cookie.load('dialerAppUserRoleEnabled')){
    cookie.remove('userRoleEnabled', { path: '/', domain: "callhippo.com" });
  }
}

export const errorResponseStatus =  [401]
