import React, { Fragment } from 'react';
import { useDispatch, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tour from 'reactour';
import dashboardActions from '@iso/redux/dashboard/actions';
import WithDirection from '@iso/lib/helpers/rtl';
import CHTourStyles from './CHTour.styles';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const { DASHBOARD, NUMBER, NUMBER_BY_ID, USERS, CALL_LOG } = APP_PRIVATE_ROUTE;

const IsoCHTour = CHTourStyles(Tour);
const CHTourModal = WithDirection(IsoCHTour);

const { markTourVisited, triggerTour, triggerAddNumberPopup } = dashboardActions;

function CHTour(props) {
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();

  /* const closeTour = () => {
    setIsTourVisited(true)
  } */

  const endTour = () => {    
    let userData = props.userData.data;
    if(!userData.isTourVisited){
      props.markTourVisited({userId: userData._id}).then(({ payload }) => {
        if(payload.success){
          let userDataLocalData = JSON.parse(localStorage.getItem('userData'))
          userDataLocalData.isTourVisited = true;
          localStorage.setItem("userData", JSON.stringify(userDataLocalData));
        }
      });
    }
    // userData.numbers === 0 && userData.plan.planDisplayName === 'free' && props.triggerAddNumberPopup(true);
    dispatch(triggerTour({isTourOpen:false, isTourStarted: false, tourStartAt: 0}));
    props.history.push(DASHBOARD);
  }
    
  const steps = [
    {
      selector: '',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            <h3 className="productTourTitle">Welcome to CallHippo!!</h3>
            <div className="productTourContent">
              <div className="ptc1">CallHippo is a telephony system for Sales, Support and Call Center teams.</div>
              <div>In the next few slides, we will briefly take you through CallHippo and how it can serve your team.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => { 
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 1}));
                  props.history.push(NUMBER);
                }}>Next<span className="nextarrow">&#xbb;</span></button>
                <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() =>endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      )
    },
    {
      selector: '[data-tour="tour-number"]',
      content: ({ goTo, inDOM, props }) => (
        <Fragment>
          <div className='startTourMain'>
          {/* <div className="productTourArrow numberarrow"></div> */}
          <div className={`productTourArrow ${document.body.classList.contains("setup-active") ? "new-numberarrow" : "numberarrow"}`}></div>
          <h3 className="productTourTitle">Number and DID</h3>
            <div className="productTourContent">
          </div>
            <div>This section allows you to buy virtual numbers from 55+ countries. You can buy Toll Free and Local Numbers and search number based on locations or the prefixes of the number.</div>
            <div className="ptButtonWrapper">
              <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => 
              {
                dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 0}));
                props.history.push(DASHBOARD);
              }}><span className="prevarrow">&#xab;</span>Prev</button>
              <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => { goTo(2); }}>Next<span className="nextarrow">&#xbb;</span></button>
              <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
            </div>
          </div>
        </Fragment>
      ),
      // position: [
      //   width === 99 ? 232 : 232,65,
      //   width === 768 ? 99 : 99,52,
      // ]
      position: [80, 60]
      //position: [99,35]
    },
    {
      selector: '[data-tour="tour-number-setting-btn"]',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            <div className="productTourArrow numbersettingarrow"></div>
            <h3 className="productTourTitle">Number Settings</h3>
            <div className="productTourContent">
              <div>Once you have purchased a virtual number, you can click on the setting page and set the number as per your requirement.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn" style={{textTransform:'capitalize'}} onClick={() => goTo(1)}><span className="prevarrow">&#xab;</span>Prev</button>
                <button className="producttournextbtn" style={{textTransform:'capitalize'}} onClick={() => {
                  //goTo(3);
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 3}));
                  props.history.push(`${NUMBER_BY_ID}/dummy`);
                }}>Next<span className="nextarrow">&#xbb;</span></button>
                <button className="endtourbtn" style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      ),
      position: [
        width === 1920 ? 1100 : 
        width === 1536 ? 750 : 
        width === 1440 ? 650 :
        width === 1366 ? 615 :
        width === 1280 ? 520 : 
        630,
        120
      ]
    },
    {
      selector: '[data-tour="tour-number-setting-sidebar"]',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            <div className="productTourArrow"></div>
            <h3 className="productTourTitle">Number Settings</h3>
            <div className="productTourContent">
              <div className="ptc1">CallHippo allows you to forward the calls, record calls, customize working hours and set welcome message, voicemail, IVR & more.</div>
              <div>You can also assign one number to multiple users. This will allow you to ring the phone of all the users simultaneously.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => {  
                    dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 2}));
                    props.history.push(NUMBER);
                  }}><span className="prevarrow">&#xab;</span>Prev</button>
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => {
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 4}));
                  props.history.push(USERS);
                }}>Next<span className="nextarrow">&#xbb;</span></button>
                <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      ),
    },
    {
      selector: '[data-tour="tour-users"]',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            {/* <div className="productTourArrow userArrow"></div> */}
            <div className={`productTourArrow ${document.body.classList.contains("setup-active") ? "new-userArrow" : "userArrow"}`}></div>
            <h3 className="productTourTitle">Users & Performance</h3>
            <div className="productTourContent">
              <div className="ptc1">This tab allows you to add team members to your CallHippo account. Click on the settings button to enter the User's setting page.</div>
              <div>In the user's setting page, you can set forward to device, custom availability & assign numbers to a user.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => {
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 3}));
                  props.history.push(`${NUMBER_BY_ID}/dummy`);
                }}><span className="prevarrow">&#xab;</span>Prev</button>
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => {
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 5}));
                  props.history.push(CALL_LOG); 
                }}>Next<span className="nextarrow">&#xbb;</span></button>
                <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      ),
      position: [80, 70],
      // you could do something like:
      // position: [160, 250],
      action: node => {

      },
      style: {
        // backgroundColor: '#bada55',
      },

      stepInteraction: false,
      navDotAriaLabel: '',
    },
    {
      selector: '[data-tour="tour-calllog"]',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            {/* <div className="productTourArrow callLogsArrow"></div> */}
            <div className={`productTourArrow ${document.body.classList.contains("setup-active") ? "new-callLogsArrow" : "callLogsArrow"}`}></div>
            <h3 className="productTourTitle">Call Logs / Recordings</h3>
            <div className="productTourContent">
              <div>Call Logs / Recordings tab will give you the details of your calling activity. You can also listen to the call recordings from this page and get call cost.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => {
                  dispatch(triggerTour({isTourOpen:false, isTourStarted: true, tourStartAt: 4}));
                  props.history.push(USERS);
                }}><span className="prevarrow">&#xab;</span>Prev</button>
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => goTo(6)}>Next<span className="nextarrow">&#xbb;</span></button>
                <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      ),
      position: [80,180],
      // you could do something like:
      // position: [160, 250],
      action: node => {

      },
      style: {
        // backgroundColor: '#bada55',
      },

      stepInteraction: false,
      navDotAriaLabel: '',
    },
    {
      selector: '[data-tour="open-dialer"]',
      content: ({ goTo, inDOM }) => (
        <Fragment>
          <div className='startTourMain'>
            <div className="ptdialerarrow"></div>
            <h3 className="productTourTitle">Dialer App</h3>
            <div className="productTourContent">
              <div>To make calls and receive call via the web browser, click on the Dialer button.</div>
              <div className="ptButtonWrapper">
                <button className="producttournextbtn"  style={{textTransform:'capitalize'}} onClick={() => goTo(5)}><span className="prevarrow">&#xab;</span>Prev</button>
                <button className="endtourbtn"  style={{textTransform:'capitalize'}} onClick={() => endTour()}>End Tour</button>
              </div>
            </div>
          </div>
        </Fragment>
      ),
      position: [
        width === 1920 ? 1375 :
        width === 1536 ? 950 :
        width === 1440 ? 900 :
        width === 1366 ? 825 :
        width === 1280 ? 745 :
        802,
        55
      ],
      // you could do something like:
      // position: [160, 250],
      action: node => {

      },
      style: {
        // backgroundColor: '#bada55',
      },

      stepInteraction: false,
      navDotAriaLabel: '',
    },
  ];

  return (
    <div>
      <CHTourModal
        className="productTourWrapper tourwrappertab"
        disableDotsNavigation={false}
        showNumber={false}
        showCloseButton={false}
        steps={steps}
        isOpen={props.isOpen}
        startAt={props.tourStartAt}
        //onRequestClose={closeTour}
        showButtons={false}
        showNavigation={false}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userData: state.authentication.userData,
    tourFirstVisit: state.authentication.tourFirstVisit,
    tourStartAt:state.authentication.tourStartAt,
  }
}
export default connect(mapStateToProps, { markTourVisited, triggerTour, triggerAddNumberPopup })(withRouter(CHTour));