import React, { Component } from 'react';
import { Link, Redirect,useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import Popover from '@iso/components/uielements/popover';
import IntlMessages from '@iso/components/utility/intlMessages';
import authentication from '@iso/redux/authentication/action';
import dashboardActions from '@iso/redux/dashboard/actions';
import contactActions from '@iso/redux/contacts/actions';
import usersActions from '@iso/redux/users/actions';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import message from '@iso/components/Feedback/Message';
import cookie from "react-cookies";
import { APP_PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import './TopbarUser.css'
import {allCookieClear} from "@iso/redux/api";
import {
  Action,
  checkAccessAllowedForModule,
  ModuleName,
  defaultModuleAccess
} from "../../library/helpers/moduleAccess";
import commonMsg from "../../components/message.json"
import { HashLink } from 'react-router-hash-link';

const { LANDING, LOGIN_IN } = APP_PUBLIC_ROUTE;
const { DASHBOARD, USER_PROFILE, CHANGE_PASSWORD, USER_PROFILE_DOCUMENT, PLAN_BILLING, INTEGRATION, IMPORT_CONTACT,SHAKEN_STIR } = APP_PRIVATE_ROUTE;

const { logout } = authentication;
const { exportContact } = contactActions;
const { getCustomerPortal } = usersActions;
const { triggerTour } = dashboardActions;
const integrationOurAppsUrl = "integrations/#linkOurApps"
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      visible: false,
      redirectToReferrer: false,
      userName: '',
      parentEmail: '',
      isAcDetailDisabled: false,
      isAcDetailShow: false,
      chargebeeSite: '',
      isProfileRemaining: true,
      modalVisible: false,
      confirmLoading: false,
      exportContactModuleAccess:defaultModuleAccess,
      isopen: false,
      importContactRights: false
    };
  }
  
  componentDidMount() {
    let userData = this.props.userData.data;
    let accessAllowedForExportContacts = checkAccessAllowedForModule(userData.roleData, ModuleName.EXPORTCONTACT,userData)
    if (process.env.REACT_APP_REPORTS_APP && cookie.load('chReports') != userData._id) {
      localStorage.removeItem('userData');
      this.props.history.replace(LOGIN_IN)
    }
    // if (process.env.REACT_APP_REPORTS_APP && cookie.load('chReports') == undefined) {
    //   this.props.logout(this.props.history);
    // }
    if( (userData && userData.importContactRights && userData.importContactRights.length && userData.role && (userData.importContactRights).includes(userData.role)) || (userData.roleData && userData.roleData.priority && userData.roleData.priority == 1) ) this.setState({importContactRights:true})
    this.setState({
      userId: userData._id,
      userName: userData.fullName,
      parentEmail: userData.parentId && userData.parentEmail,
      isAcDetailDisabled: userData.isProfileRemaining,
      isAcDetailShow: userData.stripeId && !userData.parentId && (!userData.accountPaymentType || userData.accountPaymentType == 'postpaid'),
      chargebeeSite: userData.chargebeeSite,
      isProfileRemaining: userData.isProfileRemaining,
      numbers: userData.numbers,
      exportContactModuleAccess:accessAllowedForExportContacts
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isAuthenticated!=this.props.isAuthenticated){
      if(!this.props.isAuthenticated){
          
      }
    }

     if (prevProps.log_out !== this.props.log_out) {
       if (this.props.log_out.data.success) {
          this.setState({ redirectToReferrer: true });
         localStorage.clear();
         allCookieClear()

       } else {
         message.destroy();
         message.error();
       }
     }

    if (prevProps.export_contact !== this.props.export_contact) {
      console.log(this.props.export_contact)
      this.setState({confirmLoading: false, modalVisible: false});
      if (this.props.export_contact.success) {
        
        // let url = process.env.REACT_APP_BASE_URL + this.props.export_contact.data.fileName;
        // window.open(url);
        message.success(commonMsg.download_success_msg);
      } else {
        message.destroy();
        console.log(this.props.export_contact)
        // if (this.props.export_contact.error.length) {
        //   message.error(this.props.export_contact.error);
        // } 
        //else {
          message.error(this.props.export_contact.message);
          
          
        //}
     }
    }

    // if (prevProps.get_customer_portal !== this.props.get_customer_portal) {
    //   if (this.props.get_customer_portal.success) {
    //     let portalData = this.props.get_customer_portal.portalData;
    //     const cbInstance = window.Chargebee.init({
    //       site: this.props.userData.data.chargebeeSite,
    //     })
    //     var chargebeePortalInstance = cbInstance.createChargebeePortal();
    //     cbInstance.setPortalSession(function () {
    //       return new Promise(function (resolve, reject) {
    //         var sampleResponse = {
    //           "id": portalData.id,
    //           "token": portalData.token,
    //           "access_url": portalData.access_url,
    //           "status": portalData.status,
    //           "created_at": portalData.created_at,
    //           "expires_at": portalData.expires_at,
    //           "object": portalData.object,
    //           "customer_id": portalData.customer_id
    //         };
    //         resolve(sampleResponse);
    //       });
    //     });

    //     chargebeePortalInstance.open({
    //       close: () => {
    //         cbInstance.logout();
    //         cbInstance.closeAll();
    //         window.location.reload(true);
    //       },
    //     },
    //       );

    //   } else {
    //     message.destroy();
    //     message.error(this.props.get_customer_portal.message);
    //   }
    // }

  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible })
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };
  clickOnShakenStir = () => {
    this.setState({
      visible: false,
    });
    // save activity to mixpanel to track activity
    window.mixpanel.track("Click on Shaken/STIR.",{distinct_id: this.props.userData.data.email, user_id : this.props.userData.data.email, parent_id: this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email});
  }

  exportContact() {
    this.setState({modalVisible:true})
    this.hide();
  }

  logout() {
    this.hide();
    this.props.logout(this.props.history);
  }

  handleOk = () => {
    let userData = this.props.userData.data;
    this.setState({
      confirmLoading: true,
    });
    if(typeof userData.userActive !== "undefined" && !userData.userActive){
      message.destroy();
      message.info(commonMsg.veriyfication_msg);
      this.setState({
        modalVisible: false,
        confirmLoading: false,
      });
    }else{
      this.props.exportContact();
    }
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  openOurApp = () => {
      if( window.location.pathname == "/integrations/#linkOurApps" || window.location.pathname == "/integrations/" || window.location.pathname == "/integrations/integrations/"){
        var linked = document.querySelector("div [href='#linkOurApps']")
        linked.click();
      }else{
        this.props.history.push(integrationOurAppsUrl);
        }
        this.hide();
  }
  closeOurApp = () => {
    this.setState({
      isopen: false,
    })
}
  render() {
    const { parentEmail, isAcDetailDisabled, isAcDetailShow, userId, numbers, modalVisible,isopen
      , confirmLoading,importContactRights } = this.state;
    const { isProfileRemaining, isBlocked, fullName, roleData, isSsoEnabled } = this.props.userData.data;
    
    let isAcountDetailDisabled = isAcDetailDisabled;
    let isItemDisabled = isProfileRemaining;
    let isChangePassword = true;
    if(isBlocked){
      isAcountDetailDisabled = isItemDisabled = true;
    }
    if(isSsoEnabled && roleData.priority !== 1 && roleData.priority !== 5){ 
      isChangePassword = false
    }
    const content = (
      roleData.priority !== 5 ? 
        <TopbarDropdownWrapper className="isoUserDropdown">
        {parentEmail && <span onClick={this.hide} className="isoDropdownLink userdetailwordbreak">{parentEmail + ' (Owner)'}</span>}
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
          <Link onClick={this.hide} to={USER_PROFILE}>Profile</Link>
        </Button>
        {(this.props.userData && this.props.userData.data && this.props.userData.data.roleData && this.props.userData.data.roleData.priority!=3 && this.props.userData.data.roleData.priority!=4) && <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
          <Link onClick={this.hide} to={USER_PROFILE_DOCUMENT}>Documents</Link>
        </Button>}
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled || this.props.userData.data.isFreeTrialUser ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
			<Link onClick={(e) => {
            if (isItemDisabled || this.props.userData.data.isFreeTrialUser) {
                e.preventDefault();
            } else {
                this.hide();
            }
        }} 
        to={'/sms-verification'}>SMS Profile</Link>
        </Button>
        {this.props.userData.data.stripeId && !this.props.userData.data.parentId &&
          <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
            <Link onClick={this.clickOnShakenStir} to={SHAKEN_STIR}>SHAKEN/STIR</Link>
          </Button>
        }
        {this.props.userData.data.isFreeTrialUser ? 
          this.props.userData.data.roleData.priority == 1 &&
          <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
            <Link onClick={this.hide} to={PLAN_BILLING}>Plan and Billing</Link>
          </Button>
          : !(this.props.userData.data.isFreeTrialUser && !this.props.userData.data.expiredFreeTrailDate && !this.props.userData.data.stripeId) &&
          <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
            <Link onClick={this.hide} to={PLAN_BILLING}>Plan and Billing</Link>
          </Button>
        }
      
        {/* {(isAcDetailShow || (this.props.userData && this.props.userData.data && this.props.userData.data.roleData && this.props.userData.data.roleData.priority==2)) &&
          <Button onClick={() => this.props.getCustomerPortal(userId)} className={`isoDropdownLink isAcDetailBtn ${isAcountDetailDisabled? "itemDisabled" : ''}`} disabled={isAcountDetailDisabled}>Account Details</Button>
        } */}
        {isChangePassword && <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}><Link onClick={this.hide} to={CHANGE_PASSWORD}>Change Password</Link></Button>
        }
        {/* <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}><a onClick={this.hide} href='https://support.callhippo.com/' target='_blank'>Support & FAQ</a></Button> */}
        {/* {!numbers && */}
          <Button className={`isoDropdownLink isAcDetailBtn hidetourtab ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled} onClick={() => {
            this.hide();
            this.props.history.push(DASHBOARD);
            this.props.triggerTour({isTourOpen:true, isTourStarted: true, tourStartAt: 0});
          }}><span>Start Tour</span></Button>
        {/* } */}
        {((!(this.props.userData && this.props.userData.data && this.props.userData.data.roleData)) && (this.props.userData.data.parentId && this.props.userData.data.numberMasking))?null:
        this.state.exportContactModuleAccess[Action.READ]&&
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} onClick={() => this.exportContact()} disabled={isItemDisabled || !this.state.exportContactModuleAccess[Action.READ]}>Export Contacts</Button>}
        {importContactRights && <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled} ><Link onClick={this.hide} to={IMPORT_CONTACT}>Import Contacts</Link></Button>}
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}><a onClick={this.hide} href='https://callhippo.com/referral-program' target='_blank'>Refer a Friend</a></Button>        
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled} ><HashLink to={INTEGRATION+"#linkOurApps"}>Our Apps</HashLink></Button>
        <span className="isoDropdownLink" onClick={() => this.logout()}><IntlMessages id="topbar.logout" /></span>
      </TopbarDropdownWrapper> : 
      <TopbarDropdownWrapper className="isoUserDropdown">
        {parentEmail && <span onClick={this.hide} className="isoDropdownLink userdetailwordbreak">{parentEmail + ' (Owner)'}</span>}
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}>
          <Link onClick={this.hide} to={USER_PROFILE}>Profile</Link>
        </Button>
        {isChangePassword && <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}><Link onClick={this.hide} to={CHANGE_PASSWORD}>Change Password</Link></Button>}
        <Button className={`isoDropdownLink isAcDetailBtn ${isItemDisabled ? "itemDisabled" : ''}`} disabled={isItemDisabled}><a onClick={this.hide} href='https://callhippo.com/referral-program' target='_blank'>Refer a Friend</a></Button>        
        <span className="isoDropdownLink" onClick={() => this.logout()}><IntlMessages id="topbar.logout" /></span>
      </TopbarDropdownWrapper>
    );

    if (this.state.redirectToReferrer) {
      return <Redirect to={LANDING} />;
    }
    return (
      <div>
        <Popover
          placement="bottomRight"
          content={content}
          // trigger="click"
          visible={this.state.visible}
          onVisibleChange={() => this.handleVisibleChange()}
          // arrowPointAtCenter={true}
          >
          <div className="chflex chflexcenter">
            <span className="nmusercircle">{fullName ? fullName.charAt(0) : ""}</span>
            <span className="chflex chalignstart flexcolomch"><span className="usrname_topbar">{fullName}</span>
            {roleData && roleData.name && <span onClick={this.hide} className="isoDropdownLink userdetailwordbreak font12 user-qa">{roleData.name}</span>}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" style={{marginTop:'-20px'}}viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z" fill="#FFF"/></svg>
          </div>
        </Popover>
        <Modal
          title="Export Contacts"
          visible={modalVisible}
          onOk={() => this.handleOk()}    
          cancelText="No"
          okText="Yes"
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          className="exportYesNO"
          centered
        >
          <p>Are you sure you want to export your contacts?</p>
        </Modal>


         {/* our Apps Popup Start */}
         <Modal
          title="Our Apps"
          visible={isopen}
          onOk={this.closeOurApp}    
          cancelText="No"
          okText="Yes"
          confirmLoading={confirmLoading}
          onCancel={this.closeOurApp}
          className="ourApp_btn"
          centered
        >
          <p>Get access to our other apps that will help you use CallHippo in much efficiently <a href='https://dialer.callhippo.com/' target='_blank'>Web Dialer</a>, <a href='https://play.google.com/store/apps/details?id=com.callhippo.bueno.callhippo' target='_blank'>Mobile App Android</a>, <a href='https://apps.apple.com/us/app/callhippo-virtual-phone-system/id1256873576' target='_blank'>Mobile App iOS</a> and <a href='https://drive.google.com/drive/u/0/folders/1evlwg_bYbGzY6VFI_k3I3UhRZrxSgKKq' target='_blank'>Desktop App.</a></p>
        </Modal>
         {/* our Apps Popup End */}
      </div>
    );
  }

}

const mapStateToProps = state => {
  //console.log(state);
  return {
  userData:state.authentication.userData,
  log_out: state.authentication.log_out,
  export_contact: state.Contacts.export_contact,
  // get_customer_portal: state.usersReducer.get_customer_portal,
  isAuthenticated: state.authentication.isAuthenticated,
  update_common_settings: state.usersReducer.update_common_settings,
  user_details: state.usersReducer.user_details
}};

export default connect(mapStateToProps, { logout, exportContact, getCustomerPortal, triggerTour })(TopbarUser);
