import React from 'react';
import { Popover } from 'antd';

/**
 * Function returning the promise for antd form password validation rules
 * @param password string
 * @param fieldsToVerify Array
 */

export const validatePassword = (password, fieldsToVerify, popOverClass, popoverPlacement='bottom') => {
  const validations = [
    { condition: password && password.length >= 8, message: 'At least 8 characters long' },
    { condition: password && /\d/.test(password), message: 'Contain at least 1 number' },
    { condition: password && /[A-Z]/.test(password), message: 'Contain at least 1 uppercase letter' },
    { condition: password && /[a-z]/.test(password), message: 'Contain at least 1 lowercase letter' },
    { condition: password && /[!@#$%^&*().?":{}|<>]/.test(password), message: 'Contain at least 1 special character' },
  ];

  if (fieldsToVerify) {
    const containsAnyField = (field) => field && field.split(/[!#$%^&*()+\=\[\]{};':"\\|,<>\/?@._-]+/).some(fieldWord => password &&  fieldWord && password.toLowerCase().includes(fieldWord.toLowerCase()));
    const profileFieldCondition = (password && fieldsToVerify.some(field => containsAnyField(field.toLowerCase())));
    validations.push({ condition: password && !profileFieldCondition, message: 'Should not contain first name, last name, or part of email' });
  }

  /* if (fieldsToVerify) {
    const containsAnyField = (field) => field && field.split(/[!#$%^&*()+\=\[\]{};':"\\|,<>\/?@._-]+/).some(fieldWord => password && password.toLowerCase().includes(fieldWord.toLowerCase()));
    validations.push(...Object.entries(fieldsToVerify).map(([field, value]) => ({ condition: !containsAnyField(value), message: `Should not contain ${field}` })));
  } */

  return validations.every(({ condition }) => condition) ? Promise.resolve() : Promise.reject(<Popover visible={true} placement={popoverPlacement} content={validations.map(({ condition, message }, index) => (<div key={index} className={`validationwrapper ${popOverClass || ''}`}> <span className={`${condition ? 'checkcircle' : 'cancelicon'}`}></span><span>{message}</span></div>))} 
  {...(popOverClass === 'AuthPassValidator' && { getPopupContainer: (trigger) => document.getElementById('updProfPassInptFrmItm') })}
    ></Popover>);
};  