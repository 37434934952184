import { message } from 'antd';
import axios from 'axios';
const base_url = process.env.REACT_APP_BASE_URL;

export const CREATE_AI_AGENT = 'CREATE_AI_AGENT';
export const FETCH_ASSISTANTS = 'FETCH_ASSISTANTS';
export const DELETE_ASSISTANT = 'DELETE_ASSISTANT';
export const OPEN_ASSISTANT_SETTINGS = 'OPEN_ASSISTANT_SETTINGS';
export const SET_LOADING = 'SET_LOADING';
export const ALLOCATE_NUMBER = "ALLOCATE_NUMBER";
export const DE_ALLOCATE_NUMBER = "DE_ALLOCATE_NUMBER";

const getHeaders = ()=>{
    const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData?.authToken ?? '',
        userId = userData?._id ?? '',
        parentId = userData?.parentId ?? userData?._id;
    return {
        "Content-Type": 'application/json',
        authToken,
        parentId,
        id : userId
    };
};

const aiAgentAction = {
    createAiAgent: (postData) => {
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData?.authToken ?? '',
        userId = userData?._id ?? '';

        return async (dispatch) => {
            try {
                const result = await axios.post(`${base_url}aiagent/create`, postData, {
                    headers: {
                        "Content-Type": 'multipart/form-data',
                        id: userId,
                        authtoken: authToken,
                        parentId: userData.parentId ?? userData._id
                    }
                });
                if (result.data.success) {
                    dispatch({
                        type: CREATE_AI_AGENT,
                        payload: result.data.aiAgentData,
                    });
                    return result.data.aiAgentData;
                }
            } catch (err) {
                message.error(err?.response?.data?.error,3);
                return null;
            }
        }
    },

    fetchAssistants: () => async (dispatch) => {
        try {
            const response = await axios.get(`${base_url}aiagents`, {headers:getHeaders()});
            dispatch({ type: FETCH_ASSISTANTS, payload: response.data });
        } catch (error) {
            console.error('Error fetching assistants', error);
        } finally {
            dispatch({ type: SET_LOADING, payload: false });
        }
    },

    deleteAssistant: (agentId) => async (dispatch) => {
        try {
            await axios.delete(`${base_url}aiagent/delete/${agentId}`, {headers:getHeaders()});
            dispatch({ type: DELETE_ASSISTANT, payload: agentId });
            return true;
        } catch (error) {
            console.error('Error deleting assistant', error);
            return false;
        }
    },
    openAssistantSettings: (agentData) => (dispatch) => {
        dispatch({ type: OPEN_ASSISTANT_SETTINGS, payload: agentData });
    },
    allocateNumber: (agentId, phoneNumber,agentName,numberSid,deAllocatedNumber) => {
        return async (dispatch) => {
            try {
                const deAllocateNumber = deAllocatedNumber?.deAllocateNumber;
                const deAllocateNumberSid=deAllocatedNumber?.deAllocateNumberSid;
                const response = await axios.post(
                    `${base_url}aiagent/updatenumber`,
                    JSON.stringify({ agentId, phoneNumber,agentName,numberSid, deAllocateNumber,deAllocateNumberSid}),
                    {headers:getHeaders()}
                );

                if (response?.data?.success) {
                    dispatch({
                        type: ALLOCATE_NUMBER,
                        payload: { agentId, phoneNumber },
                    });
                    message.success('Number Allocated successfully.',3);
                }else{
                    message.error(response.data.message,3);
                }
            } catch (error) {
                message.error(error.response.data.error.replace(/400/,""),3);
            }
        };
    },
    deAllocateNumber: (agentId, phoneNumber,phoneNumberSid) => {
        return async (dispatch) => {
            try {
                const response = await axios.post(
                    `${base_url}aiagent/deAllocateNumber`,
                    JSON.stringify({ agentId, phoneNumber, phoneNumberSid}),
                    {headers:getHeaders()}
                );
                if (response?.data?.success) {
                    dispatch({
                        type: DE_ALLOCATE_NUMBER,
                        payload: { agentId },
                    });
                    message.success('Number deallocated successfully.',3);
                }
            } catch (error) {
                message.error(error.response.data.error.replace(/400/,""),3);
            }
        };
    }
}

export default aiAgentAction;