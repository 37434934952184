import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import number from '@iso/redux/number/reducer';
import usersReducer from '@iso/redux/users/reducer';
import activityReducer from '@iso/redux/activityFeed/reducer';
import authentication from '@iso/redux/authentication/reducer';
import dashboard from '@iso/redux/dashboard/reducer';
import liveCalls from '@iso/redux/dashboard/liveCallReducer';
import planReducer from '@iso/redux/plan/reducer';
import callPlannerReducer from '@iso/redux/callPlanner/reducer';
import callReportReducer from '@iso/redux/callReport/reducer';
import teamsReducer from '@iso/redux/teams/reducer';
import customMessage from "./customMessage/reducer";
import powerDialerReducer from '@iso/redux/powerDialer/reducer';
import campaignReducer from '@iso/redux/campaign/reducer';
import integration from '@iso/redux/integration/reducer';
import callScript from '@iso/redux/callscript/reducer';
import broadcastReducer from '@iso/redux/broadcast/reducer';
import pushserReducer from '@iso/redux/puhser/reducer';
import didGroupsReducer from '@iso/redux/didGroups/reducer';
import importContactReducer from '@iso/redux/importContact/reducer';
import assistantsReducer from '@iso/redux/assistants/reducer';

export default combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Contacts,
  modal,
  drawer,
  number,
  usersReducer,
  activityReducer,
  authentication,
  liveCalls,
  dashboard,
  planReducer,
  callPlannerReducer,
  callReportReducer,
  teamsReducer,
  powerDialerReducer,
  customMessage,
  integration,
  callScript,
  broadcastReducer,
  pushserReducer,
  campaignReducer,
  didGroupsReducer,
  importContactReducer,
  assistantsReducer
});
