import { CREATE_AI_AGENT, FETCH_ASSISTANTS, DELETE_ASSISTANT, OPEN_ASSISTANT_SETTINGS, SET_LOADING, ALLOCATE_NUMBER,DE_ALLOCATE_NUMBER } from './action';

const initialState = {
    assistants: [],
    loading: true,
    selectedAssistant: null,
}

export default function assistantsReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_AI_AGENT:
            return { ...state, selectedAssistant: action.payload, assistants: [...state.assistants, action.payload] }
        case FETCH_ASSISTANTS:
            return { ...state, assistants: action.payload.aiAgentData, loading: false };
        case DELETE_ASSISTANT:
            return { ...state, assistants: state.assistants.filter(a => a._id !== action.payload) };
        case OPEN_ASSISTANT_SETTINGS:
            return { ...state, selectedAssistant: action.payload };
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case ALLOCATE_NUMBER:
            return {
                ...state,
                selectedAssistant:
                    state?.selectedAssistant?._id === action.payload.agentId
                        ? { ...state.selectedAssistant, allocatedNumber: action.payload.phoneNumber }
                        : state.selectedAssistant,
            }
        case DE_ALLOCATE_NUMBER:
            return {
                ...state,
                selectedAssistant:
                    state?.selectedAssistant?._id === action.payload.agentId
                        ? { ...state.selectedAssistant, allocatedNumber: null }
                        : state.selectedAssistant,
            }
        default:
            return state;
    }

}