import axiosApi, {errorResponseStatus} from '../api';
import {ClearUserData} from "../api";
import Axios from 'axios';
export const GET_POWER_DIALER_LIST = 'GET_POWER_DIALER_LIST';
export const START_STOP_CAMPAIGN = 'START_STOP_CAMPAIGN';
export const DOWNLOAD_CAMPAIGN = 'DOWNLOAD_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const GET_CAMPAIGN_SETTING = 'GET_CAMPAIGN_SETTING';
export const UPDATE_CAMPAIGN_SETTING = 'UPDATE_CAMPAIGN_SETTING';
export const GET_AUTODIALER_DATA = 'GET_AUTODIALER_DATA';
export const GET_INTEGRATION_CAMPAIGN_DATA = 'GET_INTEGRATION_CAMPAIGN_DATA';
export const DELETE_AUTODIALER_DATA = 'DELETE_AUTODIALER_DATA';
export const UPDATE_AUTODIALER_DATA = 'UPDATE_AUTODIALER_DATA';
export const ADD_AUTODIALER_DATA = 'ADD_AUTODIALER_DATA';
export const GET_USER_TEAM_LIST = 'GET_USER_TEAM_LIST';
export const ADD_CAMPAIGNS = 'ADD_CAMPAIGNS';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
export const GET_ACTVITYFEED_POWER_DIALER_LIST = 'GET_ACTVITYFEED_POWER_DIALER_LIST';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_ACTIVITY_DATA = 'GET_ACTIVITY_DATA';
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const GET_SINGLE_CAMPAIGN_AGENT='GET_SINGLE_CAMPAIGN_AGENT'

const base_url = process.env.REACT_APP_BASE_URL;

export const powerDialer = (history,postData={}) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  var pathName = history.location ? history.location.pathname : 'powerDialer'
  let typeName = pathName.substring(pathName.lastIndexOf("/")+1);
  typeName = typeName?typeName:"powerDialer"
  let url = base_url + ((typeName == "powerDialer" ||typeName == "predictiveDialer" ||typeName == "speedToDial") ? "powerDialerGet" : typeName)+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+typeName;
  console.log('typeName: ', typeName);
  // let url = base_url + typeName+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+typeName;
  let data ;
  if(postData.searchText){
    // url = url + '&search='+postData.searchText;
    data = {
      search : postData.searchText
    }
  }else
  {
    data ={}
  }
  return axiosApi(`${url}`, "post",data, dispatch, `${GET_POWER_DIALER_LIST}`).then(function(res){
   if (res && !res.payload.success) {  
     return dispatch({
       type: "SET_CUSTOM_MESSAGE",
       payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res; 
});

};

export const startStopPowerDialer = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let campaignId = postData._id;
  let action = postData.action;
  let url = base_url + 'powerDialer/' + action + '/' + user_id;
  return axiosApi(`${url}`, "put", { "userId": user_id, "campaignId": campaignId }, dispatch, `${START_STOP_CAMPAIGN}`)
  // .then((res) => {
   
  //   if (res.payload.success) {
  //     return dispatch({
  //       type: "SET_CUSTOM_MESSAGE",
  //       payload: { message: `Campaign ${action=='stop' ? "paused":"started"} successfully.`, type: "success" }
  //     });
  //   } else {
  //     let error = res.payload.error.error;
  //     return dispatch({
  //       type: "SET_CUSTOM_MESSAGE",
  //       payload: { message: error, type: "error" }
  //     });
  //   }
  // });

};

export const downloadPowerDialerCampaign = (campaignId, dayDiff=0, isRequestFromCampaignList=false) => dispatch => {
  if (dayDiff > 90 && isRequestFromCampaignList) {
    dispatch({
      type: `${DOWNLOAD_CAMPAIGN}`,
      payload: { success: true, data: { message: "Your request is submitted successfully. You will receive email on registered Email ID soon." }, isEmailReport : true },
    })
    const userData = JSON.parse(localStorage.getItem("userData"));
    let authToken = userData ? userData.authToken : null;
    let userId = userData ? userData._id : null;
    
    Axios.post(`${base_url}powerDialer/download/${campaignId}?dayDiff=${dayDiff}`,{},{
      headers:{
        "Content-Type": "application/json",
        id: userId,
        authtoken: authToken,
      }
    });
  } else {
    let url = `${base_url}powerDialer/download/${campaignId}`;
    axiosApi(`${url}`, "post", {}, dispatch, `${DOWNLOAD_CAMPAIGN}`).then(function(res){
        if (!res.payload.success) {  
          return dispatch({
            type: "SET_CUSTOM_MESSAGE",
            payload: { message: res.payload.error.error, type: "error" }
           });
         }

     });
  }
};

export const deleteCampaign = (campaignId, parentId) => dispatch => {
  let url = base_url + 'powerDialer/' + campaignId + '/' + parentId;
  let del = axiosApi(`${url}`, "delete", {}, dispatch, `${DELETE_CAMPAIGN}`).then((res) => {
    powerDialer();
    return dispatch({
      type: "SET_CUSTOM_MESSAGE",
      payload: { message: "Successfully deleted.", type: "success" }
    });
  });
};
export const deleteAutoDialerData = _id => dispatch => {
  const url = base_url + 'autoDialer/data/' + _id;
  axiosApi(`${url}`, "delete", {}, dispatch, `${DELETE_AUTODIALER_DATA}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};
export const getCampaignSetting = (predictive, urlSame) => dispatch => {
  const user_id = JSON.parse(localStorage.getItem("userData"))._id;
  const url = base_url + 'powerDialer/setting/' + user_id + "?predictive=" + predictive + "&urlSame=" + urlSame;
  axiosApi(`${url}`, "get", {}, dispatch, `${GET_CAMPAIGN_SETTING}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};
export const getAutoDialerData = (predictive,currentPage=1,searchString="") => dispatch => {
  const user_id = JSON.parse(localStorage.getItem("userData"))._id;
  const url = base_url + "autoDialer/data/?predictive=" + predictive + "&current=" + currentPage + "&search=" + searchString;
  axiosApi(`${url}`, "get", {}, dispatch, `${GET_AUTODIALER_DATA}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};

export const getIntegrationCampaignData = (campaignId, integration = '') => dispatch => {
  const url = base_url + "integration/campaignData";
  axiosApi(`${url}`, "post", {campaignId, integration}, dispatch, `${GET_INTEGRATION_CAMPAIGN_DATA}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};

export const autoDialerDataCheck = (predictive, name="") => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let url = base_url + 'autoDialer/data/check';
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData ? userData.authToken : null;
  let billingtoken = userData ? userData.billingToken : null;
  return Axios({
    method: 'post',
    url: url,
    data: {predictive, name},
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  });
};

export const updateCampaignSetting = (data) => dispatch => {
  const user_id = JSON.parse(localStorage.getItem("userData"))._id;
  const url = base_url + 'powerDialer/setting/' + user_id;
  axiosApi(`${url}`, "post", data, dispatch, `${UPDATE_CAMPAIGN_SETTING}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};
export const updateAutoDialerData = (data) => dispatch => {
  const url = base_url + 'autoDialer/data/';
  axiosApi(`${url}`, "put", data, dispatch, `${UPDATE_AUTODIALER_DATA}`).then((res) => {
    if (res && !res.payload.success) {
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res;
  });
};
export const addAutoDialerData = (data) => dispatch => {
  const user_id = JSON.parse(localStorage.getItem("userData"))._id;
  const url = base_url + 'autoDialer/data/';
  const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  const authToken = userData ? userData.authToken : null;
  const billingtoken = userData ? userData.billingToken : null;
  Axios({
    method: 'post',
    url: url,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign Data created successfully.", type: "success" }
      });
      return dispatch({
        type: `${ADD_AUTODIALER_DATA}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if (error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: ADD_AUTODIALER_DATA,
        payload: error.response ? error.response.data : ""
      });
    })
};

export const addCampaign = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let parent_id = JSON.parse(localStorage.getItem("userData")).parentId || JSON.parse(localStorage.getItem("userData"))._id; ;
  let url = base_url + 'powerDialer/' + user_id + "/" + parent_id;
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData?userData.authToken:null;
  let billingtoken = userData?userData.billingToken:null;
  // axiosApi(`${url}`, "post", postData, dispatch, `${ADD_CAMPAIGNS}`)
  Axios({
    method:'post',
    url:url,
    data:postData,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign created successfully.", type: "success" }
      });
      return dispatch({
        type: `${ADD_CAMPAIGNS}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: ADD_CAMPAIGNS,
        payload: error.response ? error.response.data : ""
      });
    })
}

export const updateCampaign = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  ///let pid = postData.pid;
  const parent_id = JSON.parse(localStorage.getItem("userData")).parentId || JSON.parse(localStorage.getItem("userData"))._id; ;
  let url = base_url + 'powerDialer/update/'+ user_id + '/' + parent_id;
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData?userData.authToken:null;
  let billingtoken = userData?userData.billingToken:null;
  // axiosApi(`${url}`, "post", postData, dispatch, `${ADD_CAMPAIGNS}`)
  Axios({
    method:'post',
    url:url,
    data:postData,
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      // content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign updated successfully.", type: "success" }
      });
      return dispatch({
        type: `${UPDATE_CAMPAIGNS}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: UPDATE_CAMPAIGNS,
        payload: error.response ? error.response.data : ""
      });
    })
}

export const getUsersTeamList = (dashboardFilter = false) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let parent_id = JSON.parse(localStorage.getItem("userData")).parentId || JSON.parse(localStorage.getItem("userData"))._id; ;
  let url = base_url + 'getUsersTeamList/' + user_id + '/' + parent_id +'?powerDialerReq=true'+(dashboardFilter ? '&dashboardFilter=true' : '');
  axiosApi(`${url}`, "get", {}, dispatch, `${GET_USER_TEAM_LIST}`)
}
export const getActvityFeedPowerDialer = (history,postData={}) => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let typeName = "getActivityfeedPowerDialer"
  let url = base_url + typeName+'/' + user_id+'?current='+ (postData.current ?postData.current:1)+'&type='+'powerDialer';
  const userIdCookies = {
    cookies:`ch3krt12=${user_id}`
  }
  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  return axiosApi(`${url}`, "get", {}, dispatch, `${GET_ACTVITYFEED_POWER_DIALER_LIST}`, '', userIdCookies).then(function(res){
   if (res && !res.payload.success) {  
     return dispatch({
       type: "SET_CUSTOM_MESSAGE",
       payload: { message: res.payload.error.error, type: "error" }
      });
    }
    return res; 
});

};

export const getContacts = (history,postData={}) => dispatch => {
  let url = base_url + 'powerDialer/getContacts/'+postData.campaignMasterId+'?current='+ (postData.current ?postData.current:1);
  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  axiosApi(`${url}`, "get", postData, dispatch, `${GET_CONTACTS}`)
}

export const getActivityData = (postData={}) => dispatch => {
  let url = base_url + 'powerDialer/activityData/'+postData.campaignMasterId+'?current='+ (postData.current ?postData.current:1);
  if(postData.searchText){
    url = url + '&search='+postData.searchText;
  }
  axiosApi(`${url}`, "get", postData, dispatch, `${GET_ACTIVITY_DATA}`)
}

export const updateContact = ( campaignNumberId, postData ) => dispatch => {
    let url = base_url + 'powerDialer/updateContact/' + campaignNumberId;
    axiosApi(`${url}`, "post", postData, dispatch, `${UPDATE_CONTACTS}`).then(function(res){
      if (!res.payload.success) {  
        return dispatch({
          type: "SET_CUSTOM_MESSAGE",
          payload: { message: res.payload.error.error, type: "error" }
        });
      }
      
  });
};

export const deleteContact = campaignNumberIds => dispatch => {
  let url = base_url + 'powerDialer/delete/Contact';
  let del = axiosApi(`${url}`, "delete", { campaignNumberIds }, dispatch, `${DELETE_CONTACT}`);
};

// export const getSingleCampaignData = (campaignId) => dispatch => {
//   let user_id = JSON.parse(localStorage.getItem("userData"))._id;
//   let url = base_url + 'getSingleCampaignData/' + user_id+'?campaignId='+campaignId;
//   axiosApi(`${url}`, "get", {}, dispatch, `${GET_SINGLE_CAMPAIGN_AGENT}`)
// }
export const getSingleCampaignData = (campaignId) => dispatch => {
   let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let url = base_url + 'getSingleCampaignData/' + user_id+'?campaignId='+campaignId;
 return axiosApi(`${url}`, "get", {}, dispatch, `${GET_SINGLE_CAMPAIGN_AGENT}`)
}

// export const getSingleCampaignData = (campaignId) => dispatch => axiosApi(`${base_url}user/profile`, "get", {}, dispatch, `${planSetting.USER_PROFILE}`)
