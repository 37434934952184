import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Radio, message } from 'antd-v5';
import AuthOnboardingWrapper from './AuthOnboarding.style';
import authentication from '@iso/redux/authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import integrationList from "../../containers/Integrations/integrationsList";
import { useHistory } from 'react-router-dom';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
const integrationL = integrationList;
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const chlogo = imageUrl + 'chlogo.svg';

const { updateOnboardingDetails } = authentication;
const { DASHBOARD } = APP_PRIVATE_ROUTE;

const Callhippoinfopopup = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.authentication.userData.data);
  const { view } = useSelector(state => state.App);
  const [showDemoSchedule, setShowDemoSchedule] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [primaryPurpose, setPrimaryPurpose] = useState('');
  const [preferredSoftware, setPreferredSoftware] = useState('');

  const handleSubmit = e => {
    const { otherPurpose, jobRole, otherSoftware } = e;
    setIsDisabled(true);
    let filterSoftware = e.preferredSoftware ? e.preferredSoftware.filter((data) => data !== "Others").join(",") : '';
    let obj = {
      primaryPurpose: e.primaryPurpose === 'Other' ? otherPurpose : e.primaryPurpose,
      bestJobRole: jobRole,
      preferredSoftware: otherSoftware ? filterSoftware.concat("," + otherSoftware) :  filterSoftware.concat(","),
    };
    dispatch(updateOnboardingDetails(userData._id, obj)).then((res) => {
      if (view === 'MobileView') {
        message.error("Please use desktop version of CallHippo for application login");
        setTimeout(() => {
          logout();
        }, 3000);
      } else {
        if (userData?.teamsize !== 'Just me' && userData?.teamsize !== '3-4' && userData?.teamsize !== '2') {
          props.redirectDashboard();
        } else {
          props.onBoardingFinish();
          userData.isOnBoardingProcessPending = false;
          let userDetail = JSON.parse(localStorage.getItem('userData'));
          userDetail.isOnBoardingProcessPending = false;
          userDetail.teamsize = userData?.teamsize;
          localStorage.setItem("userData", JSON.stringify(userDetail));
          setIsDisabled(false);
          window.location.href = DASHBOARD;
        }
      }
    });
  };

  const logout = () => {    
    dispatch(authentication.logout([]))
  }

  const onChangePrimaryPurpose = (e) => {
    setPrimaryPurpose(e)
  }

  const onChangeSoftware = (e) => {
    setPreferredSoftware(e.toString())
  }
  
  const [formLayout, setFormLayout] = useState('vertical');
  const { Option } = Select;
  const formItemLayout =
    formLayout === 'vertical'
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      }
      : null;

  return (
    <AuthOnboardingWrapper>
      <div className="infopopupwrapper">
       {!showDemoSchedule && 
       <div className="profile-form-sd">
          <Form onFinish={handleSubmit} {...formItemLayout} form={form}>
              <Form.Item name="primaryPurpose" label="The Primary purpose of using CallHippo?" rules={[{ required: true, message: 'Required' }]}>
                <Select placeholder="Select option" onChange={onChangePrimaryPurpose}>
                  <Option key="Sales" value="Sales">Sales</Option>
                  <Option key="Customer_Support" value="Customer Support">Customer Support</Option>
                  <Option key="Call_Center" value="Call Center">Call Center</Option>
                  <Option key="Office_Phone_System" value="Office Phone System">Office Phone System</Option>
                  <Option key="both" value="Both Sales & Customer Support">Both Sales & Customer Support</Option>
                  <Option key="Other" value="Other">Other</Option>
                </Select>
              </Form.Item>
              {primaryPurpose == 'Other' &&
                <Form.Item  {...formItemLayout} name="otherPurpose" label="" dependencies={['primaryPurpose']} rules={[{ required: (form.getFieldValue('primaryPurpose') === 'Other'), message: 'Required' }]}>
                <Input placeholder="" />
                </Form.Item>
              }
              <Form.Item name="jobRole" label="Which CallHippo products are you interested in?" rules={[{ required: true, message: 'Required' }]}>
                  <Select placeholder="Select option">
                    <Option key="Office_Phone_System" value="Office Phone System">Office Phone System</Option>
                    <Option key="Business_Phone_System" value="Business Phone System">Call Center Solution</Option>
                    <Option key="Voice_Broadcast" value="Voice Broadcast">Voice Broadcast</Option>
                    <Option key="Whatsapp_Business" value="Whatsapp Business">Whatsapp Business</Option>
                    <Option key="Whatsapp_Shared_Inbox" value="Whatsapp Shared Inbox">Whatsapp Shared Inbox</Option>
                  </Select>
              </Form.Item>
              <Form.Item className='prefSoftFormItem' name="preferredSoftware" label="Any preferred software you want CallHippo to integrate with?">
                <Select placeholder="Select option" mode="multiple" onChange={onChangeSoftware}>
                  {Object.keys(integrationL).map((data, key) => <Option key={integrationL[data].displayName} value={integrationL[data].displayName}>
                    {integrationL[data].displayName}
                  </Option>)}
                  <Option key="Others" value="Others">Others</Option>
                </Select>
              </Form.Item>
              {preferredSoftware.includes('Others') &&
                <Form.Item name="otherSoftware" label="" dependencies={['preferredSoftware']}>
                  <Input placeholder="" />
                </Form.Item>
              }
              {/* CAL7747:start */}
              {/* <Form.Item className='minHeight65' name="optionn" label="How did you hear about us?" rules={[{ required: false, message: 'Please let us know!' }]}>
                {getFieldDecorator('howDidYouHear', {
                  rules: [
                    {
                      required: false,
                      message: 'Not Required'
                    }
                  ]
                })(<Select placeholder="Select option" onChange={onChangeHowDidYouHear}>
                  <Option key="Search_Engine" value="Search Engine">Google / Search Engine</Option>
                  <Option key="Software_Marketplace" value="Software Marketplace">Software Marketplace</Option>
                  <Option key="Social_Media" value="Social Media">Social Media</Option>
                  <Option key="Through_a_Friend" value="Through a Friend">Through a Friend</Option>
                  <Option key="Other" value="Other">Other (please specify)</Option>
                </Select>)}
              </Form.Item>
              {howDidYouHear == 'Other' &&
                <Form.Item  {...formItemLayout} name="otherHear" label="" className='minHeight65'>
                  {getFieldDecorator('otherHear', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(<Input placeholder="" onChange={onChangeOtherHear} />)}
                </Form.Item>} */}
              {/* CAL7747:end */}
              <Form.Item className="text-center minHeight50">
                <Button htmlType="submit" className="infopopupbtn" disabled={isDisabled}>Submit</Button>
              </Form.Item>
          </Form>
        </div>
        }
      </div>
      
    </AuthOnboardingWrapper>
  )
}

export default Callhippoinfopopup;
