import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const Changeemailpopover = WithDirection(styled.div`
.startnowText {
    color:#333;
    font-weight:bold;
    margin-left:5px;
    cursor: pointer;
}
.changeemailcancelbtn{
    background:#d0cecf;
    margin-right:5px;
    color:#fff;
}
.changeemailcancelbtn.ant-btn:hover, .ant-btn:focus{
    background:#d0cecf;
    color:#fff;
    border-color:#d0cecf;
}
.changeemailwrapper{
    height:150px;
}
.signuptext{
    display:flex;
    align-items:center;
    margin-bottom:10px;
    line-height: normal;
}
.sigupicon{
    font-size:18px;
    margin-right:5px;
}
.emailtextwrapper{
    margin-bottom:10px;    
}
.emailaddressinput{
    margin-bottom:15px;   
}
.activationemailwrapper{
    background: #ffece6;       
    padding: 15px 20px;
    margin: 0px 0px 0px;
    width: 100%;
    text-align:center;
    height: 50px;
    border-radius: 10px 0 0 0;
}
.activationemailbold{
    font-weight:600;
}
.activationemailchange{
    color:#e17648;
}
.changeemailpopover{
    cursor:pointer;
} 
.changemailform .ant-form-item-label{
    padding:0;
}
.changemailform .ant-form-item-control-wrapper{
    margin-bottom: 5px;
}
.freeTrialCol{
    display: flex;
    justify-content: center;
    align-items: center;

    .freeTrialClose{
        font-size: 17px;
        font-weight: 600;
        margin-left: 6px;
        color: #00000069;
        cursor: pointer;
    }
}
`
);
export default Changeemailpopover;