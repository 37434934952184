import styled from 'styled-components';

const AuthOnboardingModalWrapper = ComponentName => styled(ComponentName)`
  .profile-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
    color: #000000D9;
  }
  .profile-heading-wrapper{
    padding: 0 30px;
  }
  .profile-description {
    text-align: center;
    font-size: 13px;
    margin: 0 26px;
    color: #000000D9;
  }
  .model-logo {
    height: 50px;
    width: 50px;
    margin: 5px auto;
    display: flex;
  }
  .profile-form-sd{
    padding: 0 30px;
  }
  .profile-form-sd .ant-form-item-explain-error {
    font-size: 10px;
    // position: absolute;
    // bottom: -2px;
    // left: 5px;
  }
  .profile-form-sd .ant-form-vertical .ant-form-item-label >label{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  .profile-form-sd .ant-input .react-tel-input .form-control {
    height: 36px !important;
    width: 100% !important;
  }
  .profile-form-sd .ant-input {
    border: 1px solid #D9D9D9 !important;
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 6px !important;
    height: 36px !important;
    padding: 10px 6px;
  }
  .profile-form-sd .ant-form-item {
    margin-bottom: 14px !important;
  }
  ${'' /* .profile-form-sd :where(.css-dev-only-do-not-override-1p4sm1f).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  } */}
  .ant-modal-body {
    padding: 0px !important;
  }
  .profile-form-sd .ant-select-single {
    text-align: left !important;
    height: 36px;
  }
  .profile-form-sd .profile-next,
  .infopopupbtn {
    width: 100%;
    background-color: #E27849;
    border: none;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    letter-spacing: 1px;
    color: #fff !important;
  }
  .profile-form-sd .profile-next:hover,
  .infopopupbtn:hover {
    background-color:rgb(247, 123, 69) !important;
  }
  .profile-form-sd .ant-form-item-explain-error{
    text-align: left;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    display: none !important;
  }
  .firstStepOnboardingFormRow{
    display: flex !important;
    .ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
      right: -9px !important;
      left:unset !important;
      color: #000 !important;
      font-weight: 500;
      margin:0 !important;
      position: absolute !important;
      top: 2px !important;
    }
  }
  // input phone number 
    .form-control {
    width: 100% !important;
  }

  .form-control:focus {
    outline: none;
    border-color: #1890ff !important; /* Border color on focus */
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
  }

  .flag-dropdown {
    border-right: 2px solid #fa8c16; /* Custom border for flag dropdown */
  }
  .ant-modal-content {
    padding: 30px 0 !important;
  }
  // progressbar 
  .progress-bar-wrpper{
    width: 100%;
    height: 4px;
    background-color: #d9d9d9;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 2px;
    }
   .progress-bar {
      height: 100%;
      background-color: #fa8c16;
      transition: width 0.3s ease-in-out;
      position: relative;
    }
  
    .progress-bar::after {
      content: "";
      width: 12px;
      height: 12px;
      background-color: #fa8c16;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      right: 5px;
      transform: translateX(50%);
      transition: all 0.3s ease-in-out;
    }
`;
export default AuthOnboardingModalWrapper;
