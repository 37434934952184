import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Steps, message, Row, Col } from "antd-v5";
import cookie from "react-cookies";
import styled from "styled-components";
import AuthOnboardingModalWrapper from "./AuthOnboardingModal.style";
import AuthOnboarding from "./AuthOnboarding";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../assets/images/chcollapselogo.svg";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import { validatePassword } from '@iso/components/utility/validatePassword';
import usersAction from '@iso/redux/users/actions';
import authentication from '@iso/redux/authentication/action';
import RedirectToChApp from '@iso/components/utility/RedirectToChApp';
import WithDirection from '@iso/lib/helpers/rtl';
import Modals from '@iso/components/Feedback/Modal';

import { APP_PRIVATE_ROUTE, APP_PUBLIC_ROUTE } from '@iso/components/root/routeConstants';
import AuthOnboardingStyle from "./AuthOnboarding.style";

const isoModal = AuthOnboardingModalWrapper(Modals);
const Modal = WithDirection(isoModal);

const { DASHBOARD } = APP_PRIVATE_ROUTE;
const { LANDING, LOGIN_IN, AUTH_PROFILE } = APP_PUBLIC_ROUTE;
const { updateProfileData, sendOTPForVerify, verifyOTP } = usersAction;

const { Option } = Select;
const { Step } = Steps;

const AuthOnboardingProcessModal = () => {
    const {
        _id,
        email,
        phoneNumber,
        fullName,
        signUpType,
        isProfileRemaining,
        setNewPassword,
        is2faRememberSet,
        isOnBoardingProcessPending,
        allUsers,
        teamsize
    } = useSelector(state => state.authentication.userData.data);

    const { view } = useSelector(state => state.App);
    
    const [form] = Form.useForm();
    let history = useHistory();
    const dispatch = useDispatch();

    const [userPhoneNumber, setUserPhoneNumber] = useState(phoneNumber)
    const [isFirstSubmit, setIsFirstSubmit] = useState(false)
    const [checkPhoneNumber, setCheckPhoneNumber] = useState(false)
    const [redirectToChApp, setRedirectToChApp] = useState(false)
    const [current, setCurrent] = useState(0);
    const [userPayloadData, setUsepayloadData] = useState()
    const [prfileEmail, setPrfileEmail] = useState( signUpType === 'gmail' && isProfileRemaining ? email : fullName || email);

        useEffect(() => {
            if(process.env.REACT_APP_ENVIRONMENT !== 'JENKINS' && !isProfileRemaining && isOnBoardingProcessPending){
                setCurrent(current + 1);
            }
        }, [isProfileRemaining, isOnBoardingProcessPending])

    const successMsg = msg => { message.destroy(); message.success(msg) };
    const errorMsg = msg => { message.destroy(); message.error(msg) };

    const onProfileSubmit = async (payloadData) => {
        setIsFirstSubmit(true);
        const phoneNumber = signUpType === "gmail" && checkPhoneNumber ? `+${userPhoneNumber.replace(/\+/g, '')}` : userPhoneNumber;
        const profileData = { ...payloadData, phoneNumber, _id, formStatus: true };
        try {
            const { payload } = await dispatch(updateProfileData(_id, profileData));
            if (payload.success) {
                payloadData.isProfileRemaining = false;
                if (payloadData.password) payloadData.isPasswordAbsent = false;
                if (allUsers?.length) {
                    payloadData.allUsers = [{ ...allUsers[0], fullName: payloadData.fullName }, ...allUsers.slice(1)];
                }
                dispatch({ type: authentication.UPDATE_COMMON_USER_DATA, payload: { "data": payloadData, type: "changeAuth" } });
                setUsepayloadData(payloadData);
                payloadData.sendVia = 'sms';
                if (process.env.REACT_APP_ENVIRONMENT === 'JENKINS') {
                    setRedirectToChApp(true);
                    window.location.href = DASHBOARD;
                }
                setCurrent(current + 1);
            } else {
                errorMsg(payload.message);
            }
        } catch (err) {
            console.log("🚀 ========== ~ file: AuthOnboardingModal.js:181 ~ updateProfileData ~ err:", err);
        }
    };

    const showCalendlyPopUP = () => {
        history.push(AUTH_PROFILE)
    }

    const steps = [
        {
            content: (
                <div className="profile-form-sd">
                    <Form layout="vertical" onFinish={onProfileSubmit} form={form}>
                        <Row gutter={16} className="firstStepOnboardingFormRow">
                            <Col span={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[{ required: true, message: "Please input your company name!" }]}
                                >
                                    <Input placeholder="Your Company Name" />
                                </Form.Item>
                            </Col>  
                            <Col span={12}>
                                <Form.Item
                                    name="numberOfEmployees"
                                    label="Number of Employees"
                                    rules={[{ required: true, message: "Please select the Number of Employees!" }]}
                                >
                                    <Select placeholder="Select option" className="w-100">
                                        <Option value="1-10">1-10</Option>
                                        <Option value="11-50">11-50</Option>
                                        <Option value="51-200">51-200</Option>
                                        <Option value="201-500">201-500</Option>
                                        <Option value="501-1000">501-1000</Option>
                                        <Option value="1000+">1000+</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {signUpType == "gmail" &&    
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="Phone Number"
                                            rules={[{
                                                required: true,
                                                message: "Please input your Phone Number!"
                                            }]}
                                        >
                                            <PhoneInput
                                                country={cookie.load("userCountry") ? cookie.load("userCountry").toLowerCase() : 'us'}
                                                value={userPhoneNumber}
                                                onChange={phone => { setUserPhoneNumber(phone) }}
                                                enableSearch
                                                searchPlaceholder="Enter country name"
                                                searchClass="phoneInputSearchClass"
                                                inputProps={{ name: "phoneNumber", required: true }}
                                                isValid={(value, country) => {
                                                    const number = value.slice(country.dialCode.length).trim();
                                                    const isNumberValid = isValidPhoneNumber(value, country.iso2.toUpperCase());
                                                    if (!number || number.length < 8 || !isNumberValid) {
                                                        setCheckPhoneNumber(false);
                                                        return isFirstSubmit ? "Enter a valid Phone Number" : true;
                                                    }
                                                    if (value && value !== country.countryCode && !isNumberValid) {
                                                        setCheckPhoneNumber(true);
                                                        return `This number might be invalid for ${country.name}`;
                                                    }
                                                    setCheckPhoneNumber(true);
                                                    return true;
                                                }}
                                                defaultErrorMessage="Enter valid Phone Number"
                                            />
                                        </Form.Item>
                                    </Col> 
                                    <Col span={12}>
                                        <Form.Item
                                            name="teamsize"
                                            label="Number of Users/Agents"
                                            rules={[{ required: true, message: "Please select the number of users/agents!" }]}
                                        >
                                            <Select placeholder="Select option" className="w-100">
                                                <Option value="Just me">Just me</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3-4">3-4</Option>
                                                <Option value="5-20">5-20</Option>
                                                <Option value="21-49">21-49</Option>
                                                <Option value="50+">50+</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </> 
                            }
                            {signUpType !== "gmail" &&    
                                <Col span={24} id='updProfPassInptFrmItm'>
                                    <Form.Item 
                                    label="Password"
                                    name="password"
                                    rules={[{ 
                                        required: true, 
                                        validator: (_, value) => validatePassword(value, [email],'AuthPassValidator','left') 
                                    }]}
                                    >
                                        <Input.Password type='password' className="chInputField_newSignIn text-dark" size="large" maxLength={100} />
                                    </Form.Item>
                                </Col>
                            }  
                        </Row>
                        <Button className="profile-next" htmlType="submit">Next</Button>
                    </Form>
                </div>
            ),
        },
        {
            content: (
                <AuthOnboarding onBoardingFinish={() => setRedirectToChApp(true) } redirectDashboard={showCalendlyPopUP} />
            ),
        },
    ];

    if (redirectToChApp) {
        return (
            <RedirectToChApp />
        );
    }

    return (
        <div>
            <Modal
                visible={isOnBoardingProcessPending}
                footer={null}
                width={600}
                closable={false}
                className="complete-profile-modal"
            >
                <div className="profile-heading-wrapper">
                    <img className="model-logo" src={logo} alt="CallHippo Logo" />
                    <h1 className="profile-heading">Welcome to CallHippo</h1>
                    <p className="text-center profile-description">
                        Help us by completing your profile so we can create a more engaging experience for you.
                    </p>
                </div>
                    <div className="progress-bar-wrpper">
                        <div
                            className="progress-bar"
                            style={{ width: `${(current + 1) * 50}%` }}
                        ></div>
                    </div>
                    <div>{steps[current].content}</div>
            </Modal>
        </div>
    );
};

export default AuthOnboardingProcessModal;