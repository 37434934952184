import axiosApi from "../api";
import Axios from "axios"

const base_url = process.env.REACT_APP_BASE_URL;
const planSetting = {

    GET_PLAN: 'GET_PLAN',
    RECH_STATUS: 'RECH_STATUS',
    COUPON: 'COUPON',
    CARDS: 'CARDS',
    API_TOKEN: 'API_TOKEN',
    CONTROLHIPPO_TOKEN: 'CONTROLHIPPO_TOKEN',
    USER_PROFILE: 'USER_PROFILE',
    ADD_CONTACT: 'ADD_CONTACT',
    REMOVE_CONTACT: 'REMOVE_CONTACT',
    NUM_BLOCK: 'NUM_BLOCK',
    NUM_LIST: 'NUM_LIST',
    ADD_CREDITS: 'ADD_CREDITS',
    RECH_VALUE: 'RECH_VALUE',
    INTERNATIONAL_SMS: 'INTERNATIONAL_SMS',    
    PLAN_UPGRADE: 'PLAN_UPGRADE',
    RMNUM_BLOCK: 'RMNUM_BLOCK',
    UPDATE_NUM_BLOCK: 'UPDATE_NUM_BLOCK',
    GET_IP_ADDRESS: 'GET_IP_ADDRESS',
    ADD_IP_ADDRESS: 'ADD_IP_ADDRESS',
    DELETE_IP_ADDRESS: 'DELETE_IP_ADDRESS',
    ACCESS_MODULE: 'ACCESS_MODULE',
    GET_HOLIDAY_DATA: 'GET_HOLIDAY_DATA',
    DELETE_HOLIDAY: 'DELETE_HOLIDAY',
    GET_USERS_TEAM_LIST: 'GET_USERS_TEAM_LIST',
    ADD_EDIT_HOLIDAY: 'ADD_EDIT_HOLIDAY',
    VOICEMAIL_HOLIDAY: 'VOICEMAIL_HOLIDAY',
    RECORDING_RIGHTS : 'RECORDING_RIGHTS',
    PRICE_LIMIT: 'PRICE_LIMIT',
    DEFAULT_COUNTRY_CODE:'DEFAULT_COUNTRY_CODE',
    UPDATE_USER_PLAN_DATA:'UPDATE_USER_PLAN_DATA',
    GET_AMOUNT_DUE:'GET_AMOUNT_DUE',
    PAY_AMOUNT_DUE:'PAY_AMOUNT_DUE',
    AUDIT_LOGS : 'AUDIT_LOGS',
    REPORT_STATUS : 'REPORT_STATUS',
    SET_REPORTS : 'SET_REPORTS',
    ADD_WEBHOOK_URL : 'ADD_WEBHOOK_URL',
    GET_WEBHOOK_LIST : 'GET_WEBHOOK_LIST',
    REMOVE_WEBHOOK_URL : 'REMOVE_WEBHOOK_URL',
    UPDATE_WEBHOOK_URL : 'UPDATE_WEBHOOK_URL',
    UPDATE_ADDONS : 'UPDATE_ADDONS',
    GET_POPUPPLAN_DATA : "GET_POPUPPLAN_DATA",
    UPDATE_USERSEAT : "UPDATE_USERSEAT",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    RESUME_ACCOUNT: "RESUME_ACCOUNT",
    REVOKE_ACCOUNT: "REVOKE_ACCOUNT",
    GET_SCHEDULED_CHANGES: "GET_SCHEDULED_CHANGES",
    CALL_RECORDING_TOGGLE: "CALL_RECORDING_TOGGLE",
    GET_COLLECT_PAYMENT_NOW: "GET_COLLECT_PAYMENT_NOW",
    GET_PDF_INVOICE: "GET_PDF_INVOICE",
    SEND_PDF_INVOICE_TO_EMAIL: "SEND_PDF_INVOICE_TO_EMAIL",
    GET_ESTIMATION: "GET_ESTIMATION",
    REACTIVATE_ACCOUNT: "REACTIVATE_ACCOUNT",
    DOWNLOAD_DNC: "DOWNLOAD_DNC",
    CURRENCY_CHANGE_REQUEST: "CURRENCY_CHANGE_REQUEST",
    GET_SWAP_SUBSCRIPTION_ESTIMATE: "GET_SWAP_SUBSCRIPTION_ESTIMATE",
    ADD_SHIPPING_ADDRESS: "ADD_SHIPPING_ADDRESS",
    GET_GST_DETAILS: "GET_GST_DETAILS",
    GET_HOSTED_PAGE_URL: "GET_HOSTED_PAGE_URL",
    RETRIVE_HOSTED_PAGE_DATA: "RETRIVE_HOSTED_PAGE_DATA",

    getPlan: postData => dispatch => axiosApi(`${base_url}billing/plan/user`, "post", postData, dispatch, `${planSetting.GET_PLAN}`),
    
    currencyChangeRequest: postData => dispatch => axiosApi(`${base_url}currencyChangeRequest`, "post", postData, dispatch, `${planSetting.CURRENCY_CHANGE_REQUEST}`),

    getScheduledChanges: postData => dispatch => axiosApi(`${base_url}getScheduledChanges`, "post", postData, dispatch, `${planSetting.GET_SCHEDULED_CHANGES}`),

    getPopUpPlanData: postData => dispatch => axiosApi(`${base_url}billing/getpopupplandata/user`, "post", postData, dispatch, `${planSetting.GET_POPUPPLAN_DATA}`),

    changeAutoRechStatus: postData => dispatch => axiosApi(`${base_url}billing/plan/changeAutoRechargeStatus`, "post", postData, dispatch, `${planSetting.RECH_STATUS}`),

    changeDefaultCountryCode: postData => dispatch => axiosApi(`${base_url}addRemoveCounrtyCode/${postData.userId}`, "post", postData, dispatch, `${planSetting.DEFAULT_COUNTRY_CODE}`),

    changeAutoRechValue: postData => dispatch => axiosApi(`${base_url}billing/plan/changeAutoRechargeValue`, "post", postData, dispatch, `${planSetting.RECH_VALUE}`),
    internationalSmsStatus: postData => dispatch => axiosApi(`${base_url}user/internationalSms`, "post", postData, dispatch, `${planSetting.INTERNATIONAL_SMS}`),
    getCards: postData => dispatch => axiosApi(`${base_url}billing/user/cards`, "post", postData, dispatch, `${planSetting.CARDS}`),

    getApiToken: postData => dispatch => axiosApi(`${base_url}user/apitoken`, "put", postData, dispatch, `${planSetting.API_TOKEN}`),

    getControlHippoToken: postData => dispatch => axiosApi(`${base_url}user/controllHippoApiToken`, "post", postData, dispatch, `${planSetting.CONTROLHIPPO_TOKEN}`),

    getProfile: () => dispatch => axiosApi(`${base_url}user/profile`, "get", {}, dispatch, `${planSetting.USER_PROFILE}`),

    addContact: postData => dispatch => axiosApi(`${base_url}billing/chargebee/addContact`, "post", postData, dispatch, `${planSetting.ADD_CONTACT}`),

    getNumBlockList: postData => dispatch => {

      let URL = `${base_url}billing/plan/getBlockNumberList?current=${(postData.current ?? 1)}&pageSize=${(postData.pageSize ?? 6)}`
      if (postData.searchText) {
        URL += '&search=' + postData.searchText
      }

      return axiosApi(URL, "post", postData, dispatch, `${planSetting.NUM_LIST}`)
    },

    addNumBlock: postData => dispatch => axiosApi(`${base_url}billing/plan/addNUmberToBlockListNew`, "post", postData, dispatch, `${planSetting.NUM_BLOCK}`),

    removeNumBlock: postData => dispatch => axiosApi(`${base_url}billing/plan/removeNumberFromBlockListNew`, "post", postData, dispatch, `${planSetting.RMNUM_BLOCK}`),

    updateNumBlock: postData => dispatch => axiosApi(`${base_url}billing/plan/updateNumberFromBlockListNew`, "post", postData, dispatch, `${planSetting.UPDATE_NUM_BLOCK}`),
    
    addCredits: (postData, userId) => dispatch => axiosApi(`${base_url}billing/plan/addCredits/${userId}`, "post", postData, dispatch, `${planSetting.ADD_CREDITS}`),

    getIpAddress: userId => dispatch => axiosApi(`${base_url}billing/plan/whitelisting/${userId}`, "get", {}, dispatch, `${planSetting.GET_IP_ADDRESS}`),

    addIpAddress: postData => dispatch => axiosApi(`${base_url}billing/plan/whitelisting`, "post", postData, dispatch, `${planSetting.ADD_IP_ADDRESS}`),

    deleteIpAddress: postData => dispatch => axiosApi(`${base_url}billing/plan/whitelisting/${postData}`, "delete", {}, dispatch, `${planSetting.DELETE_IP_ADDRESS}`),

    accessModule: (postData, userId) => dispatch => axiosApi(`${base_url}user/updateAccesModule/${userId}`, "put", postData, dispatch, `${planSetting.ACCESS_MODULE}`),

    getHolidayData: postData => dispatch => axiosApi(`${base_url}holidays?skip=${postData}`, "get", {}, dispatch, `${planSetting.GET_HOLIDAY_DATA}`),

    deleteHoliday: postData => dispatch => axiosApi(`${base_url}holidays/${postData.holidayDeleteId}`, "delete", {}, dispatch, `${planSetting.DELETE_HOLIDAY}`),

    addEditHoliday: postData => dispatch => axiosApi(`${base_url}holidays/${postData.data.holidayId ? postData.data.holidayId : ''}`, "post", postData, dispatch, `${planSetting.ADD_EDIT_HOLIDAY}`),

    voicemailHoliday: postData => dispatch => axiosApi(`${base_url}holidays`, "post", postData, dispatch, `${planSetting.VOICEMAIL_HOLIDAY}`),

    changeRecordingRights: postData => dispatch => axiosApi(`${base_url}recordingRights`, "post", postData, dispatch, `${planSetting.RECORDING_RIGHTS}`),

    changeNumberMasking: (postData={}) => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken
      return Axios.post(`${base_url}api/v1/numberMaskingRights`,postData,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
      }) 
  },
  addNumberMasking: (postData) => {
    const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken
      return Axios.post(`${base_url}api/v1/addNumberMasking`,postData,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
      }) 
  },
    changeDownloadReportRights: postData => dispatch => axiosApi(`${base_url}downloadReportRights`, "post", postData, dispatch, `DOWNLOAD_REPORT_RIGHTS`),

    getUsersTeamList: (userId,isRequestFromNumberMasking = false, parentId) => dispatch => axiosApi(`${base_url}getUsersTeamList/${userId}/${parentId}?isRequestFromNumberMasking=${isRequestFromNumberMasking}`, "get", {}, dispatch, `${planSetting.GET_USERS_TEAM_LIST}`),

    removeContact: postData => dispatch => axiosApi(`${base_url}billing/chargebee/removeContact`, "post", postData, dispatch, `${planSetting.REMOVE_CONTACT}`),

    planUpgrade: (postData, userId) => dispatch => 
    axiosApi(`${base_url}billing/plan/upgrade/${userId}`,"post",postData,dispatch,`${planSetting.PLAN_UPGRADE}`),
    
    priceLimit: (id, postData) => dispatch => axiosApi(`${base_url}user/${id}/setting`,"put",postData,dispatch,`${planSetting.PRICE_LIMIT}`),

    getDueAmount: (id,invoiceStatus,pagination) => dispatch => axiosApi(`${base_url}getAmountDueData/${id}/${invoiceStatus}?${pagination}`,"get", {},dispatch,`${planSetting.GET_AMOUNT_DUE}`),
    
    getCollectPaymentNow: (id,redirectUrl) => dispatch => axiosApi(`${base_url}getCollectPaymentNow/${id}/${redirectUrl}`,"get", {},dispatch,`${planSetting.GET_COLLECT_PAYMENT_NOW}`),
    
    getPDFInvoice: (id,invoiceId) => dispatch => axiosApi(`${base_url}getPDFInvoice/${id}/${invoiceId}`,"get", {},dispatch,`${planSetting.GET_PDF_INVOICE}`),
    
    sendPDFInvoiceToEmail: (id,invoiceId) => dispatch => axiosApi(`${base_url}sendPDFInvoiceToEmail/${id}/${invoiceId}`,"get", {},dispatch,`${planSetting.SEND_PDF_INVOICE_TO_EMAIL}`),

    payDueAmount: (id, postData) => dispatch => axiosApi(`${base_url}clearPaymentDue/${id}`,"post",postData,dispatch,`${planSetting.PAY_AMOUNT_DUE}`),
    /* planUpgrade: (postData, userId) => dispatch => axiosApi(`${base_url}billing/plan/upgrade/${userId}`,"post",postData,dispatch,`${planSetting.PLAN_UPGRADE}`), */
    changeUserPlan: postData => dispatch => {dispatch({type: planSetting.UPDATE_USER_PLAN_DATA, payload: postData})},

    deleteAccount: postData => dispatch => axiosApi(`${base_url}billing/deleteAccount`, "post",postData,dispatch,`${planSetting.DELETE_ACCOUNT}`),
    resumeAccount: postData => dispatch => axiosApi(`${base_url}billing/resumeAccount`, "post",postData,dispatch,`${planSetting.RESUME_ACCOUNT}`),

    revokeAccount: postData => dispatch => axiosApi(`${base_url}billing/revokeAccount`, "post",postData,dispatch,`${planSetting.REVOKE_ACCOUNT}`),

    addUserAddress: (postData) => dispatch => axiosApi(`${base_url}addUserAddress`, "post", postData, dispatch, `${planSetting.ADD_SHIPPING_ADDRESS}`),

    getGstDetails: (postData) => dispatch => axiosApi(`${base_url}getGstDetails`, "post", postData, dispatch, `${planSetting.GET_GST_DETAILS}`),

    getTags: id => { 
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return Axios.get(`${base_url}tags/${id}`,{
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'id': userId,
              'authtoken': authToken,
              'billingtoken': bilingtoken,
            }
          }) 
    },

    createTags: (postData) => { 
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return Axios.post(`${base_url}tag`,postData,{
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'id': userId,
              'authtoken': authToken,
              'billingtoken': bilingtoken,
            }
          }) 
    },

    editTags: (id,postData) => { 
        const userData = JSON.parse(localStorage.getItem('userData')),
        authToken = userData == null ? '' : userData.authToken,
        userId = userData == null ? '' : userData._id,
        bilingtoken = userData == null ? '' : userData.billingToken;
        return Axios.post(`${base_url}tags/${id}`,postData,{
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'id': userId,
              'authtoken': authToken,
              'billingtoken': bilingtoken,
            }
          }) 
    },
    createdCallDisposition: (postData) => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
      return Axios.post(`${base_url}createCallDisposition`,postData,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }) 
  },
    getCallDisposition: (campaignId= "") => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
      const campaignIdQuery = campaignId ? "/?campaignId="+ campaignId :"";
      return Axios.get(`${base_url}getCallDisposition${campaignIdQuery}`,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
            'cookies': `ch3krt12=${userId}`
          }
        }) 
  },
  getHostedPageURL: (postData) => { 
    const userData = JSON.parse(localStorage.getItem('userData')),
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    return Axios.post(`${base_url}getHostedPageURL`,postData,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }) 
  },
  editCallDisposition: (id,postData) => { 
    const userData = JSON.parse(localStorage.getItem('userData')),
    authToken = userData == null ? '' : userData.authToken,
    userId = userData == null ? '' : userData._id,
    bilingtoken = userData == null ? '' : userData.billingToken;
    return Axios.post(`${base_url}editCallDisposition/${id}`,postData,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'id': userId,
          'authtoken': authToken,
          'billingtoken': bilingtoken,
        }
      }) 
  },
    // User status start 
    getUserStatus: id => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
      return Axios.get(`${base_url}userStatus/${id}`,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }) 
  },

  createUserStatus: (postData) => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
      return Axios.post(`${base_url}userStatusAdd`,postData,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }) 
  },

  editUserStatus: (id,postData) => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken;
      return Axios.post(`${base_url}userStatusedit/${id}`,postData,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
        }) 
  },

  // User status End

    getAuditLogs: (id,skip) => dispatch => axiosApi(`${base_url}billing/auditlogs/auditLogs/${id}?skip=${skip}`, "get", {}, dispatch, `${planSetting.AUDIT_LOGS}`),

    setReportStatus: postData => dispatch => axiosApi(`${base_url}reports`, "put", postData, dispatch, `${planSetting.REPORT_STATUS}`),

    setReports: postData => dispatch => axiosApi(`${base_url}reports`, "post", postData, dispatch, `${planSetting.SET_REPORTS}`),
    addWebhookUrl: postData => dispatch => axiosApi(`${base_url}webhookurl`, "post", postData, dispatch, `${planSetting.ADD_WEBHOOK_URL}`),
    getWebhookUrlList: (id,skip) => dispatch => axiosApi(`${base_url}webhookurl/${id}`, "get", {}, dispatch, `${planSetting.GET_WEBHOOK_LIST}`),
    removeWebhook: (id,skip) => dispatch => axiosApi(`${base_url}webhookurl/${id}`, "delete", {}, dispatch, `${planSetting.REMOVE_WEBHOOK_URL}`),
    updateWebhookUrl: postData => dispatch => axiosApi(`${base_url}webhookurl`, "put", postData, dispatch, `${planSetting.UPDATE_WEBHOOK_URL}`),
    callRecordingToggle: postData => dispatch => axiosApi(`${base_url}api/v1/callRecordingToggle`, "post", postData, dispatch, `${planSetting.CALL_RECORDING_TOGGLE}`),
    setRecordingPermission: (postData={}) => { 
      const userData = JSON.parse(localStorage.getItem('userData')),
      authToken = userData == null ? '' : userData.authToken,
      userId = userData == null ? '' : userData._id,
      bilingtoken = userData == null ? '' : userData.billingToken,
      parentId = userData!=null && userData.parentId?userData.parentId:userData._id;
      return Axios.post(`${base_url}api/v1/setRecordingPermission`,{parentId,...postData},{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'id': userId,
            'authtoken': authToken,
            'billingtoken': bilingtoken,
          }
      }) 
  },

    updateAddons: (userId,postData) => dispatch => axiosApi(`${base_url}addonsUpgrade/${userId}`, "post", postData, dispatch, `${planSetting.UPDATE_ADDONS}`),
    updateUserSeat: (userId,postData) => dispatch => axiosApi(`${base_url}updateUserSeat/${userId}`, "post", postData, dispatch, `${planSetting.UPDATE_USERSEAT}`),
    getEstimation: (postData) => dispatch => axiosApi(`${base_url}getEstimation`, "post", postData, dispatch, `${planSetting.GET_ESTIMATION}`),
    swapSubscriptionItemEstimate: (postData) => dispatch => axiosApi(`${base_url}swapSubscriptionItemEstimate`, "post", postData, dispatch, `${planSetting.GET_SWAP_SUBSCRIPTION_ESTIMATE}`),
    reactivateAccount: ( postData ) => dispatch => axiosApi(`${base_url}reactivateAccount`, "post", postData, dispatch, `${planSetting.REACTIVATE_ACCOUNT}`),
    addActivity : ( postData ) => dispatch => axiosApi(`${base_url}addActivity`, "post", postData, dispatch),
    downloadDNCNumberList: ( postData ) => dispatch => axiosApi(`${base_url}download/dncNumberList`, "post", {}, dispatch, `${planSetting.DOWNLOAD_DNC}`),
    applyCouponCode: postData => dispatch => axiosApi(`${base_url}applyCouponCode`, "post", postData, dispatch, `${planSetting.GET_PLAN}`),
    retriveHostedPageData: postData => dispatch => axiosApi(`${base_url}retriveHostedPageData`, "post", postData, dispatch, `${planSetting.RETRIVE_HOSTED_PAGE_DATA}`),

}
export default planSetting;