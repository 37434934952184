import axiosApi, {errorResponseStatus} from '../api';
import {ClearUserData} from "../api";
import Axios from 'axios';
export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST';
export const DWNLD_CAMPAIGN = 'DWNLD_CAMPAIGN';
export const DEL_CAMPAIGN = 'DEL_CAMPAIGN';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const GET_FREE_CAMPAIGN_MINUTES = 'GET_FREE_CAMPAIGN_MINUTES'

const base_url = process.env.REACT_APP_BASE_URL;

export const getCampaignList= (postData={}) => dispatch => {
  let pagination = postData.pagination?`&pagination=${postData.pagination}`:''
  let search = postData.search?`&search=${encodeURIComponent(postData.search)}`:'';
  let usersActive = postData.userActive?`&status=true`:'';
  let isDownload = postData.isDownload?`&isDownload=${postData.isDownload}`:'';
  let teamCreate = postData.teamCreate?`&teamCreate=${postData.teamCreate}`:'';
  let roleName = postData.roleName?`&roleName=${postData.roleName}`:'';
  let onlyNames= postData.onlyNames?`&onlyNames=${postData.onlyNames}`:'';

  //while filter
  let users=postData.filter && postData.filter.filterValue=='User' && postData.filter.userValue && postData.filter.userValue.length?`&users=${postData.filter.userValue.toString()}`:'';
  let teams=postData.filter && postData.filter.filterValue=='Team' && postData.filter.teamValue && postData.filter.teamValue.length?`&teams=${postData.filter.teamValue.toString()}`:'';
  let countries=postData.filter && postData.filter.countryList && postData.filter.countryList.length?`&countries=${postData.filter.countryList.toString()}`:'';
  let startDate=postData.filter && postData.filter.startDate?`&startDate=${postData.filter.startDate}`:'';
  let endDate=postData.filter && postData.filter.endDate?`&endDate=${postData.filter.endDate}`:'';
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userIdCookies = {  
    cookies:`ch3krt12=${userData._id}`
  }
  dispatch({ type: "CLEAR_CUSTOM_MESSAGE" });
  return axiosApi(`${base_url}campaigns/list?current=${postData.current?postData.current:1}${pagination}${search}${usersActive}${isDownload}${teamCreate}${roleName}${postData.filter?users+teams+countries+startDate+endDate:''}${onlyNames}`, "get", {}, dispatch, `${GET_CAMPAIGN_LIST}`, '', userIdCookies).then((result) => {
    return result;
  })
}

export const dwnld_Campaign = campaignId => dispatch => {
  let url = base_url + 'campaigns/download/' + campaignId;
  axiosApi(`${url}`, "post", {}, dispatch, `${DWNLD_CAMPAIGN}`).then(function(res){
    if (!res.payload.success) {  
      return dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: res.payload.error.error, type: "error" }
       });
     }
    
 });
};

export const delCampaign = campaignId => dispatch => {
  let url = base_url + 'campaigns/' + campaignId;
  let del = axiosApi(`${url}`, "delete", {}, dispatch, `${DEL_CAMPAIGN}`).then((res) => {
    // powerDialer();
    return dispatch({
      type: "SET_CUSTOM_MESSAGE",
      payload: { message: "Successfully deleted.", type: "success" }
    });
  });
};

export const addCampaign = postData => dispatch => {
  let user_id = JSON.parse(localStorage.getItem("userData"))._id;
  let url = base_url + 'campaigns/' + user_id;
  let method  = 'post';
  let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  let authToken = userData?userData.authToken:null;
  let billingtoken = userData?userData.billingToken:null;
  Axios({
    method:'post',
    url:url,
    data:postData,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      id: user_id,
      authtoken: authToken,
      billingtoken: billingtoken,
    },
  })
    .then((result) => {
      dispatch({
        type: "SET_CUSTOM_MESSAGE",
        payload: { message: "Campaign created successfully.", type: "success" }
      });
      return dispatch({
        type: `${ADD_CAMPAIGN}`,
        payload: result.data,
      });
    })
    .catch((error) => {
      if(error.response && errorResponseStatus.indexOf(error.response.status) >= 0) {
        return ClearUserData(error.response);
      }
      return dispatch({
        type: ADD_CAMPAIGN,
        payload: error.response ? error.response.data : ""
      });
    })
};

export const getFreeCampignMinutes = () => dispatch => axiosApi(`${base_url}campaign/getFreeCampignMinutes`, 'get', {}, dispatch, `${GET_FREE_CAMPAIGN_MINUTES}`)

