import React, { useEffect } from 'react'

const LadeskChatbot = () => {
    useEffect(() => {
        try {
            (function (d, src, c) { 
                var t = d.scripts[d.scripts.length - 1], 
                s = d.createElement('script'); 
                s.id = 'la_x2s6df8d'; s.defer = true; 
                s.src = src; 
                s.onload = s.onreadystatechange = function () { 
                    var rs = this.readyState; if (rs && (rs != 'complete') && (rs != 'loaded')) { return; } c(this); 
                }; 
                t.parentElement.insertBefore(s, t.nextSibling); 
            })(document,'https://callhippo.ladesk.com/scripts/track.js',function (e) { window.LiveAgent.createButton('r0fbh1m7', e);});
            
        } catch (error) {
            console.error("An error occurred while initializing the chat widget:", error);
        }
    }, [])

    return (
        <div></div>
    )
}

export default LadeskChatbot;