import React, { Component } from 'react';
import { Row, Col, Form, Button, Radio, message, Tooltip, Select, Tabs,Popover,Card,Icon,Input } from 'antd';
import numberAction from '../../redux/number/actions';
import planSetting from '@iso/redux/plan/actions';
import dashboardActions from '@iso/redux/dashboard/actions';
import authantication from "@iso/redux/authentication/action";
import { connect } from 'react-redux';
import lodash, { includes } from 'lodash';
import jstz from 'jstz';
import Modals from '@iso/components/Feedback/Modal';
import AddNumPopupStyles from './AddNumPopup.styles';
import AddNumPopupFreeTrial from './AddNumPopupFreeTrial.styles';
import WithDirection from '@iso/lib/helpers/rtl';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { setMessage } from "../../redux/customMessage/action";
import Currency from "../../constants";
import countries from "../PlanSetting/countries";
import users from '../../redux/users/actions';
import moment from 'moment';
import modalActions from '@iso/redux/modal/actions';
const isoModal = AddNumPopupStyles(Modals);
const Modal = WithDirection(isoModal);
const isoModalFreeTrial = AddNumPopupFreeTrial(Modals);
const ModalFreeTrial = WithDirection(isoModalFreeTrial);
const { changeUserSetting, updateSetupGuide } = dashboardActions;
const { changeUserData } = authantication;
const { NUMBER,INVITE_USER,DASHBOARD, USER_PROFILE_DOCUMENT, PLAN_BILLING, ADD_NUMBER ,NUMBER_BY_ID} = APP_PRIVATE_ROUTE;
const { Option } = Select;
const { TabPane } = Tabs;
const {getCustomerPortal} = users;
const { openModal } = modalActions;
const {
  chargebeeNewUser, getUserProfile,user, getNumbers ,createCbFtCustomerNSubcription
} = numberAction;

const {getPlan , planUpgrade, swapSubscriptionItemEstimate, applyCouponCode, getHostedPageURL} = planSetting;

class Addnopopup extends Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef()
    let initialState = this.getInitialState()
    initialState.showCouponInput = false;
    initialState.couponCode = '';
    initialState.isCouponApplied = false;
    initialState.isCouponRemoved = false;
    this.state = {...initialState}
  }

  getInitialState = () => {
    let userData = this.props.userData.data
    return {
      activeKey:"2",
      visible: false,
      showtext: false,
      planselectionshow: false,
      currencyList: [],
      currencyOFC: 'US/CA',
      cplanName:"professional",
      numberpurchaseshow: false,
      monthlyPrice: '',
      annuallyPrice: '',
      classname: "number-container num-annuactive",
      classnamewithactive: "number-container ",
      classnameforradio: "planprice bgwhite addnumboxborder",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      checkedactive: true,
      checked: false,
      radiovalue: '',
      radiovalueactive: 'a',
      checkrighttickfirst: true,
      checkrightticksecond: false,
      monthlyradiocheckvalue: 'monthly',
      quarterlyradiocheckvalue: '',
      annuallyradiocheckvalue: '',
      planSelectionValue: 'a',
      showplanmsg: false,
      user: [],
      plans: [],
      showMonthlyPlan: false,
      planPriceType: userData.currency ? 'userChargeCurrency': 'userCharge',
      freeNumberPerUser: '1',
      basicfreeNumberPerUser: '0',
      ydata: '',
      mdata: '',
      planId: '',
      checkshow: false,
      countryName: '',
      showcard: false,
      premium: false,
      numberPeriod: 2,
      payPeriod: 2,
      planmonth: 12,
      planPeriod: 2,
      cbInstance: window.Chargebee,
      userData: JSON.parse(localStorage.getItem('userData')),
      userProfile: {},
      disableMonthly:false,
      userMaxSeat: 1,
      freeTrailButtonClick:false,
      regularPlans:[],
      inviteUserStatus : false,
      currency : userData.selectedCurrency || userData.currency || "USD",
      currencySign: Currency[userData.selectedCurrency || userData.currency || "USD"].sign || "$",
      conversionRate:JSON.parse(process.env.REACT_APP_CONVERSION_RATE),
      ofcDisable:false,
      basicPlanId:'',
      ofcCountries:{'Global':'Global','US':'US/CA','CA':'US/CA','GB':'GB','AU':'AUS','IN':'INR'},
      notVisible: false,
      inventoryNumber: false,
      ftBtnDisable:false,
      buyBtnDisable:false,
      ftPlanChoose: "ultimateglobal",
    };
  }
  componentDidMount() {
    //this.props.user({ userId: this.props.userData.data._id, planId: this.props.userData.data.plan._id, authToken: this.props.userData.data.authToken, billingtoken: this.props.userData.data.billingToken });

    if(this.props.userData.data.conversionRate){
      this.setState({conversionRate:this.props.userData.data.conversionRate});
    }

    /* this.props.getPlan({ uid: this.props.userData.data._id }).then(({ payload }) => {
      if (payload.success) {
        this.setPlanToState(payload.data)
        this.setState(prevState => ({
          userData: {
            ...prevState.user, // Preserve existing properties
            accountStatus: payload.data.user.accountStatus || ''
          }
        }));
      } else {
        message.error(payload.error)
      }
    }); */

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.props.visible) {
      if(this.props.visible && !this.props.userData.data.stripeId){
        let userDataLocalStorage = JSON.parse(localStorage.getItem('userData'));
        if(userDataLocalStorage.chargebeeWebhookPending == true){
          message.destroy()
          message.info('We are currently updating your plan. Please wait for a moment.',5)
          this.props.history.push(PLAN_BILLING);
          return this.handleCancel()
        }
      }
      this.setState({
        visible: this.props.visible,
        disableMonthly:false,
        notVisible:false
      });
      // if(this.props.visible){
      //   if(this.props.userData.data.isFreeTrialUser){
      //     setTimeout(() => {
      //       message.info('Select a plan of your choice as per the required features and use them for 10 days for free!',7)            
      //     }, 1000);
      //   }
      //   this.setState({loadingUserData:true});
      // }
      if(this.props.newPlan){
        this.setState({
          showtext : true
        });
      }
      if(this.props.visible && this.props.userData && this.props.userData.data){
        //this.props.user({ userId: this.props.userData.data._id, planId: this.props.userData.data.plan._id, authToken: this.props.userData.data.authToken, billingtoken: this.props.userData.data.billingToken });
        let selectedPlan = this.props.user_data.data.data.allPlans.find(o => o.planName === 'professional') || []
        let basicPlan = this.props.user_data.data.data.allPlans.find(o => o.planName === 'basic') || []
        this.setState((prevState)=>({
          user: this.props.user_data.data.data.user,
          plans: this.props.user_data.data.data.allPlans,
          currencyList: this.props.user_data.data.data.allPlans.length > 0 ? this.props.user_data.data.data.allPlans[0].planCurrency: [],
          planId:  this.props.number  && this.props.number.isoCountry && prevState.ofcCountries[this.props.number.isoCountry] && this.props.user_data.data.data.allPlans.find(o => o.planDisplayName === 'professional' && o.freeMinutesCountry == prevState.ofcCountries[this.props.number.isoCountry]) ?   this.props.user_data.data.data.allPlans.find(o => o.planDisplayName === 'professional' && o.freeMinutesCountry == prevState.ofcCountries[this.props.number.isoCountry])._id : selectedPlan ? selectedPlan._id : '',
          basicPlanId: basicPlan ? basicPlan._id : '',
          basicfreeNumberPerUser : basicPlan ? basicPlan.freeNumberPerUser : '0',
          regularPlans:this.props.user_data.data.data.regularPlan,
          notVisible:false
        }));
      }
    }
    
    if (prevState.number !== this.props.number) {
      await this.setState({
        number: this.props.number,
        inventoryNumber: this.props.inventoryNumber,
        disableMonthly:false,
        currencyOFC: this.props.number && this.props.number.isoCountry && prevState.ofcCountries[this.props.number.isoCountry] ? this.state.ofcCountries[this.props.number.isoCountry]: 'US/CA'
      });
      this.setState({
        premium: this.props.premium,
        numberpurchaseshow: false,
        showtext: true,
        planselectionshow: true
      });
    }

    if (prevState.user_details !== this.props.user_details) {
      this.setState({
        userMaxSeat: this.props.user_details.maxUser || 1,
        user_details: this.props.user_details,
      });
    }

    if (prevState.addressVerifiedNoteHide !== this.props.addressVerifiedNoteHide) {
      this.setState({
        addressVerifiedNoteHide: this.props.addressVerifiedNoteHide,
      });
    }
    if (prevState.premium !== this.props.premium && this.props.visible) {
      this.setState({
        premium: this.props.premium,
        numberpurchaseshow: false,
        showtext: true,
        planselectionshow: true
      });
      if(this.props.newPlan){
        this.setState({
          showtext: true
         })
        }
    }
    if (prevProps.get_avail_number !== this.props.get_avail_number && this.props.get_avail_number.data.success) {
      this.setState({
        monthlyPrice: this.props.get_avail_number.data.data.numbers[0].monthlyPrice,
        annuallyPrice: this.props.get_avail_number.data.data.numbers[0].annuallyPrice,
        countryName: this.props.get_avail_number.data.data.country,
        phoneNumber: this.props.get_avail_number.data.data.phoneNumber,
        provider: this.props.get_avail_number.data.data.provider,
        callEnabled: this.props.get_avail_number.data.data.callEnabled,
        smsEnabled: this.props.get_avail_number.data.data.smsEnabled,
        type: this.props.get_avail_number.data.data.type,
        inventoryNumber:this.props.inventoryNumber,
        providerNumberCost: this.props.get_avail_number.monthly_rental_rate,
        providerNumberSetupCost: this.props.get_avail_number.setup_rate,
        setupCost: this.props.get_avail_number.data.data.setupCost
      })
    }
    if (prevProps.chargebee_new_user != this.props.chargebee_new_user) {
      if (this.props.chargebee_new_user.data.success) {
        window.mixpanel.track(1+" people bought number", { distinct_id: this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email });
        await this.setState({
          chargebee_new_user: this.props.chargebee_new_user.data
        })
        let stripeId = this.getQueryString('stripeId', this.props.chargebee_new_user.data.url);
        let subscriptionId = this.getQueryString('subscriptionId', this.props.chargebee_new_user.data.url);
        
        let setUserData = JSON.parse(localStorage.getItem('userData'));
        if(stripeId){
          this.props.changeUserData({data: { stripeId,subscriptionId,currency:setUserData.selectedCurrency? setUserData.selectedCurrency: this.state.currency }, "type":"stripeId"})
        }
        //this.props.getUserProfile();
      }else{
        if (!this.props.chargebee_new_user.data.success && !this.props.chargebee_new_user.planPurchased){
            this.props.history.push(DASHBOARD);
            message.error(this.props.chargebee_new_user.data?.error?.error || 'We could not reserve the number you have selected, please contact support@callhippo.com to get this number.') 
        }
      }
    }
    if (prevProps.user_data !== this.props.user_data) {
      if(this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.user && this.props.user_data.data.data.user.isDocVerificationRequired != "approved" && this.props.visible){
        this.setState({loadingUserData:false});
        this.setState({notVisible:true});
        this.props.changeStateFromChild({ visible: false});
        this.props.history.push({  pathname: USER_PROFILE_DOCUMENT, state: {status:428}}); 
      }else{
        this.setState({loadingUserData:false});
        let selectedPlan = this.props.user_data.data.data.allPlans.find(o => o.planName === 'professional') || []
        let basicPlan = this.props.user_data.data.data.allPlans.find(o => o.planName === 'basic') || []
        this.setState({
          user: this.props.user_data.data.data.user,
          plans: this.props.user_data.data.data.allPlans,
          currencyList: this.props.user_data.data.data.allPlans.length > 0 ? this.props.user_data.data.data.allPlans[0].planCurrency: [],
          planId:  this.props.number  && this.props.number.isoCountry && this.state.ofcCountries[this.props.number.isoCountry] && this.props.user_data.data.data.allPlans.find(o => o.planDisplayName === 'professional' && o.freeMinutesCountry == this.state.ofcCountries[this.props.number.isoCountry]) ?   this.props.user_data.data.data.allPlans.find(o => o.planDisplayName === 'professional' && o.freeMinutesCountry == this.state.ofcCountries[this.props.number.isoCountry])._id : selectedPlan ? selectedPlan._id : '',
          basicPlanId: basicPlan ? basicPlan._id : '',
          basicfreeNumberPerUser : basicPlan ? basicPlan.freeNumberPerUser : '0',
          regularPlans:this.props.user_data.data.data.regularPlan,
          notVisible:false
        });
        this.props.changeUserData({plans: this.props.user_data.data.data.user.plan,type:"stripeId",data:{stripeId:this.props.user_data.data.data.user.stripeId,subscriptionId:this.props.user_data.data.data.user.subscriptionId}, expiredFreeTrailDate: this.props.user_data && this.props.user_data.data && this.props.user_data.data.data && this.props.user_data.data.data.user && this.props.user_data.data.data.user.expiredFreeTrailDate? this.props.user_data.data.data.user.expiredFreeTrailDate: "" })
      }
    }

    if (prevProps.get_users !== this.props.get_users && this.state.chargebee_new_user) {
      if (this.props.get_users.success) {
        /* this.props.getPlan({ uid: this.state.user._id }).then(({ payload }) => {
          if (payload.success) {
            this.setPlanToState(payload.data)
            this.setState(prevState => ({
              userData: {
                ...prevState.user, // Preserve existing properties
                accountStatus: payload.data.user.accountStatus || ''
              }
            }));
          } else {
            message.error(payload.error)
          }
        }); */
        this.setState({
          userProfile: this.props.get_users.data
        });
        /* this.props.getNumbers().then((result) => {
          if(result && result.payload && result.payload.success){
            this.props.changeUserData({ data: {numberCount:result.payload.data.length,deletedNumberCount:result.payload.deletedNumberCount,numbersList:result.payload.data,allNumberCount:result.payload.allNumberCount}, "type":"addNumber" });
          }
        }); */
        let period = this.state.numberPeriod == 2 && this.state.payPeriod == 2 ? 'monthly' : 'annually';
          if (this.getQueryString('numberNotAdded', this.state.chargebee_new_user.url)) {
            let numberNotAvailable = this.getQueryString('numberNotAvailable', this.state.chargebee_new_user.url)
            this.props.changeStateFromChild({ loading: false, fshow: false, show: false, eshow: false});
            this.props.history.push(NUMBER);
            message.error(numberNotAvailable ? 'Oops! Just now someone has booked this number before you did. Please select a different number. You will not be charged again if already.' : 'We could not reserve the number you have selected , please contact support@callhippo.com to get this number.')
            this.setState({chargebee_new_user: {}});
          } else {
              this.props.changeStateFromChild({ loading: true, fshow: false, show: false, eshow: false });
          }
          //this.props.changeStateFromChild({ loading: true, showAssignNumber: true, fshow: false, show: false, eshow: false });
          if (this.state.userProfile.userNumberCount == 1) {
            if(this.state.userProfile && this.state.userProfile.expiredFreeTrailDate){
              let setUserData = JSON.parse(localStorage.getItem('userData'));   
              setUserData.expiredFreeTrailDate = this.state.userProfile.expiredFreeTrailDate;
              localStorage.setItem("userData", JSON.stringify(setUserData));
            }
              this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true });
          }
          if (this.state.userProfile.userAllNumberCount == 0 && this.state.userProfile.plan.planDisplayName == "free" && this.state.userProfile.paymentType == 'prepaid') {
            this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true });
          }
          if (this.getQueryString('firstNumberPurchase', this.state.chargebee_new_user.url) != "true") {
            // console.log('saveNumber CODE')
          }else {
            this.props.changeStateFromChild({ loading: false ,fshow: false, show: false, eshow: false});
            if (this.state.userProfile.userAllNumberCount == 0 && this.state.userProfile.plan.planDisplayName == "free" && !this.getQueryString('numberNotAdded', this.state.chargebee_new_user.url)) {
              this.props.changeStateFromChild({ loading: false, showAddCreditsModal: true, firstNumberId:this.getQueryString('numberId', this.state.chargebee_new_user.url)});
            } else {
              this.props.changeStateFromChild({firstNumberId:this.getQueryString('numberId', this.state.chargebee_new_user.url)});
            }
          }
          if(this.props.newPlan && this.state.inviteUserStatus && !this.state.newPlan){
            message.success('Plan upgrade successfully.')
            this.setState({newPlan:true});
            this.props.changeStateFromChild({upgradePlan:true});
            this.props.history.push({pathname: DASHBOARD , state:{newPlan:true}});
          }
          else if(!this.state.number && !this.state.number.phoneNumber && this.state.inviteUserStatus && !this.state.newPlan){
            message.success('Plan upgrade successfully.')
              this.setState({newPlan:true});
              this.props.changeStateFromChild({upgradePlan:true});
              this.props.history.push(INVITE_USER);
          }

      } else {
        message.destroy();
        message.error("182 SOMETHING WENT WRONG")
      }
    }
  }

  setPlanToState = planInfo => {
    let totalCredits = Math.abs(parseFloat(planInfo.availableCredits.availableCredits)).toFixed(2) <= 0.00 ? Math.abs(planInfo.availableCredits.availableCredits).toFixed(2) : parseFloat(planInfo.availableCredits.availableCredits - planInfo.availableCredits.usedCredits).toFixed(2);
    if (planInfo.user.plan.planDisplayName != "free" && !planInfo.user.stripeId) {
      planInfo.plans.splice(0, 1);
    }
    //this.props.changeUserData({totalCredits: Number(totalCredits)});
    this.props.changeUserData({totalCredits: Number(totalCredits),plans : planInfo.user.plan})
    this.props.changeUserSetting({ data: { totalCredits: totalCredits }, "type":"credit" });
  }
    getQueryString = (field, url) => {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
  };

  onMouseOver = e => {
    // console.log("onMouseOver")

  }
  onMouseDown = e => {
    // console.log("onMouseDown")

  }
  handleOk = e => {
    this.setState({
      numberpurchaseshow: false,
      planselectionshow: true,
      showtext: true,
      checkrighttickfirst: true
    });
  };


  handleCancel = e => {
    this.props.close();
    this.props.changeStateFromChild({
      visible: false,
      addressVerifiedNoteHide: false,
      number:'',
      closeClicked: true
    })
    let initialState = this.getInitialState()
    this.setState({...initialState})
    this.setState(prevState => ({
      userData: {
        ...prevState.user, // Preserve existing properties
        accountStatus: this.state?.userData?.accountStatus || ''
      },
      showCouponInput: false,
      couponCode: '',
      isCouponApplied: false
    }));
  };

  trackMixPanelEvent = ()=>{
    
    window.mixpanel.track("Click on the Buy Now", { distinct_id: this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email });

    if(this.props.get_number && !this.props.get_number.length){
      window.mixpanel.track("Click on buy now in plan popup",{distinct_id : this.props.userData.data.email,parent_id:this.props.userData.data && this.props.userData.data.parentEmail?this.props.userData.data.parentEmail:this.props.userData.data.email});
    } 
  }
  showItemName(item){
    const itemDesc = {
      'callscribe': 'Call Scribe', 
      'calltracking': 'Call Tracking', 
      'websitecallbutton': 'Website Call Button', 
      'cnam': 'CNAM', 
      'customcaller': 'Custom Caller Id', 
      'whatsapp_business': 'Whatsapp Business', 
      'adminuser_': 'Dashboard addon', 
      'a2p': 'US A2P messaging service', 
      'calltranscript': 'Voicemail Transcription', 
      'numbermasking': 'Hide number from Agent', 
    };
    const itemName = item.entity_type === 'plan_item_price'
    ? 'Users'
    : (item.entity_type === 'addon_item_price' &&
       item.entity_id.includes('number_'))
      ? 'Numbers'
      : Object.keys(itemDesc).find(key => item.entity_id.includes(key));

    return itemName ? itemName === 'Users' || itemName === 'Numbers' ? itemName : itemDesc[itemName] : item.entity_id;
  }
  estimatesBreakdown(estimateDetails) {
    return (
      <div>
        { estimateDetails && estimateDetails.invoice_estimate && (
          <>
            <table className="responsive-table fwidth planUpgradeDwnGradTable">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {estimateDetails.invoice_estimate.line_items.map((item, index) => (
                  item.unit_amount > 0 ? (
                    <tr key={index}>
                      <td>
                        {this.showItemName(item)}
                      </td>
                      <td>{this.state.currencySign}{item.unit_amount / 100}</td>
                      <td>{item.quantity}</td>
                      <td>{this.state.currencySign}{item.amount / 100}</td>
                    </tr>
                    ) : null
                ))}
                { estimateDetails.invoice_estimate.credits_applied > 0 &&
                  <tr>
                    <td>Credits Applied</td>
                    <td></td>
                    <td style={ { textAlign: 'end' } }><b>-</b></td>
                    <td>{this.state.currencySign}{ estimateDetails.invoice_estimate.credits_applied / 100   }</td>
                  </tr>
                }
                { estimateDetails.invoice_estimate.discounts && estimateDetails.invoice_estimate.discounts.map((item, index) => (
                  item.amount > 0 ? (
                    <tr key={index}>
                      <td>
                        Applied Discount
                      </td>
                      <td></td>
                      <td></td>
                      <td>- {this.state.currencySign}{item.amount / 100}</td>
                    </tr>
                    ) : null
                ))}
                <tr>
                  <td>Total Amount</td>
                  <td></td>
                  <td></td>
                  <td>{this.state.currencySign}{estimateDetails.invoice_estimate.total ? estimateDetails.invoice_estimate.total / 100 : 0}</td>
                </tr>
              </tbody>
            </table>
           {/* <p className='modalBottomText'>
             <strong>Note:</strong> &nbsp; You will be charged{' '}
             {this.state.currencySign}
             {estimateDetails.invoice_estimate.total / 100} as
             a full payment from next billing cycle (
             {moment
               .unix(estimateDetails.subscription_estimate.next_billing_at)
               .format('DD MMM, YYYY')}
             )
           </p> */}
          </>
        )}
      </div>
    );
  }

  handleCheckout = async (ftPlanChoose, basicPlan) => {
    this.setState({ ftBtnDisable: true, buyBtnDisable: true })
    if (this.state.userMaxSeat == "") {
      return this.props.setMessage({ message: "You have to choose at least one seat.", type: "error" });
    }
    this.props.close();
    this.trackMixPanelEvent()
    
    if (!this.state.freeTrailButtonClick) {
      let chargebeeAddressPopupRes = await new Promise((resolve) => {
        this.props.openModal({
          modalType: 'chargebeeAddressPopup',
          modalProps: {
            callBackSuccess: async (userAddress) => {
              resolve({success: true, userAddress});
            },
            callBackFail: async () => {
              resolve({success: false});
            },
          }
        });
      });
      if(chargebeeAddressPopupRes.success){
        const { changeUserData } = this.props;
        await changeUserData({ data: { userAddress: chargebeeAddressPopupRes.userAddress,gstinNumber: chargebeeAddressPopupRes?.userAddress?.gstinNumber}, type: "changeAuth" });
      }else{
        return this.handleCancel()
      }
    }

    const self = this;
    const { freeNumberPerUser, basicfreeNumberPerUser, plans, payPeriod, numberPeriod, planmonth, regularPlans, freeTrailButtonClick, basicPlanId } = this.state;
    let planId = this.state.planId
    if (ftPlanChoose) {
      let choosedPlan = plans.find(obj => obj.planName == ftPlanChoose)
      planId = choosedPlan?._id || this.state.planId;
    }
    const { changeUserData } = this.props;
    let planAmount = 0, query1, obj, addons = [], productName, plan;
    planId = basicPlan ? basicPlanId : planId;
    if (planId) {
      plan = lodash.filter(plans, { _id: planId })[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    if (this.state.user_details.isFreeTrialUser && !this.props.userData.data.expiredFreeTrailDate && !this.state.freeTrailButtonClick) {
      plan = lodash.filter(regularPlans, { planDisplayName: plan.planDisplayName })[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    if (this.state.user_details.isFreeTrialUser && !this.props.userData.data.expiredFreeTrailDate) {
      plan = lodash.filter(regularPlans, { planDisplayName: plan.planDisplayName, freeMinutesCountry: ftPlanChoose ? "Global" : this.state.currencyOFC })[0];
      planAmount = payPeriod == 1 ? (plan.userChargeYearly * 12) : plan.userCharge;
    }
    console.log("plan==========", plan, planId)
    let period, price;
    if (payPeriod == 1) {
      planAmount = 12 * plan.userChargeYearly;
      period = 'annually'
      // price = this.state.number.annuallyPrice
    } else if (payPeriod == 2) {
      planAmount = 1 * plan.userCharge;
      period = 'monthly'
      // price = this.state.number.monthlyPrice
    } else if (payPeriod == 3) {
      planAmount = 3 * plan.userChargeQuarterly;
      period = 'quarterly'
      // price = this.state.number.quarterlyPrice
    } else if (payPeriod == 4) {
      planAmount = 6 * plan.userChargeHalfYearly;
      period = 'halfYearly'
      // price = this.state.number.halfYealryPrice
    }

    price = this.state.number.monthlyPrice
    planAmount = planAmount ? planAmount : 0;
    planAmount = planAmount * this.state.userMaxSeat;
    //    let period = numberPeriod == 2 && payPeriod == 2 ? 'monthly' : 'annually';
    await this.calculateAmountNewFilter(planAmount, freeNumberPerUser, planmonth);
    if (this.state.number && this.state.number.phoneNumber) {
      query1 = 'contactName=' + this.props.contactName + '&countryName=' + this.props.get_avail_number.data.data.country + '&timezone=' + this.state.timezone + '&price=' + price + '&period=' + period + '&phoneNumber=' + this.state.number.phoneNumber + '&callEnabled=' + this.state.number.callEnabled + '&smsEnabled=' + this.state.number.smsEnabled + '&type=' + this.state.number.type + '&providerNumberCost=' + this.state.number.monthly_rental_rate + '&providerNumberSetupCost=' + this.state.number.setup_rate + '&setupCost=' + this.state.number.setupCost + '&couponCode=' + this.state.number.couponCode + '&addressRequired=' + this.state.number.addressRequired;
    } else {
      this.setState({
        inviteUserStatus: true
      });
      query1 = '&price=' + price + '&period=' + period + '&inviteUserStatus=' + true;
    }

    const baseObj = {
      userId: this.state.user._id,
      redirectSuccessUrl: `${window.location.origin}/addNumber?${query1}`,
      redirectFailedUrl: `${window.location.origin}/plan/upgradeFail`,
      cancelUrl: `${window.location.origin}/addNumber`,
      description: 'Upgrade Plan And Purchase number',
      paymentGateway: 'chargebee',
      billingToken: this.state.userData.billingToken,
      period: period,
      timezone: this.state.timezone,
      payPeriod1: period,
      payPeriod: planId ? this.state.payPeriod : this.state.numberPeriod,
      planId: plan ? plan._id : this.state.user.plan._id,
      planAmount: Number(planAmount),
      amount: Number(planAmount),
      seatBaseValue: Number(this.state.userMaxSeat),
      inventoryNumber: this.props.inventoryNumber,
      provider: this.state.number.provider,
    };
    
    if (this.state.inviteUserStatus || this.props.newPlan) {
      obj = {
        ...baseObj,
      };
    } else {
      obj = {
        ...baseObj,
        countryName: this.props.get_avail_number.data.data.country,
        type: this.state.number.type,
        numberPeriod: this.state.numberPeriod,
        contactName: this.props.contactName,
        phoneNumber: this.state.number.phoneNumber,
        price: Number(price),
        setupCost: this.state.number.setupCost,
        smsEnabled: this.state.number.smsEnabled,
        mmsEnabled: this.state.number.type !== 'tollfree' && this.state.number?.capabilities?.MMS ? this.state.number.capabilities.MMS : false,
        providerNumberCost: this.state.number.monthly_rental_rate,
        providerNumberSetupCost: this.state.number.setup_rate,
        premium: this.state.number.premium,
        restriction: this.state.number.restriction,
        sub_type: this.state.number.sub_type,
        region: this.state.number.region,
        addressRequirements: this.state.number.addressRequirements,
        addressSid: this.props.systemVerifiedDocs?.addressSid || '',
        bundleSid: this.props.systemVerifiedDocs?.bundleSid || '',
        ownDocument: this.props.ownDocument || false,
      };
    }

    if (this.state.number.city || this.state.number.locality) {
      obj.city = this.state.number.city ? this.state.number.city : this.state.number.locality;
    }
    obj.currency = this.state.currency;
    console.log("obj.payPeriod>>>>>", obj)
    if (obj.payPeriod == 1) {
      productName = this.state.freeTrailButtonClick ? plan.stripeIdAnnually_ft : plan.stripeIdAnnually;
    } else if (obj.payPeriod == 2) {
      productName = this.state.freeTrailButtonClick ? plan.stripeIdMonthly_ft : plan.stripeIdMonthly;
    } else if (obj.payPeriod == 3) {
      productName = this.state.freeTrailButtonClick ? plan.stripeIdQuarterly_ft : plan.stripeIdQuarterly;
    } else if (obj.payPeriod == 4) {
      productName = this.state.freeTrailButtonClick ? plan.stripeIdHalfYearly_ft : plan.stripeIdHalfYearly;
    }
    let currencyText = '_' + this.state.currency.toLowerCase()
    console.log("obj.premium>>>", obj.premium)
    if (this.state.currency != "USD") {
      productName = productName + currencyText
    }
    if (obj.premium || (productName.includes("basic") && basicfreeNumberPerUser == 0)) {
      addons.push({ id: this.state.currency != "USD" ? "number_" + obj.price + currencyText : "number_" + obj.price, quantity: 1 });
    }
    if (this.state.number.setupCost) {
      let setupCost = this.state.number.setupCost
      addons.push({ id: this.state.currency != "USD" ? "setupcost_" + setupCost + currencyText : "setupcost_" + setupCost, quantity: 1 });
    }
    console.log("productName>>>>>", productName)

    this.setState({
      numberPeriod: 2,
      payPeriod: 2,
      planPeriod: 2
    })
   
   if (this.state.freeTrailButtonClick) {
      var product = {planId: productName, planQuantity: this.state.userMaxSeat, addons};
      var customer = {
        email: this.state.user.email,
        "cf_billing_india": "General",
        "cf_mail_send": "YES",
        phone: this.state.user.phoneNumber ? this.state.user.phoneNumber : this.state.user.mobileNo && this.state.user.mobileNo.length ? this.state.user.mobileNo[0] : '',
        userId: this.state.user && this.state.user.parentId ? this.state.user.parentId : this.state.user._id
      };
      if (this.state.user.firstName && this.state.user.lastName) {
        customer.first_name = this.state.user.firstName
        customer.last_name = this.state.user.lastName
      }
      if (this.state.user.companyName) {
        customer.company = this.state.user.companyName
      }
      const resultData = await this.props.createCbFtCustomerNSubcription({ customer, product, obj })
      this.setState({buyBtnDisable:false});
      if (resultData.payload && resultData.payload.success) {
        // need to update free trial button states false to enable button 
        this.setState({ freeTrailButtonClick: false })
        if (resultData.payload.success && resultData.payload.data.expiredFreeTrailDate && resultData.payload.data.freeTrialDate) {
          changeUserData({ data: { expiredFreeTrailDate: resultData.payload.data.expiredFreeTrailDate, freeTrialDate: resultData.payload.data.freeTrialDate , plan :resultData.payload.data.plan_data }, "type": "changeAuth" })
        }
        if (self.state.number) {
          changeUserData({ data: { planPeriod: obj.period, isGettingStartedProcessStep: "two" }, "type": "changeAuth" })
          self.props.updateSetupGuide({ isGettingStartedProcessStep: "two" })
        }
        if (self.props.get_number && !self.props.get_number.length) {
          window.mixpanel.track("1st number purchased successfully.", { distinct_id: self.props.userData.data.email, parent_id: this.props.userData.data && this.props.userData.data.parentEmail ? this.props.userData.data.parentEmail : this.props.userData.data.email });
        }
        self.props.getUserProfile();
        const backToDashboard = this.getQueryString('backToDashboard');
        if(backToDashboard){
          console.log("Back to dashboard")
        }else if(resultData.payload.url && this.getQueryString('numberId', resultData.payload.url)){
          this.props.history.push(NUMBER_BY_ID+'/'+this.getQueryString('numberId', resultData.payload.url));
        }
      } else {
        if (resultData.payload && resultData.payload.planPurchased && resultData.payload.data) {
          this.props.setMessage({ message: resultData.payload.data, type: "success" });
          window.location.reload();
        }
      }
    } else {
      let hostedPageRequest = {
        chargebeePlanId: productName,
        chargebeeAddons: addons,
        chargebeeQuantity: Number(this.state.userMaxSeat),
        selectedCurrency: this.props.userData.data?.selectedCurrency,
        planId: obj.planId
      }
      getHostedPageURL(hostedPageRequest).then((payload) => {
        console.log(`🚀 ~ Addnopopup ~ this.props.getHostedPageURL ~ payload:`, payload)   
        if(payload.data.success && payload.data.data?.hostedPageURL){
          window.location.href = payload.data.data.hostedPageURL
        }
      }).catch((err)=>{
        message.error(err.response?.data?.error || "Something went wrong")
        this.setState({buyBtnDisable:false})
      })
    }


    // window.mixpanel.track("Click on proceed to checkout in chargebee popup",{distinct_id : this.props.userData.data.email});
    window.mixpanel.identify(this.props.userData.data.email);

    let objData = {
      "$name": this.props.userData.data.fullName,
      "$email": this.props.userData.data.email,
      "selected plan name": plan.planDisplayName,
      "plan period": obj.payPeriod1,
      "currency": obj.currency,
      "parent_id": this.props.userData.data.parentEmail ? this.props.userData.data.parentEmail : this.props.userData.data.email

    }
    window.mixpanel.people.set(objData);
  }

  chargebeeInit = (obj) => {
    this.props.getCustomerPortal({id:this.props.userData.data._id}).then(({ payload }) => {
      if (payload.success) {
        let portalData = payload.portalData;
        const cbInstance = window.Chargebee.init({
          site: this.props.userData.data.chargebeeSite
        })
        var chargebeePortalInstance = cbInstance.createChargebeePortal();
        cbInstance.setPortalSession(function () {
          return new Promise(function (resolve, reject) {
            var sampleResponse = {
              "id": portalData.id,
              "token": portalData.token,
              "access_url": portalData.access_url,
              "status": portalData.status,
              "created_at": portalData.created_at,
              "expires_at": portalData.expires_at,
              "object": portalData.object,
              "customer_id": portalData.customer_id
            };
            resolve(sampleResponse);
          });
        });
        chargebeePortalInstance.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
          },
          {
            close: () => {
              cbInstance.logout();
              cbInstance.closeAll();
            },
            paymentSourceAdd: () => {
              cbInstance.logout();
              cbInstance.closeAll();
              this.onFtplanUpgradeVia(obj)
              message.destroy()
            },
            paymentSourceUpdate: () => {
                cbInstance.logout();
                cbInstance.closeAll();
                this.onFtplanUpgradeVia(obj) 
            },
          }
        );
        
        
      } else {
        message.destroy();
        message.error("Please Contact admin for update payment details")
      }
    });
  };

  onFtplanUpgradeVia = (data) => {
    let obj = {
      planPeriod: data.payPeriod == 2 ? 4 : data.payPeriod == 4 ? 2 : data.payPeriod,
      userId: this.props.userData.data._id,
      planId: data.planId,
      newUser: true,
      coupon: this.state.isCouponApplied && this.state.couponCode && !this.state.isCouponRemoved ? this.state.couponCode : '',
      changePlanAsPer: 'new',
      prorateOnPlan : true,
      planUpdate : false,
      updatedUserSeat: data.seatBaseValue,
      trialEnd: true,
      planChangeOnFt: true,
      currency: data.currency,
    }

    this.props.planUpgrade(obj, this.props.userData.data._id).then(({ payload }) => {
      if (payload.success) {
        this.props.changeUserData({data: { creditCard: true, isFreeTrialUser: false, expiredFreeTrailDate: "", freeTrialUserBlock: false, isCancelled: false }, "type":"changeAuth"})
        message.success('Plan upgrade successfully.');
        if (this.props.hideSkipButton) {
          setTimeout(() => {
            window.location.reload();            
          }, 5000);
        }
        this.props.getPlan({ uid: this.props.userData.data._id }).then(({ payload }) => {
          if (payload.success) {
            this.setState({ loading: false});
            this.props.changeUserData({data: { plans: payload.data.user.plan, currency:payload.data.user.currency,planPeriod:payload.data.user.planPeriod,stripeId:payload.data.user.stripeId, subscriptionId:payload.data.user.subscriptionId}, "type":"changeAuth"})
            this.setState(prevState => ({
              userData: {
                ...prevState.user, // Preserve existing properties
                accountStatus: payload.data.user.accountStatus || ''
              }
            }));
          }
        });
      } else {
        if (payload && payload.error && payload.error.error) {
          const resErrorMsg = err => message.error(err && err.error ? err.error : "Something went wrong");
          if (payload.error.NumberAdd) {
            this.props.history.push(ADD_NUMBER)
            resErrorMsg(payload.error)
          } else if (typeof payload.error == "string") {
            message.error(payload.error)
          } else if (typeof payload.error == "object") {
            message.error(payload.error.error)
          } else { resErrorMsg() }
        } else if (payload.error.message) {
          message.error(payload.error.message.split("Error message")[0])
        }
      }
    })
  }

  calculateAmountNewFilter = async (planAmount, freeNumberPerUser, planmonth) => {
    var tz = jstz.determine();
    await this.setState({
      timezone: tz.name(),
      number: {
        ...this.state.number,
        price: planAmount
      }
    });
  }

  // when selecting annually on number period selection
/*   onClickonAnnually = () => {
    this.setState({
      classnamewithactive: "number-container ",
      classname: "number-container num-annuactive",
      classnameforradio: "planprice bgwhite addnumboxborder",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      checkedactive: true,
      checked: false,
      radiovalue: '',
      radiovalueactive: 'a',
      numberPeriod: 1,
      planmonth: 12,
      disableMonthly:true
    })
  } */

  // when selecting monthly on number period selection
/*   onClickonMonthly = () => {
    this.setState({
      classnamewithactive: "number-container num-annuactive",
      classname: "number-container",
      classnameforradio: "planprice bgwhite addnumboxborder num-annu-priceactive rdiochrct",
      classnameforradiowithactive: "planprice bgwhite addnumboxborder",
      checkedactive: false,
      checked: true,
      radiovalue: 'a',
      radiovalueactive: '',
      numberPeriod: 2,
      planmonth: 1,
      disableMonthly:false
    })
  } */

  // when selecting annually on plan selection
  onClickOnAnnuallyRadio = e => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: 'annually',
        planPriceType: this.props.userData.data.currency ? 'userChargeYearlyCurrency': 'userChargeYearly',
        planSelectionValue: '',
        showMonthlyPlan: false,
        payPeriod: 1,
        numberPeriod: 1,
        planPeriod: 1,
        planmonth: 12
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: 'annually',
        planSelectionValue: '',
        planPriceType: this.props.userData.data.currency ? 'userChargeYearlyCurrency': 'userChargeYearly',
        showMonthlyPlan: false,
        payPeriod: 1,
        planPeriod: 1,
        planmonth: 12
      })
    }
  }

  onRadioNumberPlanSelection = (type,planPriceType,payPeriod) => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: type,
        planPriceType: planPriceType,
        showMonthlyPlan: false,
        payPeriod: payPeriod,
        numberPeriod: 1,
        planPeriod: 6,
        planmonth: 3
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: type,
        planPriceType: planPriceType,
        showMonthlyPlan: false,
        payPeriod: payPeriod,
        planPeriod: 6,
        planmonth: 3
      })
    }
  }

  // when selecting monthly on plan selection
  onClickOnMonthlyRadio = e => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: 'monthly',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        showMonthlyPlan: true,
        planPriceType: this.props.userData.data.currency ? 'userChargeCurrency': 'userCharge',
        payPeriod: 2,
        numberPeriod: 2,
        planPeriod: 2,
        planmonth: 1
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: 'monthly',
        quarterlyradiocheckvalue: '',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        planPriceType: this.props.userData.data.currency ? 'userChargeCurrency': 'userCharge',
        showMonthlyPlan: true,
        payPeriod: 2,
        planPeriod: 2,
        planmonth: 1
      })
    }
  }

  onClickOnQuarterlyRadio = e => {
    if (!this.state.premium) {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: 'quarterly',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        showMonthlyPlan: true,
        planPriceType: this.props.userData.data.currency ? 'userChargeQuarterlyCurrency': 'userChargeQuarterly',
        payPeriod: 3,
        numberPeriod: 2,
        planPeriod: 2,
        planmonth: 3
      })
    } else {
      this.setState({
        monthlyradiocheckvalue: '',
        quarterlyradiocheckvalue: 'quarterly',
        annuallyradiocheckvalue: '',
        planSelectionValue: '',
        planPriceType: this.props.userData.data.currency ? 'userChargeQuarterlyCurrency': 'userChargeQuarterly',
        showMonthlyPlan: true,
        payPeriod: 3,
        planPeriod: 2,
        planmonth: 3
      })
    }
  }
  minSeatChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const re = /^[0-9\b]+$/;
    if (value == "") {
      this.setState({userMaxSeat: ""})
    } else if (re.test(value) && value > 10) {
      return this.props.setMessage({ message: "You Can not choose more than 10 seat.", type:"error" });
    }else if (re.test(value) && value < 1) {
      return this.props.setMessage({ message: "You have to choose at least one seat.", type:"error" });
    }else if (re.test(value)){
      this.setState({userMaxSeat: value})
    }else{
      this.setState({userMaxSeat: this.state.userMaxSeat})
    }
    /* if(value >=2){
      this.setState({disableMonthly: false})
    }else{
      let toggleData = {disableMonthly: true}
      if(this.state?.monthlyradiocheckvalue == 'monthly'){
        this.onClickOnQuarterlyRadio()
      }
      this.setState(toggleData)
    } */
  }

  currencyDropdownListdefault = (val,selectedData) => {
    this.setState({
      currencyOFC:val,
      planId:selectedData.key,
      ofcDisable: this.state.number && val != "Global" ? (val == "IN" || val == this.state.ofcCountries[this.state.number.isoCountry]) ? false : true : false
    })
  }
  onSelectCurrency = (value) => {
    this.setState({
      currency:value,
      currencySign:Currency[value].sign
    })
  
    this.props.changeUserData({data: { selectedCurrency: value }, "type":"changeAuth"})
  }
  minSeatKeyDown = e => { if([69,107,109,187,110,189,190].includes(e.keyCode)){ e.preventDefault() } }
  
  onClickOnSelectPlan = plan => {
    this.setState({
      freeNumberPerUser: plan.freeNumberPerUser,
      ydata: plan.userChargeYearly,
      planId: plan._id,
      mdata: plan.userCharge,
      //disableMonthly:plan.planDisplayName == "platinum"
    })
  }
  openCouponCode = () => {
    this.setState({ showCouponInput: true });
  }
  
  applyCouponCode = () => {
    this.props.applyCouponCode({ coupon: this.state.couponCode }).then(({ payload }) => {
      if (payload.success) {
        this.setState({ isCouponApplied: true });
        message.success('Yay! The discount applied successfully.')
      } else {
        this.setState({ isCouponApplied: false });
        message.error('Invalid Coupon Code.')
      }
    });
  }
  onClickOfficeOnSelectPlan = plan => {
    this.setState({
      freeNumberPerUser: plan.freeNumberPerUser,
      ydata: plan.userChargeYearly,
      planId: this.state.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName)) ? this.state.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName))._id : this.state.plans.find(o => (o.freeMinutesCountry === 'US/CA') && (plan.planDisplayName == o.planDisplayName))._id,
      mdata: plan.userCharge,
      //disableMonthly:plan.planDisplayName == "platinum",
      cplanName:plan.planDisplayName,
      currencyOFC:this.state.plans.find(o => (o.freeMinutesCountry === this.state.currencyOFC) && (plan.planDisplayName == o.planDisplayName)) ? this.state.currencyOFC : 'US/CA'
    })
  }

  callback = async (key) => {
    let planNameSelect = key == '2' ? "professional" : "silver12"
    let tabChangeObj
    if(true/* key === '2' */){
      tabChangeObj = {
        planId:  this.state.number  && this.state.number.isoCountry && this.state.ofcCountries[this.state.number.isoCountry] && this.state.plans.find(o => [planNameSelect].includes(o.planDisplayName) && o.freeMinutesCountry == this.state.ofcCountries[this.state.number.isoCountry]) ?   this.state.plans.find(o => [planNameSelect].includes(o.planDisplayName) && o.freeMinutesCountry == this.state.ofcCountries[this.state.number.isoCountry])._id : this.state.plans.find(o => [planNameSelect].includes(o.planName))._id,
        cplanName: key === '2' ? "professional" : "silver",
        currencyOFC: this.state.number && this.state.number.isoCountry && this.state.ofcCountries[this.state.number.isoCountry] ? this.state.ofcCountries[this.state.number.isoCountry]: 'US/CA',
        ofcDisable: this.state.number && this.state.currencyOFC != "Global" ? (this.state.number.isoCountry == "IN" || this.state.ofcCountries[this.state.number.isoCountry]) ? false : true : false
      }
    }else
    {
      tabChangeObj = {
        planId: this.state.plans.find(o => o.planDisplayName === 'silver')._id,
        ofcDisable:false
      }
    }
    /* console.log("tabChangeObj.currency================",tabChangeObj.currency,this.state.currency)
    if(tabChangeObj.currency != this.state.currency){
      this.props.changeUserData({data: { selectedCurrency: tabChangeObj.currency }, "type":"changeAuth"})
    } */

    this.setState({
      ...tabChangeObj,
      activeKey:key
    })
  }

  render() {
    const { annuallyPrice, monthlyPrice,planSelectionValue, monthlyradiocheckvalue, quarterlyradiocheckvalue, annuallyradiocheckvalue, ydata, mdata, freeNumberPerUser, planId, user_details, currencySign,currency, plans,ofcDisable ,ofcCountries,ftBtnDisable,buyBtnDisable, number} = this.state;
    let planCallCenter = plans.length ? plans.filter(planObj => !planObj.isOfficePhoneSystem && planObj.freeMinutesCountry === this.state.currencyOFC && planObj.planDisplayName != "basic") : [];
    let planOfficePhone = plans.length ? plans.filter(planObj => planObj.isOfficePhoneSystem && planObj.freeMinutesCountry === this.state.currencyOFC) : [];
    return (
      !number?.premium && this.props.userData.data?.isFreeTrialUser && !this.props.userData.data?.expiredFreeTrailDate ?
      <ModalFreeTrial
        className="addNumberPopupNew"        
        width={414}
        centered
        title="Start Your 10 Days Free Trial"
        //visible={true}
        visible={this.state.visible && !this.state.notVisible && !this.state.loadingUserData}
        onCancel={()=>{this.handleCancel()}}
        footer={[
          <div className='addnumpopupFooter'>
            <Button disabled= {this.state.freeTrailButtonClick ? true : false} className={`start_free_trial`} onClick={() => this.setState({freeTrailButtonClick: true, userMaxSeat: 1}, () => this.handleCheckout(this.state.ftPlanChoose))}>Start Free Trial</Button>
            <p>Need help in selecting the right plan? <a className='setupcallLink' href='https://callhippo.com/schedule-a-demo/' target="_blank">Setup Call</a> with our product expert.</p>
          </div>
        ]}
        type="primary"
      >
        <Radio.Group onChange={(e) => this.setState({ftPlanChoose: e.target.value})} value={this.state.ftPlanChoose}>
          <Radio.Button value="ultimateglobal" className='officePhoneSysCard'>
              <div className='freeTrialUserCard'>
                <div className='officePhoneBtn'>Office Phone System</div>
                <div className='planSelectCardBodyInner'>
                  <div className='planSelectCardBodyInnerTxt'>
                    <p>Ideal for Businesses to support their Customers and not for Bulk Calling.</p>
                    <ul>
                      <li>Country Wise Minutes Bundle</li>
                      <li>Country Wise Unlimited Calls</li>
                      <li>Manual Calling Dialer</li>
                      <li>Auto Rotate DID</li>
                    </ul>
                  </div>
                  {/* <div className='planSelectBottom'>
                    <div className='selectPlanBtn'>
                      <span className='textWithIcon'>
                        <Icon type="check-circle" theme="filled" /> Selected
                      </span>
                      <span className='textOnly'>Select Plan</span>
                    </div>
                  </div> */}
                </div>
              </div>
          </Radio.Button>
          {/* <Radio.Button value="platinum12" bordered={false} className='callCentreSolution' style={{width: "50%"}}>
            <div className='freeTrialUserCard' style={{ height:270 }}>
                <div className='callCentreSolutionBtn'>Call Center Solution</div>
                <div className='planSelectCardBodyInner'>
                  <div className='planSelectCardBodyInnerTxt'>
                    <p>Ideal for Bulk Calling for Sales, Support and Call Center Teams.</p>
                    <ul>
                      <li>Industry leading call connect rates</li>
                      <li>Power & Predictive Dialer</li>
                      <li>Automatic Machine Detection</li>
                      <li>Compliance Call Recording</li>
                    </ul>
                  </div>
                  <div className='planSelectBottom'>
                    <div className='selectPlanBtn'>
                      <span className='textWithIcon'>
                        <Icon type="check-circle" theme="filled" /> Selected
                      </span>
                      <span className='textOnly'>Select Plan</span>
                  </div>
                  </div>
                </div>
              </div>
          </Radio.Button> */}
        </Radio.Group>
      </ModalFreeTrial>
      :
      <Modal
        className="addnumpopup"        
        width="950px"
        centered
        // visible={true}
        visible={this.state.visible && !this.state.notVisible && !this.state.loadingUserData}
        onCancel={()=>{this.handleCancel()}}
        footer={null}
      >

        {/* <div className="fwidth">
          <div className="stepwizard">
            <div className="stepwizard-row setup-panel stpcheck">
              <div className="stepwizard-row-progress"></div>
              <div className="stepwizard-step">
                <div className="stepwizard-btn-circle-one"><span><i className="material-icons step-check-icon">check</i></span></div>
              </div>
              <div className="stepwizard-step">
                <div className="stepwizard-btn-circle-two"><span>{this.state.checkrighttickfirst ? <i className="material-icons step-check-icon">check</i> : 2}</span></div>
              </div>
              <div className="stepwizard-step stepwizard-step-three">
                <div className="stepwizard-btn-circle-three"><span>{this.state.checkrightticksecond ? <i className="material-icons step-check-icon">check</i> : 3}</span></div>
              </div>
            </div>
          </div> */}
          <div className="fwidth">

            {/* START: popup title design */}
            {/* <div className="text-center">
              {this.state.numberpurchaseshow && <h3 className="num-plan-title numplan_title_offerchange fwidth" style={{ display: 'block' }}>Almost there! Choose your number plan</h3>}
              {this.state.showtext && <h3 className="num-plan-title priceplanttitle fwidth">Select your plan and you are done!<i className="material-icons">mood</i></h3>}
            </div> */}
            {/* END: popup title design */}
     
                {/* START: Tab Structure */}
                {this.state.showtext && !this.state.number.premium && this.props.userData.data && this.props.userData.data.isFreeTrialUser && !this.props.userData.data.expiredFreeTrailDate && <h3 className="num-plan-title priceplanttitle fwidth tab-text-title">Start Your 10 Days Free Trial</h3>}
                <Tabs activeKey={this.state.activeKey} className="fwidth text-center" defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Office Phone System" key="2">
                <div className="text-center">
                  {/* {this.state.showtext && <h3 className="num-plan-title priceplanttitle fwidth tab-text-title">Idle for business to support their customers</h3>} */}
                 </div>
                  {this.state.planselectionshow &&
                        <form name="addNumberForm">
                            <Row id="currencydropdown" type="flex" className="planSelect__duration fwidth flexcolomch chflexcenter mgbtm20">
                                <Col className='padzero cnflex reserveUnlimitedCol' 
                                span={this.state?.userData?.accountStatus == 'cancel' && !this.props.userData.data.isFreeTrialUser && !this.props.userData.data.freeTrialUserBlock ? 20 : 14}
                                >
                                    <Form.Item className='chjustifycenter'>
                                      <label className='mgright5 fbold chflex chaligncenter'>Unlimited Calling <Popover
                                      content={'Select country for which you want to get unlimited minutes.'} 
                                      placement="top"><i className="material-icons infopopopen cursorp">info</i>
                                      </Popover>:
                                      </label>
                                      <Select 
                                      defaultValue={this.state.currencyOFC} 
                                      style={{ width: 130 }} 
                                      className="currencydropdownbtn"
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                      onChange={this.currencyDropdownListdefault} 
                                      value={this.state.currencyOFC}
                                      >
                                        {plans.map((plan) => {
                                          if(this.state.cplanName == plan.planDisplayName){
                                            return <Option value={plan.freeMinutesCountry} key = {plan._id} label={plan.freeMinutesCountry}>{plan.freeMinutesCountry == "GB" ? "UK" : plan.freeMinutesCountry}</Option>
                                          }
                                          return null
                                        })}
                                      </Select>
                                    </Form.Item>
                                    {this.state?.userData?.accountStatus == 'cancel' && !this.props.userData.data.isFreeTrialUser && !this.props.userData.data.freeTrialUserBlock &&
                                      <div className="chflex chaligncenter reserveSeatDropDown" id="seatdropdown">
                                        <label className="seatpurchasedlabel fbold chflex chaligncenter">Reserve Seats <Tooltip overlayClassName="number_plan_tooltipWrap" title="The charges will be calculated based on the total seats."><i className="material-icons seatpurchasedtooltip">info</i></Tooltip> : </label>
                                        <input type="number" value= {this.state.userMaxSeat} min={0} max={11} onChange={this.minSeatChange} onKeyDown={this.minSeatKeyDown} className="totalseatinput" />
                                      </div>
                                    }
                                    {this.state.currencyList.length > 0 && 
                                      <div className="chflex chaligncenter reserveSeatDropDown" id="seatdropdown">
                                        <label className='seatpurchasedlabel fbold chflex chaligncenter'>Currency:</label>
                                        <Select 
                                        defaultValue={this.state.currency} 
                                        style={{ width: 100 }} 
                                        className="currencydropdownbtn"
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        onChange={this.onSelectCurrency} value={this.state.currency}>{this.state.currencyList.map((planC) => {
                                          return (<Option value={planC}>{planC}</Option>)
                                        })}
                                        </Select>
                                      </div>
                                      }
                                </Col>
                                <Col className='padzero' span={24}>
                                  <div className="switchwrapper chjstcenter">
                                    <Form.Item label="">
                                      <Radio.Group value={monthlyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnMonthlyRadio} disabled={this.state.disableMonthly} value="monthly">Monthly</Radio>
                                        {/* this.state.userMaxSeat <= 1 && <small className="minimumUserText">Minimum 2 Users</small> */}
                                      </Radio.Group>
                                    </Form.Item>                        
                                    <Form.Item label="">
                                        <Radio.Group value={quarterlyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnQuarterlyRadio} value="quarterly">Quarterly</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    <Form.Item label="">
                                      <Radio.Group value={annuallyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnAnnuallyRadio} value="annually">Annually</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    <div className="number_purchse_an_offer_secpage monthcosttab mothcost_dropdown">
                                      <span className="num_purchse_span_sec currecydrop_monthcost">Save 3 Months Cost</span>
                                    </div>
                                  </div>
                                </Col>
                              {/* <Col  span={24} className="gutter-row w-100 unlimited_calling_div">
                                <Col className='padzero' span={12}>
                                </Col>
                                <Col className='padzero' span={12}>
                                {this.state.currencyList.length > 0 && <Form.Item  className="">
                                    <label className='mgright5 fbold'>Currency:
                                    </label>
                                    <Select defaultValue={this.state.currency} style={{ width: 100 }} className="currencydropdownbtn"
                                     onChange={this.onSelectCurrency} value={this.state.currency}

                                    >{this.state.currencyList.map((planC) => {
                                        return (        <Option value={planC}>{planC}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>}
                                </Col>
                              </Col> */}
                            </Row>
                            {/* END: selection of plan period(monthly/annually) */}
                            <Row type="flex" justify="center" className="plancontainer">
                            {planOfficePhone.map((plan) => 
                                <Col className="planpadding cnflex" md={{ span: 8 }} lg={{ span: 8 }}>
                                  <div className="planslbox platinumplan">
                                    <div className="number-container brdorange">
                                      <div className={plan.planDisplayName === "professional" ? 'plandigit num-annu num_background plan_title_silver' : 'plandigit num-annu num_background'}>{plan.planDisplayName}</div>
                                        <div className="planstat ng-binding">
                                        <Tooltip overlayClassName="number_plan_tooltipWrap" title={plan.planDescription}><i className="material-icons numberPlanTooltip">info</i></Tooltip>
                                          <span className="plan_price_position"> {plan[this.state.planPriceType][currency]}
                                            <span className="plan_currency_AN">{currencySign}</span>
                                          </span>
                                        </div>
                                        <span className="plan_type_label">USER/MONTH</span>
                                      <div className="planprice bgwhite addnumboxborder">
                                        {plan.planDisplayName === this.state.cplanName ? 
                                          <Button className="slrctplan plan_selected" onClick={() => this.onClickOfficeOnSelectPlan(plan)}> 
                                            <i className="material-icons plan_check_icon" >check_circle</i> Selected
                                          </Button> 
                                           :
                                          <Button className="slrctplan" onClick={() => this.onClickOfficeOnSelectPlan(plan)}>Select Plan</Button>
                                        }
                                      </div>
                                      <div className='planprice bgwhite addnumboxborder'>
                                      {plan.planDisplayName === "starter" &&
                                      <>
                                      <div className='scheme-title-box emptybox_feature'></div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul> 
                                      </>
                                      }
                                      {plan.planDisplayName === "professional" &&
                                      <>
                                      <div className='scheme-title-box'>Everything in Starter +</div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                       {plan.planDisplayName === "ultimate" &&  
                                      <>
                                      <div className='scheme-title-box'>Everything in Professional +</div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )} 
                        </Row>
                       {ofcDisable && this.state.number && <Row type="flex" justify="center" className="plancontainer ofcCountryErr">
                           {Object.keys(ofcCountries).slice(1).map((key)=>ofcCountries[key]).includes(this.state.currencyOFC) ? <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                To get {countries[Object.keys(ofcCountries).filter((key)=>ofcCountries[key] == this.state.currencyOFC ? key : false)[0]].name}{this.state.currencyOFC == 'US/CA' ? '/Canada' : ''} unlimited calling, please purchase {this.state.currencyOFC} number
                              </Col>:
                              <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                 please select US/Canada , UK or AUS number to get unlimited calling benefits of office phone system plans.
                              </Col>}
                        </Row>}
                      </form>
                    }
                </TabPane>
                <TabPane tab="Call Center Solution" key="1">
                <div className="text-center">
                  {/* {this.state.showtext && <h3 className="num-plan-title priceplanttitle fwidth tab-text-title">Idle for business to support their customers</h3>} */}
                 </div>
                  {this.state.planselectionshow &&
                        <form name="addNumberForm">
                            <Row id="currencydropdown" type="flex" className="planSelect__duration fwidth flexcolomch chflexcenter mgbtm20">
                                <Col className='padzero cnflex reserveUnlimitedCol' 
                                span={this.state?.userData?.accountStatus == 'cancel' && !this.props.userData.data.isFreeTrialUser && !this.props.userData.data.freeTrialUserBlock ? 20 : 14}
                                >
                                    <Form.Item className='chjustifycenter'>
                                      <label className='mgright5 fbold chflex chaligncenter'>Unlimited Calling <Popover
                                      content={'Select country for which you want to get unlimited minutes.'} 
                                      placement="top"><i className="material-icons infopopopen cursorp">info</i>
                                      </Popover>:
                                      </label>
                                      <Select 
                                      defaultValue={this.state.currencyOFC} 
                                      style={{ width: 130 }} 
                                      className="currencydropdownbtn"
                                      getPopupContainer={(trigger) => trigger.parentNode}
                                      onChange={this.currencyDropdownListdefault} 
                                      value={this.state.currencyOFC}
                                      >
                                        {plans.map((plan) => {
                                          if(this.state.cplanName == plan.planDisplayName){
                                            return <Option value={plan.freeMinutesCountry} key = {plan._id} label={plan.freeMinutesCountry}>{plan.freeMinutesCountry == "GB" ? "UK" : plan.freeMinutesCountry}</Option>
                                          }
                                          return null
                                        })}
                                      </Select>
                                    </Form.Item>
                                    {this.state?.userData?.accountStatus == 'cancel' && !this.props.userData.data.isFreeTrialUser && !this.props.userData.data.freeTrialUserBlock &&
                                      <div className="chflex chaligncenter reserveSeatDropDown" id="seatdropdown">
                                        <label className="seatpurchasedlabel fbold chflex chaligncenter">Reserve Seats <Tooltip overlayClassName="number_plan_tooltipWrap" title="The charges will be calculated based on the total seats."><i className="material-icons seatpurchasedtooltip">info</i></Tooltip> : </label>
                                        <input type="number" value= {this.state.userMaxSeat} min={0} max={11} onChange={this.minSeatChange} onKeyDown={this.minSeatKeyDown} className="totalseatinput" />
                                      </div>
                                    }
                                    {this.state.currencyList.length > 0 && 
                                      <div className="chflex chaligncenter reserveSeatDropDown" id="seatdropdown">
                                        <label className='seatpurchasedlabel fbold chflex chaligncenter'>Currency:</label>
                                        <Select 
                                        defaultValue={this.state.currency} 
                                        style={{ width: 100 }} 
                                        className="currencydropdownbtn"
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        onChange={this.onSelectCurrency} value={this.state.currency}>{this.state.currencyList.map((planC) => {
                                          return (<Option value={planC}>{planC}</Option>)
                                        })}
                                        </Select>
                                      </div>
                                      }
                                </Col>
                                <Col className='padzero' span={24}>
                                  <div className="switchwrapper chjstcenter">
                                    <Form.Item label="">
                                      <Radio.Group value={monthlyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnMonthlyRadio} disabled={this.state.disableMonthly} value="monthly">Monthly</Radio>
                                        {/* this.state.userMaxSeat <= 1 && <small className="minimumUserText">Minimum 2 Users</small> */}
                                      </Radio.Group>
                                    </Form.Item>                        
                                    <Form.Item label="">
                                        <Radio.Group value={quarterlyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnQuarterlyRadio} value="quarterly">Quarterly</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                    <Form.Item label="">
                                      <Radio.Group value={annuallyradiocheckvalue}>
                                        <Radio onClick={this.onClickOnAnnuallyRadio} value="annually">Annually</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                    <div className="number_purchse_an_offer_secpage monthcosttab mothcost_dropdown">
                                      <span className="num_purchse_span_sec currecydrop_monthcost">Save 3 Months Cost</span>
                                    </div>
                                  </div>
                                </Col>
                              {/* <Col  span={24} className="gutter-row w-100 unlimited_calling_div">
                                <Col className='padzero' span={12}>
                                </Col>
                                <Col className='padzero' span={12}>
                                {this.state.currencyList.length > 0 && <Form.Item  className="">
                                    <label className='mgright5 fbold'>Currency:
                                    </label>
                                    <Select defaultValue={this.state.currency} style={{ width: 100 }} className="currencydropdownbtn"
                                     onChange={this.onSelectCurrency} value={this.state.currency}

                                    >{this.state.currencyList.map((planC) => {
                                        return (        <Option value={planC}>{planC}</Option>)
                                      })}
                                    </Select>
                                  </Form.Item>}
                                </Col>
                              </Col> */}
                            </Row>
                            {/* END: selection of plan period(monthly/annually) */}
                            <Row type="flex" justify="center" className="plancontainer">
                            {planCallCenter.map((plan) => 
                                <Col className="planpadding cnflex" md={{ span: 8 }} lg={{ span: 8 }}>
                                  <div className="planslbox platinumplan">
                                    <div className="number-container brdorange">
                                      <div className={(plan.planDisplayName === "silver" || plan.planDisplayName === "enterprise") ? 'plandigit num-annu num_background plan_title_silver' : 'plandigit num-annu num_background'}>{plan.planDisplayName}</div>
                                        <div className="planstat ng-binding">
                                        <Tooltip overlayClassName="number_plan_tooltipWrap" title={plan.planDescription}><i className="material-icons numberPlanTooltip">info</i></Tooltip>
                                          <span className="plan_price_position"> {plan[this.state.planPriceType][currency]}
                                            <span className="plan_currency_AN">{currencySign}</span>
                                          </span>
                                        </div>
                                        <span className="plan_type_label">USER/MONTH</span>
                                      <div className="planprice bgwhite addnumboxborder">
                                        {plan.planDisplayName === this.state.cplanName ? 
                                          <Button className="slrctplan plan_selected" onClick={() => this.onClickOfficeOnSelectPlan(plan)}> 
                                            <i className="material-icons plan_check_icon" >check_circle</i> Selected
                                          </Button> 
                                           :
                                          <Button className="slrctplan" onClick={() => this.onClickOfficeOnSelectPlan(plan)}>Select Plan</Button>
                                        }
                                      </div>
                                      <div className='planprice bgwhite addnumboxborder'>
                                      {plan.planDisplayName === "bronze" &&
                                      <>
                                      <div className='scheme-title-box emptybox_feature'></div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul> 
                                      </>
                                      }
                                      {plan.planDisplayName === "silver" &&
                                      <>
                                      <div className='scheme-title-box'>Everything in Bronze +</div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                       {plan.planDisplayName === "platinum" &&  
                                      <>
                                      <div className='scheme-title-box'>Everything in Silver +</div>
                                      <ul className='scheme-list' style={{paddingRight: plan.freeMinutesCountry==="US/CA" ? "5px":""}}>
                                        {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                        <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5 schemalist_info_icon">info</i>
                                        </Popover>}</li>)}
                                      </ul>
                                      </>
                                       }
                                       {plan.planDisplayName === "enterprise" &&
                                        <>
                                        <div className='scheme-title-box'>Everything in Platinum +</div>
                                        <ul className='scheme-list'>
                                          {plan.planPurchasePopUpFeatures && plan.planPurchasePopUpFeatures.map((f)=><li className="scheme-list-details">{f.feature}{f.popover && <Popover content={f.popover} className="abandonratepopovercontent"  placement="bottom">
                                          <i className="material-icons mleft tooltipic_color tooltipic abondenthelp mgright5">info</i>
                                          </Popover>}</li>)}
                                        </ul>
                                        </>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )} 
                        </Row>
                       {ofcDisable && this.state.number && <Row type="flex" justify="center" className="plancontainer ofcCountryErr">
                           {Object.keys(ofcCountries).slice(1).map((key)=>ofcCountries[key]).includes(this.state.currencyOFC) ? <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                To get {countries[Object.keys(ofcCountries).filter((key)=>ofcCountries[key] == this.state.currencyOFC ? key : false)[0]].name}{this.state.currencyOFC == 'US/CA' ? '/Canada' : ''} unlimited calling, please purchase {this.state.currencyOFC} number
                              </Col>:
                              <Col className="planpadding" md={{ span: 20 }} lg={{ span: 20 }}>
                                 please select US/Canada , UK or AUS number to get unlimited calling benefits of office phone system plans.
                              </Col>}
                        </Row>}
                      </form>
                    }
                </TabPane>
                </Tabs>
                {(this.state.currency === 'INR')&& 
                <div className='chflex chaligncenter chjstcenter mgtop10'>
                  Local taxes (GST) will be applicable in addition to the listed prices.
                </div>}
                <div className='chflex chaligncenter chjstcenter mgtop10'>
                    <a className='comparePlanLink' href='https://callhippo.com/pricing/' target='_blank' >Compare Plans</a>
                </div>
                <div className="purchasebtnend tabBorderTop ">
                  <div className='mgtop25 cnflex chaligncenter spacebetween addOnPopupFooterBtns'>
                    <div className="cnflex chaligncenter chjstcenter" span={6}>
                      {this.state.user_details &&  ( this.props.userData.data?.isFreeTrialUser || this.props.userData.data?.freeTrialUserBlock  ) ?
                        this.state.showCouponInput ?
                        <div className='cnflex chaligncenter gap8' style={{alignSelf: "center"}}>
                          <Input type="text" className='couponCodeInputText' value={ this.state.couponCode } maxLength={30} onChange={(e) => this.setState({ couponCode: e.target.value })} placeholder="Enter coupon code" /><Button type='button' className={`couponApplyButton ${this.state.couponCode.length > 0 ? 'couponApplyButtonGreen' : ''}`} size='small' color='primary' onClick={this.applyCouponCode} disabled={!this.state.couponCode}>Apply</Button>
                        </div>
                          :
                          <span className='havCouponCodeText' style={{alignSelf: "center"}} onClick={this.openCouponCode}>Having coupon code?</span>
                        :
                        null
                      }
                    </div>
                    <div>
                      {!this.props.userData.data.expiredFreeTrailDate && ((this.state.user_details && ((this.state.user_details.userAllNumberCount > 0 || this.state.user_details.stripeId) && this.state.user_details.accountStatus != 'cancel' && this.state.user_details.accountStatus != 'hardDelete')) || (this.state.user_details && this.state.user_details.plan.planDisplayName != 'free')) ?                    
                          <Button type="primary" className="gbtnheight paybtnrct checkoutBtn mgleft15" key="submit"  onClick={() => this.props.secondNumberPopupOk(this.state.number,this.state.numberPeriod)} disabled={planId === '' || planId == 123 || ofcDisable? true : false}>
                            CHECKOUT({this.state.number ? this.state.number.monthlyPrice : 0})
                          </Button>        
                          :
                          this.state.showtext ?
                          [
                            <>
                          <div className='chflex chaligncenter chjstcenter'>
                          {!this.state.number.premium && this.props.userData.data && this.props.userData.data.isFreeTrialUser && !this.props.userData.data.expiredFreeTrailDate &&
                            <Button className={`Trial_selected checkoutBtn`} onClick={() => this.setState({freeTrailButtonClick: true}, () => this.handleCheckout())} disabled={(planId == '' || planId == 123 || ofcDisable || ftBtnDisable) ? true : false}>Start 10 Days Trial</Button>
                          }
                          <Button className={`slrctplan plan_selected`} key="submit" type="primary"  onClick={() => this.setState({freeTrailButtonClick: false}, () => this.handleCheckout())} disabled={(planId === '' || planId == 123 || ofcDisable || buyBtnDisable) ? true : false}>
                            Buy Now
                          </Button>
                          </div>
                          </>,
                          ] 
                          : [
                          <div className='chflex chaligncenter chjstcenter'>
                              <Button className="gbtnheight paybtnrct addnumberbtn checkoutBtn mgleft15" key="submit" onClick={this.handleOk} disabled={planId === '' || planId == 123 ? true : false}>
                                PAYs {this.state.showtext ? "true":"false"}
                            </Button>
                          </div>
                            ,
                        ]}
                    </div>
                    <div>
                        {(this.props.userData.data.isFreeTrialUser || this.props.userData.data.freeTrialUserBlock || this.state.number) && <a className="chflex chaligncenter chjstcenter" style={{height:"36px"}} onClick={() => this.handleCheckout(this.state.freeTrailButtonClick= false,true)} >
                              <span style={{borderBottom: "1px solid",alignSelf: "center"}}>Skip</span> 
                      </a>}
                    </div>
                  </div>
                </div>
            {/* END: Tab Struture */}
            {/*plan selection design end */}
          </div>
      </Modal>
      
    );
  }
}

const mapStateToProps = state => ({
  chargebee_new_user: state.number.chargebee_new_user,
  get_users: state.number.get_users,
  user_data: state.number.user_data,
  userData:state.authentication.userData,
  get_number: state.number.get_number

});

export default connect(mapStateToProps, { chargebeeNewUser, getUserProfile, getPlan, planUpgrade, changeUserData, changeUserSetting,setMessage, user, getNumbers, createCbFtCustomerNSubcription, updateSetupGuide, getCustomerPortal, swapSubscriptionItemEstimate, applyCouponCode, openModal})(Addnopopup);
