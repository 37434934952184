import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const AuthOnboardingWrapper = styled.div`
.ant-row.ant-form-item {
    min-height: 70px;
    margin-bottom: unset;
    padding-bottom: unset;
  }
.ant-modal-close{
    display:none;
}
.ant-modal-title img.chinfologowidth{
    width:150px;
    margin-bottom:5px;
}   
.chinfotitlewrapper{
    text-align:center;
    margin-bottom: 10px;
} 
.infopopupwrapper .ant-row:after {
        display: block;       
}
.teamsizewrapper{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    @media (max-width:480px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        
        label.ant-radio-button-wrapper{
            margin:0px !important;
            width:100% !important;
        }
    }
    label.ant-radio-button-wrapper{
        width: 94px;
        padding:0px 12px !important;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        content:unset;
    }
    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
    label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:active{
        color: #fff !important;
        background: #e27849;
        border-color: #e27849;
        box-shadow:unset;
    }
    .ant-radio-button-wrapper:last-child  {
        margin-right:0px;
    }
    .ant-radio-button-wrapper:hover{
        color:#000000a6;
    }
}
.infopopupbtn{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupbtn.ant-btn:hover, .infopopupbtn.ant-btn:focus{
    color:#fff;
    background: #e37949;
    border-color: #e37949;
}
.infopopupwrapper .ant-form-item-label{
    padding: 0 0 2px;
}
.infopopupwrapper .ant-row.ant-form-item{
    margin-bottom:10px;
}
@media (min-width: 1280px) and (max-width: 1500px) {
.minHeight50 {
    min-height: 50px !important;
}
.minHeight65 {
    min-height: 65px !important;
}
}
.prefSoftFormItem .ant-select-multiple .ant-select-selection-placeholder{
    inset-inline-end:unset !important;
}
`;

export default WithDirection(AuthOnboardingWrapper);