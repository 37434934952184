import React from 'react';
import { Link } from 'react-router-dom';
//import chlogo from '../../assets/images/chlogo.svg';
import chcollapselogo from '../../assets/images/chcollapselogo.svg';
import { APP_PRIVATE_ROUTE } from '@iso/components/root/routeConstants';

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const chlogo = imageUrl + 'ch_logo_white.svg';
const { DASHBOARD, ACTIVITYFEED } = APP_PRIVATE_ROUTE;

const Logo = ({ collapsed, isBlocked, showExpanded }) => {
  let HOME = process.env.REACT_APP_REPORTS_APP ? ACTIVITYFEED : DASHBOARD;

  if (showExpanded) {
    return (
      <div className="isoLogoWrapper chflex chflexcenter">
        <Link to={!isBlocked && HOME} className='chLogoLink'>
          <img
            className='chlogot img-expanded'
            src={chlogo}
            alt="Callhippo Logo"
          />
        </Link>
      </div>
    );
  }
  return (
    <div className="isoLogoWrapper chflex chflexcenter" style={{justifyContent:'center', margin:'0'}}>
      <Link to={!isBlocked && HOME} className='chLogoLink'>
        <img
          className='chlogot img-collapsed'
          src={chcollapselogo}
          alt="Callhippo Logo"
        />
      </Link>
    </div>
  );  
};

export default Logo;
