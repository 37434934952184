import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Layout, Tooltip } from 'antd';
import message from '@iso/components/Feedback/Message';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@iso/components/utility/logo';
import SidebarWrapper from './Sidebar.styles';
import { Link, withRouter } from 'react-router-dom';
import CHTour from './CHTour';
import { APP_PRIVATE_ROUTE, PRIVATE_ROUTE } from '@iso/components/root/routeConstants';
import { Helmet } from "react-helmet";
import AddNumberModal from './AddNumberModal.js';
import PlanUpgradePopup from "@iso/components/Popups/PlanUpgrade/PlanUpgradePopup";
import { Action, checkAccessAllowedForModule, ModuleName } from "./../../library/helpers/moduleAccess";
import dashboardAction from "../../redux/dashboard/actions";
import { InfoCircleFilled } from '@ant-design/icons';

const { DASHBOARD, BROADCAST } = APP_PRIVATE_ROUTE;
const { getCredits } = dashboardAction;
const { BROADCAST_ACTIVITY_FEED, BROADCAST_CALL, ADD_CAMPAIGNS_BROADCAST, EDIT_CAMPAIGNS_BROADCAST, PLAN_BILLING, USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD } = PRIVATE_ROUTE;
const { Sider } = Layout;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

function Sidebar(props) {
  const [visibleSidebar, setVisibleSidebar] = useState(null);
  const [placement, setPlacement] = useState('left');
  const toggleSidebar = (type) => {
    if (visibleSidebar === type) {
      setVisibleSidebar(null); // Close the sidebar if it’s already open
    } else {
      setVisibleSidebar(type); // Open the requested sidebar
    }
  };
  
  // Function to close all sidebars
  const closeSidebar = () => {
    setVisibleSidebar(null);
  };

  const onChange = e => {
    setPlacement(e.target.value);
  };
  const dispatch = useDispatch();
  const {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  function handleCollapseChange(collapsed) {
    dispatch(toggleCollapsed());
  }
  const userData = useSelector(state => state.authentication.userData.data);
  const numberData = useSelector(state => state.number.get_number);
  const user_billing = useSelector(state => state.number.user_billing);
  const get_plan = useSelector(state => state.planReducer.get_plan);
  const isTourOpen = useSelector(state => state.authentication.isTourOpen);
  const pusherData = useSelector(state => state.dashboard.pusher_data);
  const userCredits = useSelector(state => state.dashboard.userCredits);
  const accessAllowed = checkAccessAllowedForModule(props.auth.userData.data.roleData, ModuleName.BROADCAST_ACTIVITY_FEED, props.auth.userData.data);
  var currentCredit = userCredits && userCredits.credits ? userCredits.credits : 0;
  // Pusher connecttion
  const [showCallingCreditSection, setShowCallingCreditSection] = useState(false);
  const [sidebarCredit, setSidebarCredit] = useState(0);
  const [sidebarCreditRedirect, setSidebarCreditRedirect] = useState(false);

  //This useEffect will call's every time when this component loaded
  useEffect(() => {
    if (!userData.isTourVisited && !userData.isProfileRemaining) props.history.push(DASHBOARD);
    sidebarCreditChange();
  }, []);

  //Change credit when push event call
  useEffect(() => { if (pusherData && pusherData.type === "credit") sidebarCreditChange(); }, [pusherData]);
  useEffect(() => {
    if (userData.plan.isAccessEditable && userData.isDisableCredit && !(userData.roleData.priority == 1 || userData.roleData.priority == 1.5)) {
      setShowCallingCreditSection(!userData.isDisableCredit)
    } else {
      setShowCallingCreditSection(true)
    }
  }, [userData])
  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  const SIDEBAR_ROUTE = {
    DASHBOARD: {
      URL: BROADCAST,
      KEY: 'dashboard',
      URL_GROUP: [BROADCAST]
    },
    ACTIVITYFEED: {
      URL: BROADCAST_ACTIVITY_FEED,
      KEY: 'activityfeed',
      URL_GROUP: [BROADCAST_ACTIVITY_FEED]
    },
    BROADCAST_CALL: {
      URL: BROADCAST_CALL,
      KEY: 'voiceBroadcast',
      URL_GROUP: [
        BROADCAST_CALL,
        ADD_CAMPAIGNS_BROADCAST,
        EDIT_CAMPAIGNS_BROADCAST
      ]
    },
    PLAN_BILLING: {
      URL: PLAN_BILLING,
      KEY: 'planBilling',
      URL_GROUP: [PLAN_BILLING]
    },
    USER_PROFILE_DOCUMENT: {
      URL: USER_PROFILE_DOCUMENT,
      KEY: 'uploadDocument',
      URL_GROUP: [USER_PROFILE_DOCUMENT]
    }
  }

  useEffect(() => {
    dispatch(changeCurrent([]));
    Object.values(SIDEBAR_ROUTE).filter(obj => {
      obj.URL_GROUP.map((obj2) => {
        if (props.history.location.pathname.toLowerCase() === obj2.toLowerCase()) {
          dispatch(changeCurrent([obj.KEY]));
        }
      })
    })
    if (![USER_PROFILE, USER_PROFILE_DOCUMENT, CHANGE_PASSWORD].includes(props.history.location.pathname) && userData.isDocVerificationRequired != "approved") {
      props.history.push(USER_PROFILE_DOCUMENT);
    }
  }, [props.history.location.pathname])

  function handleClick(e) {
    //dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const checkCredits = () => {
    props.getCredits().then(({ payload }) => {
      if (payload.success) {
        let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
        let finalCredit = payload.data ? payload.data.totalCredits ? formatter.format(payload.data.totalCredits.toFixed(2).toString()) : sidebarCredit : sidebarCredit;
        let finalCr = payload.data ? payload.data.totalCredits ? payload.data.totalCredits : sidebarCredit : sidebarCredit;
        let userData = JSON.parse(localStorage.getItem('userData'));
        userData.totalCredits = finalCr;
        localStorage.setItem("userData", JSON.stringify(userData));
        setSidebarCredit(finalCredit);
      }
    });
  }
  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {
    if (window.outerWidth > 1024) {
      if (collapsed && openDrawer === false) {
        dispatch(toggleOpenDrawer());
      }
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const sidebarCreditChange = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let finalCredit = 0;
    if (pusherData && pusherData.type === "credit") {
      finalCredit = parseFloat(pusherData.data.totalCredits)
    } else {
      if (!(!numberData || !numberData.data || !numberData.data.length) && user_billing && user_billing.data && user_billing.data.data) {
        finalCredit = parseFloat(user_billing.data.data.availableCredits - user_billing.data.data.usedCredits)
      } else {
        if (userCredits && userCredits.credits) {
          finalCredit = userCredits.credits
        } else {
          if (userData && userData.stripeId && userData.plan && userData.plan.planDisplayName && userData.plan.planDisplayName !== 'free') {
            if (!userData.parentId || (userData.parentId && userData.totalCredits && userData.userModules.indexOf('billing') >= 0)) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
              setSidebarCreditRedirect(true);
            } else if (userData && userData.parentId && userData.userModules && userData.userModules.indexOf('billing') == -1) {
              finalCredit = userData && userData.totalCredits ? userData.totalCredits : 0;
            }
          }
        }
      }
    }
    userData.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userData));
    let userDataLocal = JSON.parse(localStorage.getItem('userData'));
    userDataLocal.totalCredits = finalCredit;
    localStorage.setItem("userData", JSON.stringify(userDataLocal));
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });
    finalCredit = formatter.format(finalCredit.toFixed(2).toString());
    setSidebarCredit(finalCredit);
  }

  const warningMsg = msg => { message.destroy(); message.warning(msg) };
  const userIsBlocked = () => {
    userData.isBlocked && !userData.amountDue && warningMsg(userData.blockReason);
    //userData.isBlocked && userData.amountDue && warningMsg(userData.blockReason + " Please update your payment method.");

  };
  const tabTitleChange = () => {
    if (current != []) {
      current.splice(0, current.length)
      current.push("credit")
    }
  }
  function changeTabTitle(currentKey) {
    const keyName = currentKey[0];
    if (currentKey[0] == "dashboard") {
      return "voicebroadcast"
    } else if (currentKey[0] == "activityfeed") {
      return "Activity Feed"
    } else if (currentKey[0] == "voiceBroadcast") {
      return "Voice Broadcast"
    } else if (currentKey[0] == undefined) {
      return "Dashboard"
    } else {
      return keyName[0].toUpperCase() + keyName.slice(1);
    }
  }

  return (
    <div>
      <SidebarWrapper>
        {/* <PusherConnection userData={userData} /> */}
        <Helmet>
          <title>{changeTabTitle(current)} | Callhippo.com</title>
        </Helmet>
        <CHTour isOpen={isTourOpen} />
        {/* <AddNumberModal /> */}
        <PlanUpgradePopup />
        <Sider
             trigger={null}
             collapsible
             collapsed={!isCollapsed}
             onCollapse={handleCollapseChange}
             width={240}
             collapsedWidth={60}
             className={`isomorphicSidebar sidebaUnqDivForTopNotificatiobar ${props.userData && props.userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
             style={props.styling}
        // className={`isomorphicSidebar sidebaUnqDivForTopNotificatiobar  ${userData && userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
        // onMouseLeave={onMouseLeave}
        // onMouseEnter={onMouseEnter}
        // style={styling}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} />
          {/* <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`}
            style={isCollapsed ? { height: height - 75 } : { height: height - 94 }}
          > */}
          <Scrollbars className={`sidebarScrollbars adjustScroll sidebarremoveborder ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`}
            style={isCollapsed ? { height: height - 100 } : userData.isFreeTrialUser ? { height: height - 180 } : { height: height - 139 }}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu Broadcast_Sider_outer_div"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={"dashboard"}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && "/broadcast"}>
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" /></svg>
                    <span className="nav-text">Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key={SIDEBAR_ROUTE.BROADCAST_CALL.KEY}>
                <Link onClick={userIsBlocked} to={!userData.isBlocked && SIDEBAR_ROUTE.BROADCAST_CALL.URL}>
                  <span className="isoMenuHolder ">
                    <i className="material-icons nav-icon voiceBroadcastIcon campaignremove">record_voice_over</i>
                    <span className="nav-text">Campaigns</span>
                  </span>
                </Link>
              </Menu.Item>
              {accessAllowed.read &&
                <Menu.Item key="activityfeed" data-tour="tour-activity">
                  <Link onClick={userIsBlocked} to={!userData.isBlocked ? (userData.numbers || userData.deletedNumbers || userData.parentId ? "/broadcast/activityFeed" : "/broadcast/dummyactivityfeed") : null} >
                    <span className="isoMenuHolder svgremove">
                      <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none" /><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z" /></svg>
                      <span className="nav-text">Activity Feed</span>
                    </span>
                  </Link>
                </Menu.Item>}
            </Menu>
          </Scrollbars>
          <div className="Btn-Collapes">
            <button onClick={() => toggleSidebar('collapsed')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 8C16.7833 8 17.0208 7.90417 17.2125 7.7125C17.4042 7.52083 17.5 7.28333 17.5 7C17.5 6.71667 17.4042 6.47917 17.2125 6.2875C17.0208 6.09583 16.7833 6 16.5 6C16.2167 6 15.9792 6.09583 15.7875 6.2875C15.5958 6.47917 15.5 6.71667 15.5 7C15.5 7.28333 15.5958 7.52083 15.7875 7.7125C15.9792 7.90417 16.2167 8 16.5 8ZM13 19H20V5H13V19ZM4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V13H6.2L4.6 14.6L6 16L10 12L6 8L4.6 9.4L6.2 11H2V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4Z" fill="white" />
              </svg>
            </button>
          </div>
        </Sider>
      </SidebarWrapper>
      {visibleSidebar === 'collapsed' && (
      <SidebarWrapper
        placement={placement}
        closable={false}
        // onClose={closeSidebar}
        visible={true}
        width={240}
        bodyStyle={{ padding: 0 }}
      >
        <PlanUpgradePopup />
        <Sider
          trigger={null}
          collapsible
          collapsed={isCollapsed}
          onCollapse={handleCollapseChange}
          width={240}
          height={100}
          className={`isomorphicSidebar sidebaUnqDivForTopNotificatiobar ${props.userData && props.userData.isProfileRemaining ? "sidebarDisabled" : ''}`}
          style={{ ...props.styling, height: '100vh',position:'absolute',top:0 }}
        >
          <Logo collapsed={isCollapsed} isBlocked={userData.isBlocked} showExpanded={true} />
          {/* <Scrollbars className={`sidebarScrollbars scrolltabheight ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`}
              style={isCollapsed ? { height: height - 75 } : { height: height - 94 }}
            > */}
          <Scrollbars className={`sidebarScrollbars adjustScroll sidebarremoveborder ${userData.isProfileRemaining ? "sidebarDisabledOpacity" : ''}`}
            style={isCollapsed ? { height: height - 100 } : userData.isFreeTrialUser ? { height: height - 180 } : { height: height - 139 }}
          >
            <Menu
              onClick={handleClick}
              theme="dark"
              className="isoDashboardMenu Broadcast_Sider_outer_div"
              mode={mode}
              openKeys={isCollapsed ? [] : openKeys}
              selectedKeys={current}
              onOpenChange={onOpenChange}
            >
              <Menu.Item key={"dashboard"}>
                <Link onClick={() => {userIsBlocked(); toggleSidebar('collapsed');}} to={!userData.isBlocked && "/broadcast"}>
                  <span className="isoMenuHolder svgremove">
                    <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" /></svg>
                    <span className="nav-text">Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key={SIDEBAR_ROUTE.BROADCAST_CALL.KEY}>
                <Link onClick={() => {userIsBlocked(); toggleSidebar('collapsed');}} to={!userData.isBlocked && SIDEBAR_ROUTE.BROADCAST_CALL.URL}>
                  <span className="isoMenuHolder ">
                    <i className="material-icons nav-icon voiceBroadcastIcon campaignremove">record_voice_over</i>
                    <span className="nav-text">Campaigns</span>
                  </span>
                </Link>
              </Menu.Item>
              {accessAllowed.read &&
                <Menu.Item key="activityfeed" data-tour="tour-activity">
                  <Link onClick={() => {userIsBlocked(); toggleSidebar('collapsed');}} to={!userData.isBlocked ? (userData.numbers || userData.deletedNumbers || userData.parentId ? "/broadcast/activityFeed" : "/broadcast/dummyactivityfeed") : null} >
                    <span className="isoMenuHolder svgremove">
                      <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none" /><path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z" /></svg>
                      <span className="nav-text">Activity Feed</span>
                    </span>
                  </Link>
                </Menu.Item>}
            </Menu>
          </Scrollbars>
          <div className="Btn-Collapes-ex" style={{
            justifyContent: 'end',
            display: 'flex'
          }}>
            <button onClick={() => toggleSidebar('collapsed')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 8C7.21667 8 6.97917 7.90417 6.7875 7.7125C6.59583 7.52083 6.5 7.28333 6.5 7C6.5 6.71667 6.59583 6.47917 6.7875 6.2875C6.97917 6.09583 7.21667 6 7.5 6C7.78333 6 8.02083 6.09583 8.2125 6.2875C8.40417 6.47917 8.5 6.71667 8.5 7C8.5 7.28333 8.40417 7.52083 8.2125 7.7125C8.02083 7.90417 7.78333 8 7.5 8ZM11 19H4V5H11V19ZM20 21C20.55 21 21.0208 20.8042 21.4125 20.4125C21.8042 20.0208 22 19.55 22 19V13H17.8L19.4 14.6L18 16L14 12L18 8L19.4 9.4L17.8 11H22V5C22 4.45 21.8042 3.97917 21.4125 3.5875C21.0208 3.19583 20.55 3 20 3H4C3.45 3 2.97917 3.19583 2.5875 3.5875C2.19583 3.97917 2 4.45 2 5V19C2 19.55 2.19583 20.0208 2.5875 20.4125C2.97917 20.8042 3.45 21 4 21H20Z" fill="white" />
              </svg>
            </button>
          </div>
        </Sider>
      </SidebarWrapper>
       )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    numbers: state.number.get_number,
    user_billing: state.number.user_billing,
    //get_plan: state.planReducer.get_plan,
    pusherData: state.dashboard.userData,
  }
}
export default connect(mapStateToProps, { getCredits })(withRouter(Sidebar));