import React from 'react';
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const redirectionImage = imageUrl+'redirection-image.png';
const loadingDots = imageUrl+'loading-dots.gif';

export default RedirectToChApp => (    
    <div style={{display: "flex",justifyContent: "center",alignItems: "center",flexDirection: "column",height: "400px"}}>
        <img style={{width: "200px"}} src={redirectionImage} />
        <p style={{fontSize: "30px", marginTop: 0,position: "relative",width: "100%",textAlign: "center",color: "#19313F", fontFamily: "sans-serif"}}>Please wait we are redirecting to our app {<img style={{width: "60px",position: "absolute",top: "0px",marginLeft: "-5px"}} src={loadingDots} />}</p>
    </div>
);
