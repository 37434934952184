import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';
import cancelicon from '../images/cancelicon.svg';
import checkcircle from '../images/checkcircle.svg';
const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }
  .ant-modal-mask{
    background-color: rgb(0 0 0 / 62%) !important;
    backdrop-filter: blur(1px) !important;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }
  .callLogFilterPopup .ant-modal-title{
       text-align: center;
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  background-color: #efefef!important;
  letter-spacing: .5px;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Login Page tooltip design */
.ant-tooltip.chMagicLinkTooltip{
  max-width: 100%;
  background-color: #222222;
  padding-bottom: 0;
}
/* End Login Page tooltip design */

/* custom styles */

/*Custom message css*/
.ant-message-notice-content{
  padding: unset !important;
  margin-top: -8px;
}
.ant-message-custom-content{
  padding: 16px;
  border-radius: 2px;
}
.ant-message-success{
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.ant-message-info{
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.ant-message-info i{
  color: #bee5eb;
}
.ant-message-error{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.ant-message-warning, .ant-message-warn{
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
/*End Custom message css*/

.chflex {
  display: flex;
}
.cnflex {
  display: flex !important;
}
.chflexcenter {
  justify-content: center;
  align-items: center;
}
.chjstcenter {
  justify-content: center;
}
.chjstend {
  justify-content: end;
}
.spacebetween {
  justify-content: space-between;
}
.chaligncenter {
  align-items: center;
}
.chalignstart {
  align-items: start !important;
}
.chalignend {
  align-items: end;
}
.flexcolomch {
  flex-direction: column;
}
.flexUnset{
  flex:unset !important;
}
.chflexw1 {
  flex: 1;
}
.flex-wrap {
    flex-wrap: wrap;
}
.gap8{
  gap: 8px;
}
.gap12{
  gap: 12px;
}
.w-100{
  width: 100% !important;
}
.isoLogoWrapper{
  .chlogot {
    width: 130px  ;
    max-height: 30px !important;
    min-height: 30px !important;
    height: 30px !important;
    margin-top: 12px;
  }
  .chlogocollapse {
    width: 51px;
  }
  .img-collapsed{
   width: 50px;
   max-height: 30px !important;
   min-height: 30px !important;
   height: 30px !important;
  }
}
.bgchtitile {
  font-size: 1.5rem;
}
.fbold {
  font-weight: 600;
}
.height100 {
  height: 100%;
}
.text-center {
  text-align: center;
}
.mgzero{
  margin:0px !important;
}
.mgbtm15 {
  margin-bottom: 15px;
}
.mgbtm10 {
  margin-bottom: 10px;
}
.mgtopzero{
  margin-top: 0px !important;
}
.mgtop5 {
  margin-top: 5px;
}
.mgtop10 {
  margin-top: 10px;
}
.mgtop15 {
  margin-top: 15px !important;
}
.mgtop25 {
  margin-top: 25px !important;
}
.mgleft15 {
  margin-left: 15px;
}
.mgleft25 {
  margin-left: 25px;
}
.mgbtmzero {
  margin-bottom: 0px !important;
}
.padzero {
  padding: 0px !important;
}
.padbtmzero {
  padding-bottom: 0px !important;
}
.fwidth {
  width: 100%;
}
.fleft {
  float: left;
}
.overflowUnset{
  overflow: unset !important;
} 
.formpsize {
  font-size: 15px;
  color: #333;
}
.drpdwnshoadow .ant-select-selection {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.16),0 0 0 1px rgba(0,0,0,.08)!important;
  border: 1px solid #f2f2f2!important;
  border-radius: unset;
}
.lnhnormal {
  line-height: normal;
}
.gbtnheight {
  height: 36px !important;
}
.mgbtm20 {
  margin-bottom: 20px;
}
.mgleft12 {
  margin-left: 12px;
}
.mgleft5 {
  margin-left:5px;
}
.mgright5 {
  margin-right: 5px;
}
.mgright10 {
  margin-right: 10px;
}
.mgright15 {
  margin-right: 15px;
}
.lineHightNormal{
  line-height:normal !important;
}
.plansprice {
  padding: 5px 0;
  border-top: 1px solid #dbdbdb;
  color: #6b6b6b;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
}
.brdorange {
  border: 1px solid #e27646 !important;
}
.globalinputch {
  border: none !important;
  border-bottom: 1px solid #9e9e9e !important;
  border-radius: 0 !important;
  outline: none !important;
  height: 3rem !important;
}
.globalinputch:hover {
  border-color: #9e9e9e !important;
  outline: none !important;
}
.globalinputch:focus {
border-bottom: 1px solid #e27646 !important;
box-shadow: 0 1px 0 0 #e27646 !important;
outline: none !important;
}
.hideAllocateNumbers{
    display: none;
}
.text-right {
  text-align: right;
}
.chmnsidebar {
    flex: 0 0 212px !important;
    max-width: 240px;
    min-width: 212px !important;
    width: 212px !important;
}  
.siwtchorangerct .ant-switch-checked {
  background-color: #e27646 !important;
}
.clrwhite {
  color: white;
}
.cursorp {
  cursor: pointer;
}
.crpoint {
  cursor: pointer!important;
}
.fnwgt500 {
  font-weight: 500;
}
.glbcolr {
  color: #545454;
  font-size: 15px;
  font-weight: 500;
}
.mgbtm30 {
  margin-bottom: 30px;
}
.brdunset {
  border: unset !important;
}
.numbertable .ant-table-thead > tr > th {
  background: WHITE;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-top: 1px solid #e8e8e8;
  font-size: 15px;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
}
.uploadbtnctm {
    display: flex !important;
    align-items: center !important;
    background: rgba(158,158,158,.2) !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12) !important;
    border: unset !important;
}
.uploadbtnctm:hover {
  background-color: #2bbbad !important;
  color: white !important;
}
.uploadbtnctm i {
  margin-right: 8px;
}
/*Module Sidebar*/
.section__wrapper{
  max-height: calc(100vh - 70px);
  overflow: auto;
  background-color: #efefef;
}
.moduleSidebar.ant-anchor-wrapper {
  position: fixed;
  padding:0;
  top:50px;
  margin-left: 0px;
  width: 16%;
  background-color: #f5f5f5;
  height: 100%;
  border-right: 1px solid #dddddd;
  border-radius:10px 0 0 0;
}
.numbersidebar.ant-anchor-wrapper {
  width: 217px;
}
.moduleSidebar .ant-anchor{
  // padding-top: 20px;
  padding-left: 0;
}
.moduleSidebar .ant-anchor .ant-anchor-ink{
  display: none;
}
.moduleSidebar .ant-anchor-link{
  padding: 0;
}
.moduleSidebar .ant-anchor-link .ant-anchor-link-title{
  padding: 14px 16px;
}
.moduleSidebar .ant-anchor-link:hover{
  background-color: #34495e;
}
.moduleSidebar .ant-anchor-link:hover .moduleSidebarSpan{    
  color: #ffffff;
}
.moduleSidebar .ant-anchor-link.ant-anchor-link-active{
  background-color: #dddddd;
}
.moduleSidebar .ant-anchor-link.ant-anchor-link-active:hover{
  background-color: #34495e;
  color: #ffffff;
}
.moduleSidebarSpan i {
  font-size: 18px;
  width: 25px;
}
.moduleSidebarSpan {
  color: #464646;
  font-weight: 500;
  font-size: 13px !important;
  display: flex;
  align-items: center;
}
/*End Module Sidebar*/

/*Core Modify*/
.ch_radio_color .ant-radio-checked .ant-radio-inner{
  border-color: #e27646;
  background-color: #e27646;
}
.ch_radio_color .ant-radio-checked .ant-radio-inner::after{
  background-color: #e27646;
}
.ch_radio_color.ant-radio-wrapper:hover .ant-radio,
.ch_radio_color .ant-radio:hover .ant-radio-inner,
.ch_radio_color .ant-radio:focus .ant-radio-inner,
.ch_radio_color .ant-radio:active .ant-radio-inner,
.ch_radio_color .ant-radio-input:focus + .ch_radio_color .ant-radio-inner{
  border-color: #e27646;
}
.ch_toggle_switch.ant-switch{
  background-color: #818181;
  height: 15px;
  // left: -2px;
}
.ch_toggle_switch.ant-switch.ant-switch-checked{
  background-color: #84c7c1;
}
.numbersetswitch.ch_toggle_switch.ant-switch::after{
  top: -4px;
  width: 21px;
  height: 21px;
  box-shadow: 0 1px 3px 1px rgba(0,0,0,.4);
  background-color: #f1f1f1;
  margin-left: -4px;
  left: -3px;
}
.ch_toggle_switch.ant-switch.ant-switch-checked::after{
  background-color: #26a69a;
  margin-left: 2px;
}
/*End Core Modify*/

/*Change YES NO sequence*/
.ourApp_btn .ant-modal-footer > div .ant-btn-primary,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:hover,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:focus,
.ourApp_btn .ant-modal-footer > div .ant-btn-primary:active {
  background: #e27849 !important;
  border-color: #e27849 !important;
  color: #fff !important;
  margin-right: 10px !important;
}
.ourApp_btn .ant-modal-footer button:nth-child(1):hover,
.ourApp_btn .ant-modal-footer button:nth-child(1):hover,
.ourApp_btn .ant-modal-footer button:nth-child(1):focus,
.ourApp_btn .ant-modal-footer button:nth-child(1):active {
  background: #fff !important;
  border-color: #ddd !important;
  color: #333 !important;
}
.ourApp_btn .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.ourApp_btn .ant-modal-body p a {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
}
.restrictBtn .ant-modal-footer > div .ant-btn-primary {
  background: #e27849;
  border-color: #e27849;
  color: #fff;
 }
.restrictBtn .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
.exportYesNO .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.exportYesNO .ant-modal-footer button:nth-child(1),.exportYesNOSecond .ant-modal-footer button:last-child{
  margin-left: 5px;
  background: #e27849;
  border-color: #e27849;
  color: #fff;
}
.exportYesNOSecond .ant-modal-footer > div {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}
.exportYesNO .ant-modal-footer button:nth-child(1):hover,.exportYesNO .ant-modal-footer button:nth-child(1):active,.exportYesNO .ant-modal-footer button:nth-child(1):focus {
  background-color: #d46332;
  border-color: #e27849;
  color: #fff;
}
.exportYesNO .ant-modal-footer button:nth-child(2) {
  margin-left: 0;
}
.addYesNo .ant-modal-footer button:nth-child(1) {
  border-color: #d9d9d9;
  background: #fff;
  color: #000000d9;
}
.addYesNo .ant-modal-footer button:nth-child(1):hover,.addYesNo .ant-modal-footer button:nth-child(1):active,.addYesNo .ant-modal-footer button:nth-child(1):focus {
  color:#40a9ff;
  border-color: #40a9ff;
  background: #fff;
}
.addYesNo .ant-modal-footer > div > button {
  margin:5px 10px 5px 10px !important;
}
.addYesNo .ant-modal-footer > div {
  text-align:center !important;
  display:flow-root !important;
}
.warningbtn .ant-modal-footer button:nth-child(1){
  display:none;
}
/*End Change YES NO sequence*/

/*number plan select tooltip*/
.number_plan_tooltipWrap .ant-tooltip-inner {
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin: 0 auto;
    min-width: 400px;
    max-width: 600px;
}
/*End number plan select tooltip*/
.searchinticon {
  position: relative;
}
.searchinticon i{
  position:absolute;
  right: 8px;
  top: 9px;
}
.listviewsearch {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
}
// .searchinticon .searchinttext{
//   padding-right: 26px;
//   border: unset;
//   border-bottom: 1px solid #d9d9d9;
// }

/*Parvati Css*/

#fixed-order-team .ant-input
{
  width:50px!important;
  margin-left:0px!important;
  border-radius:5px !important;
  border:1px solid #9e9e9e !important;
  text-align: center;
}
.dropdown_mr-5 .ant-form-item-label
{
  margin-right:5px!important;
}
.toCapitalize {
  text-transform: capitalize;
}.
.ant-input:focus {
  border-color:rgb(0 0 0 / 15%) !important;
  box-shadow:none !important;
}
.ant-input:hover {
  border-color:rgb(0 0 0 / 15%) !important; 
}
.free-warning-center .ant-modal-title {
  text-align:center
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .ant-progress-text{
  font-size:10px !important;
  color:white !important;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .svgremove svg.nav-icon,
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .svgremove .nav-icon{
  display:none;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .campaignremove {
  display:none;
}
/*hippa compliance*/
.hippaImg {
  width: 70px;
  height: 22px;
  margin-right: 10px;
  align-self: center;
}
.pciImg {
  width: 70px;
  height: 28px;
}
.ant-layout-has-sider > div:first-child {
  background-color: #19313f;
}
.ant-layout-has-sider > div > div  {
 box-shadow:unset;
 border-right: unset;
}
.Btn-Collapes{
  display: flex;
  margin-top:50px;
  justify-content: center;
}
.Btn-Collapes-ex{
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top:50px;
  }
.Btn-Collapes button ,
.Btn-Collapes-ex button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.add_credit_ul {
  list-style: disc;
  padding-left: 18px;
}
.add_credit_modal .ant-modal-footer {
  display: none;
}
.app_menu_list_dropdown {
  box-shadow: 0 1px 18px 0 rgb(0 0 0 / 21%);
  border: 1px solid #ddd;
  border-radius: 5px;
 }
 .assignPopup .ant-btn-default {
  display: none !important;
 }
//  disabled btn css
.ant-btn.ant-btn.disabled, 
.ant-btn.ant-btn[disabled], 
.ant-btn.ant-btn.disabled:hover, 
.ant-btn.ant-btn[disabled]:hover, 
.ant-btn.ant-btn.disabled:focus, 
.ant-btn.ant-btn[disabled]:focus, 
.ant-btn.ant-btn.disabled:active, 
.ant-btn.ant-btn[disabled]:active, 
.ant-btn.ant-btn.disabled.active, 
.ant-btn.ant-btn[disabled].active {
  color: #D8D8D8 !important;
  background-color: #f7f7f7 !important;
  border-color: #e9e9e9 !important;
  cursor: not-allowed !important;
}

 } 
 .doctYesNO .ant-modal-footer > div {
  display: flex;
  flex-direction: row-reverse;
}
.doctYesNO .ant-btn-primary,
.doctYesNO .ant-btn-primary:hover,
.doctYesNO .ant-btn-primary:focus,
.doctYesNO .ant-btn-primary:active {
  background-color: #e27849;
  color: #fff;
  border-color: #e27849;
  margin-right: 5px;
}
.hover-reminder{
  background-color: #FFF;
  border-radius: 100%;
  color: #19313e;
  margin-left: 5px;
  padding: 3px 6px;
  font-size: 12px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .addnumpopup{
    width:754px !important;
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .removeiconcamp i.nav-icon{
    display:none;
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner .hidenotification{
    display:none;
  }
  .newintegrationsetting {
    width:766px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1359px) {
  .planbilligsidebar.ant-anchor-wrapper {
    width: 180px;
  }
  .intsidebar.intsidebarres.ant-anchor-wrapper{
    width: 200px;
  }
  .intsidebar.ant-anchor-wrapper {
    width: 16%;
  }
}

.grecaptcha-badge{
  display: none !important;
}
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item:hover {
    background-color:#e2764647 !important;
}
.ant-select-selection:focus, .ant-select-selection:active {
  border:1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
button.fileDownloadBtn{
  border: 1px solid #2685ee;
  background-color: #2685ee;
  margin-left: 4px;
  padding: unset;
  border-radius: 30px;
  height: 34px;
  width: 34px;
}
button.fileDownloadBtn:hover, button.fileDownloadBtn:focus, button.fileDownloadBtn:active {
  border: 1px solid #2685ee;
  background-color: #2685ee;
}
button.fileSendBtn{
  border: 1px solid #5bc0de;
  background-color: #5bc0de;
}
button.fileSendBtn:hover, button.fileSendBtn:focus, button.fileSendBtn:active {
  border: 1px solid #5bc0de;
  background-color: #5bc0de;
}
.dummynumbernote{
  margin: 0 auto;
  float: none;
  text-align: center;
  padding: 8px 35px 8px 14px;
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.purchasenowbtn{
  text-align: center;
  margin: 2%;
  // padding: 2%;
}
.purchasenowbtn .btn-purchase{
  background: #e27849;
  border-color: #e27849;
  color: #fff;
}
.pushAccount-discr{
  margin:5% !important;
  padding:2% !important;
  text-align: left;
}
.disable__Div {
  pointer-events: none;
  opacity:0.5;
}
.searchclosefix .anticon-close-circle ,
.searchclosefix .ant-select-selection__clear {
    display: none !important;
}
// Sidebar Credits Info Tooltip Css
.ant-popover-inner:has(.prfileEmail) {
  max-width:350px !important;
}
.ant-popover-inner:has(.prfileEmail) .ant-popover-inner-content{
  white-space:break-space !important;
  word-break:break-all !important;
}
.ant-popover-content:has(.planSettingPopoverContent),.ant-popover-content:has(.planBillingPopoverContent),
.ant-popover-inner:has(.cloneUserPopover){
  max-width:500px !important;
}
.ant-popover-content:has(.needHelpPopover) .ant-popover-arrow{
  top:88% !important;
}
.productTourWrapper:has(.numbersettingarrow) {
  top: 90px;
  left: -12px !important;
}
/* HashtagBtn Css Start 11-07-23 */
.cardHashtagLink {
  flex-shrink:0 !important;
}
.cardHashtagLink:has(a + a) {
  gap:12px !important;
}
.cardHashtagLink a:first-child {
  white-space: nowrap;
}
.cardHashtagLink .hashtagBtn{
  border-radius: 50px;
  background: #FFE1E4 !important;
  padding: 8px 18px;
  color: #721C24 !important;
  font-size: 13px !important; 
  display: inline-block;
}
/* HashtagBtn Css End 11-07-23 */
.palnSbscriptionPopupMain{
  top:36px !important;
}
/* Low Credit Popup Css */
.antCustomNotificationPopupMain{
  box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.15) !important;
}
.antCustomNotificationPopupMain .g_reminder_btn{
  background-color: #e27646 !important;
  border-color:#e27646 !important;
}
.antCustomNotificationPopupMain .ant-notification-notice-message{
  font-weight: 600 !important;
  font-size: 18px !important;
}
.portFormInput .businessRadio .ant-radio-inner::after,
.portFormInput .resedentialRadio .ant-radio-inner::after{
  width:9px !important;
  height:9px !important;
  background-color: #e27646 !important;
}
.portFormInput .businessRadio .ant-radio-checked .ant-radio-inner,
.portFormInput .resedentialRadio .ant-radio-checked .ant-radio-inner,
.portFormInput .resedentialRadio .ant-radio:hover .ant-radio-inner,
.portFormInput .businessRadio .ant-radio:hover .ant-radio-inner{
  border-color: #e27646 !important;
}
/* Low Credit Popup Css */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}

// GLOBAL whole webapp ant select dropdown overlapping issue fix
.globalAntDropDownZindex .ant-select-dropdown{
  z-index:100 !important;
}
.isomorphicContent{
  margin: 50px 0 0;
  flexShrink: 0;
  background: #f1f3f6;
  position: relative;
  height: calc(100vh - 50px);
  overflow: auto;
  border-radius: 10px 0 0 0;
}
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner) .dashboardChMain .ant-tabs-nav-container,
.isoContentMainLayout .isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner){
    border-radius: 0 !important;
}
    
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner) .dashboardChMain .datrngpicloc {
    top: 97px !important;
}
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner) .dashboardChMain .filterwrapper {
    top: 95px !important;
}
.isomorphicContent:has(.stickyDashboardText + .dashboardChMain),
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner):has(.dashboardChMain),
.isomorphicContent:has(.stickyDashboardText),
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner)
{
  height: calc(100vh - 130px) !important;
  margin-top: 130px !important;
}

.isomorphicContent:has(.ChangeResendEmailMain > div .activationemailwrapper){
  height: calc(100vh - 90px) !important;
  margin-top: 90px !important;
}

.isomorphicContent:has(.stickyDashboardText) .section__wrapper,
.isomorphicContent:has(.ChangeResendEmailMain .ChangeResendEmailInner) .section__wrapper {
  height: calc(100vh - 108px) !important;
}

.isomorphicContent:has(.dashboardTabsMain){
  height: calc(100vh - 94px) !important;
  margin-top:94px !important;
  border-radius:0 !important;
    // background: red;
}

.topBarMessage + div .chHeaderMain{
  top:27px !important;
}
.topBarMessage + div + .ant-layout .isomorphicSidebar{
  padding-top: 27px !important;
}
.topBarMessage + div + .ant-layout .isomorphicSidebar .adjustScroll{
  height: calc(100vh - 160px) !important;
}
.topBarMessage + div + .ant-layout .isomorphicContent:has(.dashboardTabsMain){
  margin-top: 123px !important;
  height: calc(100vh - 123px) !important;
}
.topBarMessage + div + .ant-layout .isomorphicContent{
  margin-top: 77px !important;
  height: calc(100vh - 77px) !important;
}
.topBarMessage + div + .ant-layout .isomorphicContent .moduleSidebar.ant-anchor-wrapper{
  top: 97px !important;
}
.topBarMessage + div + .ant-layout .isomorphicContent .section__wrapper{
  height: calc(100vh - 98px) !important;
}
.topBarMessage + div + .ant-layout .isomorphicContent:has(.ChangeResendEmailInner) .section__wrapper{
  height: calc(100vh - 136px) !important;
}

// CSS FOR HANDLE VIEW WHEN TOPNOTIFICATION BAR ACTTIVE ON TOP OF PAGE START
.hasTopfrrTrialNotifyDiv{
  .isomorphicContent{
    height: calc(100vh - 101px) !important;
    margin-top:80px !important;
  }
  .isomorphicContent:has(.dashboardTabsMain){
    height: calc(100vh - 122px) !important;
    margin-top: 122px !important;
  }
  .isoContentMainLayout .ant-affix{
    top: 100px !important
  }
  .chHeaderMain{
    top: 30px !important; 
  }
  .isomorphicSidebar.sidebaUnqDivForTopNotificatiobar{
    padding-top: 29.5px;
    .adjustScroll{
      height:calc(100vh - 162px) !important;
    }
    .isoLogoWrapper{
      border-top: none;
    }
  }
  .needHelpDivVisibility{
    visibility: hidden !important;
  }
  .intsidebar.moduleSidebar .moduleSidebarLinkWrapper {
    max-height: calc(100vh - 138px) !important;
  }
  .section__wrapper{
    max-height: calc(100vh - 101px) !important;
  }
  .moduleSidebar.ant-anchor-wrapper{
    top: 81px !important;
  }
  ${'' /* .isomorphicContentFrTopnotify  .moduleSidebar.ant-anchor-wrapper {
    top: 0px !important;
    padding-top: 100px !important;
  } */}
}
// CSS FOR HANDLE VIEW WHEN TOPNOTIFICATION BAR ACTTIVE ON TOP OF PAGE END

.modalConfirmCustom.planChangePopup{
  top: 80px !important;
  width: 600px !important;
  .ant-modal-content{
    width: 600px !important;
  }
}
.modalConfirmCustom.planChangePopup.planChangePopupInr{
  .ant-modal-confirm-btns{
    margin-bottom: 42px;
  }   
  .addNumPopupNote{
    position:absolute;
    display:flex;
    gap:2px;
    bottom:12px;
    color: #797979 !important;
  }
}
.modalConfirmCustom.planChangePopup .ant-modal-content .ant-modal-confirm-btns button:first-child{
  order: unset !important;
}
.modalConfirmCustom {
  width: 530px !important;
  top: 225px !important;

  .ant-modal-body {
    padding: 0 !important
  }

  .ant-modal-content {
    width: 520px;

    .ant-modal-close {
      display: block;
    }

    .anticon.anticon-question-circle {
      display: none
    }

    .ant-modal-confirm-title {
      padding: 16px 24px;
      color: rgba(0, 0, 0, .65);
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ant-modal-confirm-content {
      margin: 0 !important;
      padding: 24px;
      font-size: 14px;
      line-height: 1.5;
      word-wrap: break-word;
    }

    .ant-modal-confirm-btns {
      padding: 10px 16px;
      text-align: right;
      background: 0 0;
      border-top: 1px solid #e8e8e8;
      border-radius: 0 0 4px 4px;
      float: unset;
      margin: unset;
      display: flex;
      justify-content: flex-end;

      button:first-child {
        order: 2;
        margin-left: 8px;
        background: rgb(226, 120, 73);
        color: #fff;
        border-color: rgb(226, 120, 73);
      }

      button::last-child {
        order: 1;
      }
    }

  }
}
.ant-modal{
  &:has(.upgradePlanPopupTitle){
      .ant-modal-confirm-content{
        padding: 0px 24px 7px !important;
      }
      .ant-modal-confirm-title{
          border-bottom:unset !important;
      }
      button.ant-btn:has(> div.cancleButtonUpPopup) {
        background: #ededed !important;
        color: #b9b9b9 !important;
    }

    button.ant-btn:has(> div.cancleButtonDwnPopup) {
      background: #1890ff !important;
    color: #ffffff !important;
    // border: none !important;
  }

  button.ant-btn:has(> div.DwngradButtonPopup) {
    background: #ededed !important;
  color: #b9b9b9 !important;
}
      .ant-modal-confirm-btns{
          border-top: unset !important;
          padding-bottom: 20px !important;
          justify-content: flex-end !important;
          gap: 5px !important;
          flex-direction:unset !important;
          .ant-btn{
              padding: 10px 20px !important;
              border-radius: 2px !important;
              font-size: 14px !important;
              font-weight: 400 !important;
              display: flex;
              align-items: center;
              justify-content: center;
              &:has(.cancleButtonUpPopup){
                  border: 1px solid #EDEDED !important;
                  background: #EDEDED !important;
                  color: #818181 !important;
              }
              &:has(.upgradButtonPopup){
                  border: 1px solid #1890FF !important;
                  background: #1890FF !important;
                  color: #fff !important;
              }
          }
      }
  }
}
.upgradePlanPopupTitle{
  color: #000;
  font-size: 19px;
  font-weight: 600;
}
.modalBottomText{
  color: rgba(116, 116, 116, 0.85);
  font-size: 13px !important;
  display:flex !important;
  strong{
      color: #000 !important;
      font-weight: 600 !important;
  }
}
.planUpgradeDwnGradTable{
  margin:20px 0px 30px;
  .w100{
    width: 100px;
  }
  .w120{
    width: 120px;
  }
  thead{
      tr{
          th{
              font-weight: 400 !important;
              color: #000 !important;
              padding: 0px 5px 10px !important;
              border-bottom: 1px solid #DDD;
          }
      }
  }
  tr{
      td{
          border-bottom:  unset;
          color: #000 !important;
          font-size: 14px;
          padding: 10px 5px !important;
          font-weight: 400 !important;
      }
      &:last-child{
          td{
              border-top: 1px solid #DDD !important;
              font-weight: 600 !important;
          }   
      }
  }
}
.modalTopText{
  color: rgba(116, 116, 116, 0.85);
  font-size: 15px;
}
.validationwrapper{
  display:flex;
  margin: 8px 0;
  align-items:center;
}

.cancelicon{
  background-image: url(${cancelicon});
  height: 20px;
  width: 20px;
  min-width:20px;
  background-repeat: no-repeat;
  background-size: contain;  
  display:block;
  margin-right: 8px;
}
.checkcircle{
  background-image: url(${checkcircle});
  height: 20px;
  width: 20px;
  min-width:20px;
  background-repeat: no-repeat;
  background-size: contain;  
  display:block;
  margin-right: 8px;
}
.topBarMessage{
  font-weight: 500;
  color: #2d3446;
  font-size: 12px;
  background: #fff;
  padding: 4px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index:1001;
}
.ant-popover{
  &:has(.changePassValidator){
    left:36% !important;
    @media (min-width:1440px) {
      left:37% !important;
    }
  }
  &:has(.SetPassValidator){
    left:unset !important;
    right:20% !important;
    @media (min-width:1440px) {
      right:22% !important;
    }
    @media (min-width:1601px){
      right:28% !important;
    }
  }
  &:has(.AuthPassValidator){
    left:0 !important;
    top:0 !important;
    transform: translate(-100%,-25%) !important;
    max-width:308px !important;
    width:100% !important;
    @media(max-width:1280px){
      max-width:250px !important;
      font-size:12px !important;
    }
  }
  &:has(.SetNewPasswordValidator){
    left:36% !important;
    @media (min-width:1800px){
      left:39% !important;
    }
  }
}

// sidemenu collaped menu css start
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark{
  .ant-menu-sub{
    background-color:#19313f;
    // top: 98px !important;
    left: 5px !important;
    position: absolute;
}
    padding:0px;
    .ant-menu-item-selected {
      background-color: #34495e;
      .nav-text{
        color:#FFF;
      }
    }
    .ant-menu-item-group-title{
      display:none !important;
    }
    .ant-menu-item-group{
      border-top-left-radius:4px !important;
      border-top-right-radius:4px !important;
      margin: 14px 
    }
    .ant-menu-item{
      margin:0px;
      padding: 0 14px !important;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
      &:first-child{
        border-top-left-radius:4px !important;
        border-top-right-radius:4px !important;
      }
      &:last-child{
        border-bottom-left-radius:4px !important;
        border-bottom-right-radius:4px !important;
      }
      .nav-text{
        color:#FFF;
      }
      &:hover{
        background-color: #34495e !important;
        color:#fff !important;
        border-radius: 4px;
        padding: 0 14px !important;
        .nav-text{
          color:#fff !important;
        }
      }
    }
  }
}
// sidemenu collaped menu css start
.callActivitiesTool {
  max-width: 320px;
}
.callDespoPopover{
    &.ant-popover-placement-bottom{
      padding-top: 0;
    }
    .ant-popover-arrow{
        border:none !important;
    }
}

/* call desposition report filter modal css start */
.callDespoReportFilterPopup{
  .ant-modal-content{
      padding:0px !important;
  }
  .ant-modal-header{
      padding: 18px;
      color: rgba(0, 0, 0, 0.65);
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      text-align:center;
  }
  .ant-modal-footer{
      padding: 18px;
      text-align:center;
      border-top: 1px solid #e8e8e8;
  }
  .ant-modal-body{
      background-color: #fff !important;
      padding:18px !important;
      .ant-form-item{
        margin-bottom:18px !important;
          &:last-child{
              margin-bottom:0px!important;
          }
      }
  }
  .ant-modal-close,
  .ant-modal-close:is(:hover,:focus,:active){
      display:flex;
      align-items:center;
      justify-content:center;
      background:none !important;
      outline:none !important;
      border:none!important;
      box-shadow:none !important;
  }
  .resetBtnReport{
      color:#333 !important;
      span{
          text-decoration: underline !important;
      }
  }
  .filterSelectOptions.ant-select-open{
    .ant-select-arrow{
        .anticon-search{
            display:none!important;
        }
    }
  }
  .filterSelectOptions{
      .ant-select-selector{
          padding:0 !important;
          .ant-select-selection-overflow{
              max-height:70px;
              overflow-y:auto !important;
              padding: 2px 6px;
              min-height:30px;
          }
      }
      .ant-select-arrow{
          position: absolute;
          top: 16px;
          z-index: 100;
      }

      .ant-select-selection-item{
        background-color: #fafafa;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        color: rgba(0, 0, 0, .65);
      }
  }
  .calldspRdoGrp{
    .ant-radio-wrapper{
      &:hover{
        .ant-radio-inner{
          border-color: #e27849 !important;
        }
      }
      > span{
        &:last-child{
          padding-left:4px !important;
          line-height:normal;
        }
      }
      .ant-radio-checked{
        .ant-radio-inner{
          border-color: #e27849 !important;
          background-color: unset !important;
          &::after{ 
            background-color: #e27849 !important;
            transform: scale(0.6);
          }
        }
      }
    }
  }
}
/* call desposition report filter modal css end */

/* No Data Table Css Start */
.ant-table .ant-table-placeholder:has(.noDataTableWrpr){
  border-bottom: unset !important;
}
.noDataTableWrpr{
  margin:64px auto 20px !important;
  max-width:850px !important;
  .noDataTbleImg{
    border-radius:20px !important;
    margin-bottom: 30px;
  }
  .noDataTblDesc{
    color:#a5a1a1 !important;
    font-weight:400 !important;
    font-size:14px !important;
  }
  .noDataTblLinkText{
    text-decoration: underline !important;
    color: #1890ff !important;
    cursor: pointer !important;
  }
}
/* No Data Table Css End */
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    border: 0 !important;
}
/*custom popup like chargebee*/

.customAddressPopup {
  width: 400px;
  .form-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height:398px;
  }

  .loading-form {
    background-color: #e6f7ff;
    opacity: 0.7;
    pointer-events: none;
  }
    .ant-modal-content {
        background-color: #f4f5f9 !important;
       padding: 0px !important;
     }
    .ant-form-vertical {
      .ant-form-item {
        padding-bottom: 0;
      }
    }
    .ant-modal-title {
      background: #fff;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
      text-align: center;
      padding: 20px 36px;
      position: relative;
      color: rgb(33, 150, 243) !important;
}
    .ant-modal-body {
      padding: 12px 36px 0px !important;
      .ant-form-item-explain-error {
        font-size: 12px !important;
      }
    }
    .address-wrap-wr {
      flex-wrap: wrap;
      gap: 2px;
      .ant-form-item {
        flex: 1 1 50% !important;
        max-width: 49.69% !important;
      }
    }
    input.ant-input {
      width: 100% !important;
      padding: 24px 15px !important;
      font-size: 16px !important;
      outline: none !important;
      background: #fff !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      border-radius: unset !important;
      border: unset !important;
      margin-bottom: 2px;
    }
    input.ant-input::placeholder, .ant-select-selection-item {
      color: #767676 !important;
    } 
    .ant-select-selector {
      width: 100% !important;
      padding: 24px 15px !important;
      font-size: 16px !important;
      outline: none !important;
      background: #fff !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      border-radius: unset !important;
      border: unset !important;
    }
    .address-wrap-wr input.ant-input {
      flex: 49%;
      margin-bottom: 0px;
    }
    .ant-select {
      flex: 49% !important;
      /* width: 49% ! IMPORTANT; */
      min-height: 48px;
    }
    .ant-select-arrow {
      top: 24px !important;
    }
    span.ant-select-selection-placeholder {
      color: #767676 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    .ant-modal-footer {
      text-align: center;
      padding: 24px 36px 18px;
      margin-top: 0px;
      button.ant-btn {
          margin: 0px !important;
          width: 200px;
          height: 40px;
          font-weight: 600;
          letter-spacing: 1.2px;
          font-size: 15px;
          border-radius: 2px;
      }
    }
    .ant-form-item {
      margin-bottom: 0px;
      padding: 0px;
    }
    button.ant-modal-close {
      right: -8px !important;
      top: -7px !important;
      border-radius: 50% !important;
      background-color: #393941 !important;
      display:flex;
      align-items:center;
      justify-content:center;
    }
    .ant-modal-close-x {
      width: 22px;
      height: 22px;
      svg {
          fill: #ffffff;
          margin-top: 4px;
          font-size: 14px;
      }
     }
}
/*End custom popup like chargebee*/
@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
.redirectChAppPopup  {
    text-align: center !important;
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
      padding: 0px !important;
      .ant-modal-close {
        display: none;
      }
    }
    .ant-modal-body {
      font-size: 17px !important;
      color: #ffffff;
    }
  }
  .chloaderRedirect {
    border: 3px solid #fff;
    border-radius: 50%;
    border-bottom-color: transparent;
    width: 30px !important;
    height: 30px !important;
    animation-name: loading;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: inline-flex;
    }

.teamAuditPopover{
  .ant-popover-inner-content{
    padding:5px 4px 4px 8px !important;
  }
  .moreTeamMember{
    max-width:300px;
    min-width:140px;
    max-height: 250px;
    overflow-y: auto;
    padding-right: 4px;
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar{
        width: 7px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-color: #c1c1c1;
    }
    .teamMembersName{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }   
}
.textUnderline{
  text-decoration: underline !important;
}
.ant-pagination-disabled,
.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev {
    border: 1px solid #d9d9d9 !important;
}
.chDashboardBtmNote{
    position: fixed;
    bottom: 8px;
    width:fit-content !important; 
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 3px;
    right: 46%;
    transform: translateX(50%);
    white-space: nowrap;
    color:#333333!important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 15px;
    font-weight: 500;
    background-color:#FFEDE5;;
    height: 50px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    gap:3px;
}
.ant-popover.tooltip-calllog{
  top: 194px !important;
}
 .callhippoai_text-top {
    background: #e27646;
    padding: 2px 4px;
    color: #fff;
    height: 22px;
    display: flex;
    border-radius: 4px;
    margin-right: 14px;
    margin-top: 4px;
    margin-left: 6px;
    align-items: center;
}
.callhippoai_text-top-round {
    background: #e27646;
    padding: 16px 10px;
    color: #fff;
    height: 22px;
    /* width: 26px; */
    display: flex;
    border-radius: 50%;
    margin-right: 14px;
    margin-top: 4px;
    text-align: left;
    margin-left: 0px;
    align-items: center;
}
`;

export default GlobalStyles;
